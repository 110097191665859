import React from 'react';
import {ImageRendererConfig, OptimizedImage} from '../image/ImageRenderer';

export interface LogoProps {
  image: ImageRendererConfig;
}

export const Logo = React.memo(({image}: LogoProps) => (
  <div className="rwe-logo">
    <OptimizedImage data={image} />
  </div>
));
