import React from 'react';
import {useNavbarBackgroundColor} from '../../../../hooks/use-navbar-background-color';
import {
  RunwayEastButton,
  RunwayEastButtonProps,
} from '../button/RunwayEastButton';
import {HeaderTextMask} from './HeaderTextMask';

export interface HeaderCenterProps {
  text?: string;
  buttonText?: string;
  buttonHref?: string;
  buttonVariant?: RunwayEastButtonProps['variant'];
  backgroundColor?: string;
  headerText?: string;
  headerTextBackgroundImageSrc?: string;
}

export const HeaderCenter = React.memo(
  ({
    headerText,
    buttonText,
    buttonHref,
    buttonVariant = 'primary',
    text,
    backgroundColor = '#FFF',
    headerTextBackgroundImageSrc,
  }: HeaderCenterProps) => {
    useNavbarBackgroundColor(backgroundColor);

    return (
      <div className="rwe-header-center" style={{backgroundColor}}>
        <div className="rwe-content-container">
          <div className="rwe-header-center__content">
            {headerText ? (
              <div className="rwe-header-center__heading">
                <HeaderTextMask imageSrc={headerTextBackgroundImageSrc}>
                  {headerText}
                </HeaderTextMask>
              </div>
            ) : null}
            {text ? (
              <div className="rwe-header-center__text rwe-h4-alt">{text}</div>
            ) : null}
            {buttonText && buttonHref ? (
              <div className="rwe-header-center__button">
                <RunwayEastButton href={buttonHref} variant={buttonVariant}>
                  {buttonText}
                </RunwayEastButton>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  },
);
