import React, {useCallback} from 'react';
import {CarouselWindow} from '../CarouselWindow';
import {TestimonialCarouselDots} from './TestimonialCarouselDots';
import {CarouselContextProvider} from '../CarouselContext';
import {TestimonialCarouselTestimonialBox} from './TestimonialCarouselTestimonialBox';
import {
  TestimonialCarouselLeftButton,
  TestimonialCarouselRightButton,
} from './TestimonialCarouselButton';
import {ImageRendererConfig, OptimizedImage} from '../../image/ImageRenderer';

export interface TestimonialCarouselItem {
  image: ImageRendererConfig;
  eBoxBackgroundColor: string;
  eBoxTextColor: string;
  LogoComponent: React.ReactNode;
  TestimonialBodyComponent: React.ReactNode;
  testimonialCreditText?: string;
}

export interface TestimonialCarouselProps {
  data: TestimonialCarouselItem[];
}

export const TestimonialCarousel = React.memo(
  ({data}: TestimonialCarouselProps) => {
    const renderItem = useCallback(
      (
        {image}: TestimonialCarouselItem,
        _index: number,
        visibleIndex: number | undefined,
      ) => (
        <div
          className={[
            'rwe-testimonial-carousel__item',
            visibleIndex === 0 ? 'rwe-testimonial-carousel__item--active' : '',
          ]
            .join(' ')
            .trim()}
        >
          <OptimizedImage data={image} />
        </div>
      ),
      [],
    );

    return (
      <div className="rwe-testimonial-carousel">
        <CarouselContextProvider data={data}>
          <CarouselWindow
            renderItem={renderItem}
            windowContainerChildren={
              <>
                <TestimonialCarouselLeftButton />
                <TestimonialCarouselRightButton />
              </>
            }
          >
            <TestimonialCarouselTestimonialBox />
          </CarouselWindow>
          <TestimonialCarouselDots />
        </CarouselContextProvider>
      </div>
    );
  },
);
