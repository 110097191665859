import {useEffect} from 'react';
import {
  DEFAULT_BACKGROUND_COLOR,
  useNavbarContext,
} from '../ui/components/runway-east/navbar/NavbarContextProvider';

export const useNavbarBackgroundColor = (color: string): void => {
  const {setBackgroundColor} = useNavbarContext();

  useEffect(() => {
    setBackgroundColor(color);

    return () => {
      setBackgroundColor(DEFAULT_BACKGROUND_COLOR);
    };
  }, [color, setBackgroundColor]);
};
