import React from 'react';
import {DatoRunwayEastStructuredText} from './DatoRunwayEastStructuredText';

export interface DatoHighlightedTextProps {
  backgroundColor?: string;
  data: {value: any};
}

export const DatoHighlightedText = React.memo(
  ({backgroundColor, data}: DatoHighlightedTextProps) => (
    <div
      className="rwe-structured-text__highlighted-text"
      style={{
        backgroundColor,
      }}
    >
      <DatoRunwayEastStructuredText data={data} />
    </div>
  ),
);
