import {FaqsBlockFragment} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {DatoRunwayEastStructuredText} from '../text/DatoRunwayEastStructuredText';
import {colors} from '../theme';
import {
  CollapsibleSectionListWithCopySection,
  CollapsibleSectionListWithCopySectionProps,
} from './CollapsibleSectionListWithCopySection';

export interface DatoFaqsSectionProps extends FaqsBlockFragment {
  index: number;
}

const transformDatoFaqsBlockFragment = ({
  heading,
  backgroundColor,
  faqs,
  hideSeparatorLine,
  blockCardIdentify,
  blockMenuName,
  onLaptop,
  onMobile,
}: DatoFaqsSectionProps): CollapsibleSectionListWithCopySectionProps => ({
  withLine: !hideSeparatorLine,
  blockId: blockCardIdentify as string,
  blockName: blockMenuName as string,
  LaptopVisible: onLaptop as string,
  MobileVisible: onMobile as string,
  backgroundColor: backgroundColor
    ? backgroundColor.hex || undefined
    : undefined,
  copy: <DatoRunwayEastStructuredText data={heading} />,
  sections: faqs.map(({question, answer}) => ({
    header: (
      <DatoRunwayEastStructuredText
        data={question}
        runwayEastStyle={{
          p: {
            classNames: ['rwe-p1'],
            style: {color: colors.primary.charcoalBlack},
          },
        }}
      />
    ),
    children: (
      <DatoRunwayEastStructuredText
        data={answer}
        runwayEastStyle={{
          p: {
            classNames: ['rwe-p2', 'rwe-p2--medium'],
            style: {color: colors.slate.darkSlate},
          },
        }}
      />
    ),
  })),
});

export const DatoFaqsSection = React.memo((props: DatoFaqsSectionProps) => {
  const collapsibleSectionListWithCopySectionProps = useMemo(
    (): CollapsibleSectionListWithCopySectionProps =>
      transformDatoFaqsBlockFragment(props),
    [props],
  );

  return (
    <div className="rwe-faqs-section">
      <CollapsibleSectionListWithCopySection
        {...collapsibleSectionListWithCopySectionProps}
      />
    </div>
  );
});
