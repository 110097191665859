import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

export interface HorizontalScrollableElementRefAttributes {
  scrollToIndex: (index: number) => any;
  visibleChildrenIndex: number[];
}

export interface HorizontalScrollableElementProps<Item extends any> {
  onScrollStart?: () => any;
  onScrollEnd?: () => any;
  onChildrenVisibleIndexesChange?: (
    visibleIndexes: (number | undefined)[],
  ) => any;
  containerChildren?: React.ReactNode;
  renderItem: (
    item: Item,
    index: number,
    visibleIndex: number | undefined,
  ) => React.ReactNode;
  data: Item[];
}

export const HorizontalScrollableElement = React.memo(
  React.forwardRef<
    HorizontalScrollableElementRefAttributes,
    HorizontalScrollableElementProps<any>
  >(
    (
      {
        onChildrenVisibleIndexesChange,
        containerChildren,
        onScrollEnd,
        onScrollStart,
        renderItem,
        data,
      },
      ref,
    ) => {
      const flexGridRef = useRef<HTMLDivElement | null>(null);
      const moveTimerRef = useRef<number | null>(null);
      const cancelScrollingEndTimeoutRef = useRef<NodeJS.Timeout | null>(null);
      const [isScrolling, setIsScrolling] = useState(false);
      const [isBeingMouseDragged, setIsBeingMouseDragged] = useState(false);
      const [isBeingTouchDragged, setIsBeingTouchDragged] = useState(false);
      const [cancelNextClick, setCancelNextClick] = useState(false);
      const [startX, setStartX] = useState(0);
      const [deltaX, setDeltaX] = useState(0);
      const [transformedClientLeft, setTransformedClientLeft] = useState(0);
      const [visibleChildrenIndex, setVisibleChildrenIndex] = useState(
        data.map((_, index) => index),
      );
      const childRefs = useMemo(
        () => data.map(() => React.createRef<HTMLDivElement>()),
        [data],
      );
      const carouselWindowInnerStyle = useMemo(
        (): React.CSSProperties => ({
          transition:
            isBeingTouchDragged || isBeingMouseDragged ? 'none' : undefined,
          transform: `translateX(${-transformedClientLeft}px) translateX(${deltaX}px)`,
        }),
        [
          deltaX,
          isBeingMouseDragged,
          isBeingTouchDragged,
          transformedClientLeft,
        ],
      );

      const wrappedChildren = useMemo(
        () =>
          data.map((child, index) => (
            <div ref={childRefs[index]} className="rwe-carousel-window__item">
              {renderItem(child, index, visibleChildrenIndex[index])}
            </div>
          )),
        [childRefs, data, renderItem, visibleChildrenIndex],
      );

      const calculateVisibleChildrenIndex = useCallback(() => {
        const numberOfWrappedChildrenOffViewportLeftEdge = childRefs
          .map((childRef): readonly [
            number,
            React.RefObject<HTMLDivElement>,
          ] => {
            if (!childRef.current) return [0, childRef];

            return [
              childRef.current.offsetLeft - transformedClientLeft + deltaX,
              childRef,
            ] as const;
          })
          .map(([value]) => (value && value < 0 ? 1 : 0) as number)
          .reduce(
            (previousValue, currentValue) => previousValue + currentValue,
          );

        const newChildrenVisibleIndexesMap = wrappedChildren.map(
          (_, index) => index - numberOfWrappedChildrenOffViewportLeftEdge,
        );

        const hasChildrenVisibleIndexesChanged = newChildrenVisibleIndexesMap
          .map((value, index) => visibleChildrenIndex[index] !== value)
          .reduce(
            (previousValue, currentValue) => previousValue || currentValue,
          );

        if (hasChildrenVisibleIndexesChanged) {
          setVisibleChildrenIndex(newChildrenVisibleIndexesMap);
        }
      }, [
        childRefs,
        deltaX,
        transformedClientLeft,
        visibleChildrenIndex,
        wrappedChildren,
      ]);

      const calculateFirstVisibleChild = useCallback(() => {
        setTransformedClientLeft((currentTransformedClientLeft) => {
          if (!flexGridRef.current) return currentTransformedClientLeft;
          if (Math.abs(deltaX) < 50) return currentTransformedClientLeft; //to prevent scroll when mouse clicked

          return childRefs
            .map((childRef): readonly [
              number,
              React.RefObject<HTMLDivElement>,
            ] => {
              if (!childRef.current) return [0, childRef];

              return [
                childRef.current.offsetLeft -
                  currentTransformedClientLeft +
                  deltaX,
                childRef,
              ] as const;
            })
            .filter(([offset, wrappedChildRef], index) => {
              if (!wrappedChildRef.current) return false;

              return deltaX < 0
                ? index < childRefs.length - 1 && offset < 0
                : offset + wrappedChildRef.current.clientWidth < deltaX;
            })
            .map(([offset, wrappedChildRef]) => {
              if (!wrappedChildRef.current) return 0;
              if (!flexGridRef.current) return 0;

              if (deltaX < 0) {
                return offset + wrappedChildRef.current.clientWidth <=
                  flexGridRef.current.clientWidth
                  ? wrappedChildRef.current.clientWidth
                  : 0;
              }
              return wrappedChildRef.current.clientWidth;
            })
            .reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              0,
            );
        });
      }, [childRefs, deltaX]);

      const fakeOnDragStart = useCallback(
        ({screenX, touchDrag = false, mouseDrag = false}) => {
          if (moveTimerRef.current !== null) {
            window.cancelAnimationFrame.call(window, moveTimerRef.current);
          }
          setIsBeingTouchDragged(touchDrag);
          setIsBeingMouseDragged(mouseDrag);
          setStartX(screenX);
        },
        [],
      );

      const fakeOnDragMove = useCallback(
        (screenX) => {
          moveTimerRef.current = window.requestAnimationFrame.call(
            window,
            () => {
              setDeltaX(screenX - startX);
            },
          );
        },
        [startX],
      );

      const fakeOnDragEnd = useCallback(() => {
        if (moveTimerRef.current !== null) {
          window.cancelAnimationFrame.call(window, moveTimerRef.current);
        }

        calculateFirstVisibleChild();

        setDeltaX(0);
        setIsBeingMouseDragged(false);
        setIsBeingTouchDragged(false);
      }, [calculateFirstVisibleChild]);

      const handleOnMouseUp: React.MouseEventHandler<HTMLDivElement> = useCallback(
        (ev) => {
          if (!isBeingMouseDragged) return;

          ev.preventDefault();
          fakeOnDragEnd();
        },
        [fakeOnDragEnd, isBeingMouseDragged],
      );
      const handleOnMouseMove: React.MouseEventHandler<HTMLDivElement> = useCallback(
        (ev) => {
          if (!isBeingMouseDragged) return;

          setCancelNextClick(true);
          ev.preventDefault();
          fakeOnDragMove(ev.screenX);
        },
        [fakeOnDragMove, isBeingMouseDragged],
      );
      const handleOnMouseDown: React.MouseEventHandler<HTMLDivElement> = useCallback(
        (ev) => {
          ev.preventDefault();
          fakeOnDragStart({
            screenX: ev.screenX,
            mouseDrag: true,
          });
        },
        [fakeOnDragStart],
      );
      const handleOnClickCapture: React.MouseEventHandler<HTMLDivElement> = useCallback(
        (ev) => {
          if (!cancelNextClick) {
            return;
          }
          ev.preventDefault();
          setCancelNextClick(false);
        },
        [cancelNextClick],
      );
      const handleOnTouchStart: React.TouchEventHandler<HTMLDivElement> = useCallback(
        (ev) => {
          const touch = ev.targetTouches[0];
          fakeOnDragStart({
            screenX: touch.screenX,
            screenY: touch.screenY,
            touchDrag: true,
          });
        },
        [fakeOnDragStart],
      );
      const handleOnTouchMove: React.TouchEventHandler<HTMLDivElement> = useCallback(
        (ev) => {
          if (moveTimerRef.current !== null) {
            window.cancelAnimationFrame.call(window, moveTimerRef.current);
          }

          const touch = ev.targetTouches[0];

          if (!touch) return;

          fakeOnDragMove(touch.screenX);
        },
        [fakeOnDragMove],
      );

      const endTouchMove = useCallback(fakeOnDragEnd, [fakeOnDragEnd]);
      const handleOnTouchEnd = useCallback(endTouchMove, [endTouchMove]);
      const handleOnTouchCancel = useCallback(endTouchMove, [endTouchMove]);

      const scrollToIndex = useCallback(
        (index: number) => {
          if (index < 0 || index >= childRefs.length) return;

          const childRef = childRefs[index];

          if (!childRef.current) return;

          setTransformedClientLeft(childRef.current.offsetLeft);
        },
        [childRefs],
      );

      useImperativeHandle(
        ref,
        (): HorizontalScrollableElementRefAttributes => ({
          scrollToIndex,
          visibleChildrenIndex,
        }),
        [scrollToIndex, visibleChildrenIndex],
      );

      useEffect(() => {
        if (cancelScrollingEndTimeoutRef.current) {
          clearTimeout(cancelScrollingEndTimeoutRef.current);
        }

        if (!isScrolling) {
          setIsScrolling(true);
          if (onScrollStart) onScrollStart();
        }

        cancelScrollingEndTimeoutRef.current = setTimeout(() => {
          setIsScrolling(false);
          if (onScrollEnd) onScrollEnd();
          calculateVisibleChildrenIndex();
        }, 350);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
        calculateVisibleChildrenIndex,
        onScrollEnd,
        onScrollStart,
        transformedClientLeft,
      ]);

      useEffect(() => {
        if (!onChildrenVisibleIndexesChange) return;

        onChildrenVisibleIndexesChange(visibleChildrenIndex);
      }, [visibleChildrenIndex, onChildrenVisibleIndexesChange]);

      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          className={[
            'rwe-horizontal-scrollable-element',
            isScrolling ? 'rwe-horizontal-scrollable-element--scrolling' : '',
          ]
            .join(' ')
            .trim()}
          onTouchStart={handleOnTouchStart}
          onTouchMove={handleOnTouchMove}
          onTouchEnd={handleOnTouchEnd}
          onTouchCancel={handleOnTouchCancel}
          onMouseDown={handleOnMouseDown}
          onClickCapture={handleOnClickCapture}
          onMouseUp={handleOnMouseUp}
          onMouseMove={handleOnMouseMove}
          onMouseLeave={handleOnMouseUp}
        >
          <div
            ref={flexGridRef}
            className="rwe-horizontal-scrollable-element__inner"
            style={carouselWindowInnerStyle}
          >
            {wrappedChildren}
          </div>
          {containerChildren}
        </div>
      );
    },
  ),
);
