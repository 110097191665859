import React from 'react';

export interface EmbedProps {
  src: string;
}

export const Embed = React.memo(({src}: EmbedProps) => (
  <div className="rwe-embed">
    <iframe title={src} src={src} />
  </div>
));
