import {
  DatoWorkspacePageQuery,
  DatoWorkspacePageQueryVariables,
} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {DatoReusableComponents} from '../DatoReusableComponents';
import {DatoButtonLink} from '../link/DatoLink';
import {DatoRunwayEastStructuredText} from '../text/DatoRunwayEastStructuredText';
import {WorkspacePage, WorkspacePageProps} from './WorkspacePage';
import {transformAssetsFieldValueToAssetsProp} from '../section/GalleryCarouselSection';
import {transformDatoBreadcrumbsFragment} from '../section/DatoBreadcrumbsSection';
import {JsonLd} from '../json-ld/JsonLd';

type WorkspaceData = NonNullable<DatoWorkspacePageQuery['workspace']>;

export type WorkspacePageQuery = {
  type: 'workspace';
  data: WorkspaceData;
  queryVariables: DatoWorkspacePageQueryVariables;
  query: string;
};

export const transformDatoWorkspacePageFragment = (
  workspace: WorkspaceData,
): WorkspacePageProps => {
  return {
    siteName: workspace.site?.siteName || undefined,
    address: workspace.site?.address || undefined,
    headerText: workspace.name || undefined,
    phoneNumber: workspace.site?.phoneNumber || undefined,
    children: workspace.reusableComponents.map((reusableComponents) => (
      <DatoReusableComponents data={reusableComponents} />
    )),
    assets: transformAssetsFieldValueToAssetsProp(workspace.assets),
    floatingCta: workspace.floatingCta
      ? {
          headingText: workspace.floatingCta.heading || undefined,
          children: (
            <DatoRunwayEastStructuredText data={workspace.floatingCta.body} />
          ),
          pricePrefixText: workspace.floatingCta.pricePrefix || undefined,
          priceText: workspace.floatingCta.price || undefined,
          buttons: workspace.floatingCta.links.map((link) => (
            <DatoButtonLink data={link} />
          )),
        }
      : undefined,
    breadcrumbs: workspace.showBreadcrumb
      ? transformDatoBreadcrumbsFragment(workspace)
      : [],
  };
};

export interface DatoWorkspacePageProps {
  data: WorkspaceData;
}

export const DatoWorkspacePage = React.memo((props: DatoWorkspacePageProps) => {
  const workspacePageProps = useMemo((): WorkspacePageProps => {
    return transformDatoWorkspacePageFragment(props.data);
  }, [props]);

  return (
    <>
      {props.data.seoStructuredData ? (
        <JsonLd
          schema={{
            ...props.data.seoStructuredData,
          }}
        />
      ) : null}
      <WorkspacePage {...workspacePageProps} />;
    </>
  );
});
