import {PageContentFragment} from 'graphql/generated/graphql';
import React from 'react';
import {DatoBlock} from './DatoBlock';

export interface DatoReusableComponentsProps {
  data: PageContentFragment;
  index?: number;
}

export const DatoReusableComponents = React.memo(
  ({data, index: reusableComponentsIndex}: DatoReusableComponentsProps) => {
    if (!data || !data.reusableComponents) return null;

    return (
      <>
        {data.reusableComponents.map((reusableComponent, index) =>
          reusableComponent ? (
            <DatoBlock
              key={reusableComponent.id}
              {...reusableComponent}
              index={
                reusableComponentsIndex === undefined
                  ? index
                  : reusableComponentsIndex + index
              }
            />
          ) : null,
        )}
      </>
    );
  },
);
