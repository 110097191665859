import React from 'react';
import {RightIcon} from '../icon/RightIcon';
import {colors} from '../theme';

const ICON_WIDTH = 7;

export const BreadcrumbSeparator = React.memo(() => (
  <div className="rwe-breadcrumb-separator">
    <RightIcon color={colors.primary.charcoalBlack} width={ICON_WIDTH} />
  </div>
));
