import React, {useMemo} from 'react';
import {useFloatingCta} from '../floating-cta/FloatingCtaContext';
import {
  ImageWithOverlappingContentProps,
  ImageWithOverlappingContent,
} from '../image-with-overlapping-content/ImageWithOverlappingContent';

export interface CtaSectionProps
  extends Omit<ImageWithOverlappingContentProps, 'contentContainerStyle'> {
  backgroundColor?: string;
  blockId?: string;
  blockName?: string;
  LaptopVisible?: string;
  MobileVisible?: string;
}

export const CtaSection = React.memo(
  ({backgroundColor, blockId, blockName, LaptopVisible, MobileVisible, ...rest}: CtaSectionProps) => {
    const floatingCta = useFloatingCta();
    const style = useMemo(
      () => (backgroundColor ? {backgroundColor} : undefined),
      [backgroundColor],
    );
    let DisplayMobile = '';
    let DisplayLaptop = '';
    if(MobileVisible == 'no'){
      DisplayMobile = 'DontDisplayMobile'
    }
    if(MobileVisible == 'yes'){
      DisplayMobile = 'DisplayMobile'
    }
    if(LaptopVisible == 'no'){
      DisplayLaptop = 'DontDisplayLaptop'
    }
    if(LaptopVisible == 'yes'){
      DisplayLaptop = 'DisplayLaptop'
    }
    return (
      <div className={"rwe-cta-section" + ' ' + DisplayMobile + ' ' + DisplayLaptop} id={blockId == '' ? undefined : blockId} attr-name={blockName == '' ? undefined : blockName}>
        <div className="rwe-section" style={style}>
          <div className="rwe-content-container">
            <ImageWithOverlappingContent
              {...rest}
              contentContainerStyle={floatingCta.avoidanceStyle}
            />
          </div>
        </div>
      </div>
    );
  },
);