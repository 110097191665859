import React, {useEffect} from 'react';
import {BreadcrumbProps} from '../breadcrumb/Breadcrumb';
import {GalleryCarouselItem} from '../carousel/gallery/GalleryCarousel';
import {FloatingCta, FloatingCtaProps} from '../floating-cta/FloatingCta';
import {useNavbarContext} from '../navbar/NavbarContextProvider';
import {BreadcrumbsSection} from '../section/BreadcrumbsSection';
import {GalleryCarouselSection} from '../section/GalleryCarouselSection';
import {colors} from '../theme';

export interface SolutionTypePageProps {
  headerText?: string;
  assets: GalleryCarouselItem[];
  children?: React.ReactNode;
  floatingCta?: FloatingCtaProps;
  breadcrumbs: BreadcrumbProps[];
}

export const SolutionTypePage = React.memo(
  ({
    children,
    headerText,
    assets,
    floatingCta,
    breadcrumbs,
  }: SolutionTypePageProps) => {
    const {setBackgroundColor} = useNavbarContext();

    useEffect(() => {
      setBackgroundColor(colors.primary.white);
    }, [setBackgroundColor]);

    return (
      <div className="rwe-solution-type-page">
        <BreadcrumbsSection data={breadcrumbs} />
        <div className="rwe-solution-type-page__header">
          <div className="rwe-content-container">
            {headerText ? (
              <div className="rwe-solution-type-page__header-text">
                <h1 className="rwe-h2">{headerText}</h1>
              </div>
            ) : null}
          </div>
        </div>
        <GalleryCarouselSection
          backgroundColor={colors.primary.white}
          data={assets}
        >
          {floatingCta ? <FloatingCta {...floatingCta} /> : null}
        </GalleryCarouselSection>
        {children ? (
          <div className="rwe-solution-type-page__reusable-components">
            {children}
          </div>
        ) : null}
      </div>
    );
  },
);
