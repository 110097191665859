import React from 'react';
import {
  ExtraSmallFindASpaceDropdownContent,
  ExtraSmallFindASpaceDropdownContentProps,
} from './FindASpaceDropdownContent.xs';
import {
  SmallFindASpaceDropdownContent,
  SmallFindASpaceDropdownContentProps,
} from './FindASpaceDropdownContent.sm';
import {RunwayEastButton} from '../button/RunwayEastButton';

export interface ResponsiveFindASpaceDropdownContentProps
  extends ExtraSmallFindASpaceDropdownContentProps,
    SmallFindASpaceDropdownContentProps {
  onSubmit: () => any;
}

export const ResponsiveFindASpaceDropdownContent: React.FC<ResponsiveFindASpaceDropdownContentProps> = React.memo(
  ({onSubmit, ...rest}) => (
    <div className="rwe-find-a-space-dropdown__content">
      <ExtraSmallFindASpaceDropdownContent {...rest} onSubmit={onSubmit} />
      <SmallFindASpaceDropdownContent {...rest} />
      <RunwayEastButton variant="secondary" blockButton onClick={onSubmit}>
        Find a space
      </RunwayEastButton>
    </div>
  ),
);
