import React, {useEffect, useMemo, useState} from 'react';
import {useInView} from 'react-intersection-observer';

const ANIMATION_DURATION = 3000;

// Format of child text would be for example:
// - Hel>>>>><|0
// Essentially the '<|' will make a heading animate

export const AnimatedHeaderText: React.FC = ({children}) => {
  const [animated, setAnimated] = useState(false);
  const {ref, inView: inViewState} = useInView({
    /* Optional options */
    threshold: 1,
  });
  const [inView, setInView] = useState(inViewState);

  // We only want subsequent useEffects to retrigger on the initial time this is shown
  useEffect(() => {
    if (inViewState && !inView) {
      setInView(true);
    }
  }, [inViewState, inView]);

  const formattedString = useMemo(() => {
    if (!children) return null;
    const child =
      // eslint-disable-next-line no-nested-ternary
      typeof children === 'string'
        ? children
        : Array.isArray(children)
        ? children[0]
        : children;
    if (typeof child !== 'string') {
      return null;
    }
    const indexOfEnd = child.indexOf('><|');
    if (indexOfEnd === -1) return null;
    const cleaned = child.replace(/>/g, '');
    return cleaned.split('<|');
  }, [children]);

  useEffect(() => {
    let timeout1: number | undefined;
    let timeout2: number | undefined;
    let timeout3: number | undefined;
    let timeout4: number | undefined;
    const runAnimation = () => {
      if (!animated) {
        setAnimated(true);
        timeout2 = window.setTimeout(() => {
          setAnimated(false);
          timeout3 = window.setTimeout(() => {
            setAnimated(false);
            const nextTimeout = 4 + Math.random() * 10;
            timeout4 = window.setTimeout(runAnimation, nextTimeout * 1000);
          });
        }, ANIMATION_DURATION);
      }
    };
    // eslint-disable-next-line prefer-const

    if (formattedString && inView) {
      timeout1 = window.setTimeout(runAnimation, 1000);
    }

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      clearTimeout(timeout3);
      clearTimeout(timeout4);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedString, inView]);

  if (!formattedString) return <>{children}</>;
  const [first, second] = formattedString;
  return (
    <div ref={ref} className="rwe-animated-header">
      <span className="rwe-animated-header__segment">{first}</span>
      <span
        className={[
          'rwe-animated-header__spacer',
          animated ? '-animated' : '',
        ].join(' ')}
      />
      <span className="rwe-animated-header__segment">{second}</span>
    </div>
  );
};
