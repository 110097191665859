import React, {useMemo} from 'react';
import {RunwayEastButton} from '../button/RunwayEastButton';
import {FlexGrid} from '../flex-grid/FlexGrid';
import {
  ContentWithCopySection,
  ContentWithCopySectionProps,
} from './ContentWithCopySection';

interface HrefAndText {
  href: string;
  text: string;
}

export interface FeaturesSectionProps
  extends Omit<
    ContentWithCopySectionProps,
    'children' | 'cta' | 'copyPosition'
  > {
  link?: HrefAndText;
  children: React.ReactNodeArray;
}

export const FeaturesSection = React.memo(
  ({link, children, ...rest}: FeaturesSectionProps) => {
    const renderedCta = useMemo(
      () =>
        link ? (
          <RunwayEastButton variant="primary" href={link.href}>
            {link.text}
          </RunwayEastButton>
        ) : null,

      [link],
    );

    return (
      <div className="rwe-features-section">
        <ContentWithCopySection
          {...rest}
          copyPosition="on-left"
          cta={renderedCta}
        >
          <FlexGrid>{children}</FlexGrid>
        </ContentWithCopySection>
      </div>
    );
  },
);
