import React from 'react';
import {RunwayEastButton} from '../../button/RunwayEastButton';
import {LeftIcon} from '../../icon/LeftIcon';
import {RightIcon} from '../../icon/RightIcon';
import {useCarousel} from '../CarouselContext';

export const TestimonialCarouselLeftButton = React.memo(() => {
  const {scrollLeft} = useCarousel();

  return (
    <div className="rwe-testimonial-carousel__button rwe-testimonial-carousel__button--left">
      <RunwayEastButton
        variant="floating"
        onClick={scrollLeft}
        IconComponent={<LeftIcon />}
      />
    </div>
  );
});

export const TestimonialCarouselRightButton = React.memo(() => {
  const {scrollRight} = useCarousel();

  return (
    <div className="rwe-testimonial-carousel__button rwe-testimonial-carousel__button--right">
      <RunwayEastButton
        variant="floating"
        onClick={scrollRight}
        IconComponent={<RightIcon />}
      />
    </div>
  );
});
