import {FeatureBlockFragment} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {Feature} from '../feature/Feature';
import {resolveHrefFromLinkFields} from '../link/DatoLink';
import {DatoRunwayEastStructuredText} from '../text/DatoRunwayEastStructuredText';
import {colors} from '../theme';
import {FeaturesSection, FeaturesSectionProps} from './FeaturesSection';

export const transformDatoFeatureBlockFragment = ({
  heading,
  cta,
  backgroundColor,
  features,
  hideSeparatorLine,
  blockCardIdentify,
  blockMenuName,
  onLaptop,
  onMobile,
}: FeatureBlockFragment): FeaturesSectionProps => {
  const href = cta ? resolveHrefFromLinkFields(cta) : undefined;
  return {
    withLine: !hideSeparatorLine,
    blockId: blockCardIdentify as string,
    blockName: blockMenuName as string,
    LaptopVisible: onLaptop as string,
    MobileVisible: onMobile as string,
    backgroundColor: backgroundColor
      ? backgroundColor.hex || undefined
      : undefined,
    copy: <DatoRunwayEastStructuredText data={heading} />,
    link:
      cta && cta.name && href
        ? {
            href,
            text: cta.name,
          }
        : undefined,
    children: features
      .map((feature) => (
        <Feature
          backgroundColor={
            feature.backgroundColor
              ? feature.backgroundColor.hex || colors.secondary.red
              : colors.secondary.red
          }
          icon={
            feature.icon ? (
              <img
                alt={feature.title || feature.icon.url}
                src={feature.icon.url}
                loading="lazy"
              />
            ) : null
          }
          text={feature.title || undefined}
        />
      ))
      .filter(Boolean),
  };
};

export interface DatoFeaturesSectionProps extends FeatureBlockFragment {
  index: number;
}

export const DatoFeaturesSection: React.FC<DatoFeaturesSectionProps> = React.memo(
  (props) => {
    const cardGridSectionProps = useMemo((): FeaturesSectionProps => {
      return transformDatoFeatureBlockFragment(props);
    }, [props]);

    return <FeaturesSection {...cardGridSectionProps} />;
  },
);
