import {ECtaBlockFragment} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {CtaSection, CtaSectionProps} from './CtaSection';
import {transformDatoImageWithOverlappingContentFragment} from '../image-with-overlapping-content/DatoImageWithOverlappingContent';

export interface DatoCtaSectionProps extends ECtaBlockFragment {
  index: number;
}

const transformDatoCtaBlockFragment = ({
  backgroundColour,
  index,
  blockCardIdentify,
  blockMenuName,
  onLaptop,
  onMobile,
  ...rest
}: DatoCtaSectionProps): CtaSectionProps => ({
  ...transformDatoImageWithOverlappingContentFragment({
    ...rest,
    lazyLoadImage: index > 0,
  }),
  blockId: blockCardIdentify || undefined,
  blockName: blockMenuName || undefined,
  LaptopVisible: onLaptop || undefined,
  MobileVisible: onMobile || undefined,
  backgroundColor: backgroundColour
    ? backgroundColour.hex || undefined
    : undefined,
});

export const DatoCtaSection = React.memo((props: DatoCtaSectionProps) => {
  const contentWithImageOnSideProps = useMemo(
    (): CtaSectionProps => transformDatoCtaBlockFragment(props),
    [props],
  );

  return <CtaSection {...contentWithImageOnSideProps} />;
});
