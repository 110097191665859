import React from 'react';
import {FlexGrid, ItemsPerRowPerBreakpoint} from '../flex-grid/FlexGrid';
import {Logo, LogoProps} from '../logo/Logo';
import {
  ContentWithCopySection,
  ContentWithCopySectionProps,
} from './ContentWithCopySection';

export interface LogoWallSectionProps
  extends Omit<ContentWithCopySectionProps, 'copyPosition' | 'children'> {
  logos: LogoProps[];
}

const ITEMS_PER_ROW_PER_BREAKPOINT: ItemsPerRowPerBreakpoint = [3, 4, 6, 6, 6];

export const LogoWallSection = React.memo(
  ({logos, ...rest}: LogoWallSectionProps) => (
    <div className="rwe-logo-wall-section">
      <ContentWithCopySection {...rest} copyPosition="above">
        <FlexGrid
          noSpacing
          itemsPerRowPerBreakpoint={ITEMS_PER_ROW_PER_BREAKPOINT}
        >
          {logos.map((logo) => (
            <Logo {...logo} />
          ))}
        </FlexGrid>
      </ContentWithCopySection>
    </div>
  ),
);
