import React, {useEffect, useRef, HTMLAttributes} from 'react';

export interface CustomHtmlProps {
  children: string;
}

export const CustomHtml = React.memo(({children}: CustomHtmlProps) => {
  // We remove 'dangerouslySetInnerHTML' from props passed to the div
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!children || !divRef.current) throw "children prop cant't be null"
    const slotHtml = document.createRange().createContextualFragment(children) // Create a 'tiny' document and parse the html string
    divRef.current.innerHTML = '' // Clear the container
    divRef.current.appendChild(slotHtml) // Append the new content
  }, [children, divRef])

  return (
    <div
      className="rwe-custom-html"
      // eslint-disable-next-line react/no-danger
      ref={divRef}
    />
  );
});