import React, {useEffect} from 'react';
import {ArticleHeader, ArticleHeaderProps} from '../article/ArticleHeader';
import {BreadcrumbProps} from '../breadcrumb/Breadcrumb';
import {useNavbarContext} from '../navbar/NavbarContextProvider';
import {BreadcrumbsSection} from '../section/BreadcrumbsSection';
import {colors} from '../theme';

export interface ArticlePageProps extends ArticleHeaderProps {
  breadcrumbs: BreadcrumbProps[];
  children: React.ReactNode;
}

export const ArticlePage = React.memo(
  ({breadcrumbs, children, ...articleHeaderProps}: ArticlePageProps) => {
    const {setBackgroundColor} = useNavbarContext();

    useEffect(() => {
      setBackgroundColor(colors.primary.white);
    }, [setBackgroundColor]);

    return (
      <div className="rwe-article-page">
        <BreadcrumbsSection data={breadcrumbs} />
        <ArticleHeader {...articleHeaderProps} />
        {children ? (
          <div className="rwe-article-page__content">
            <div className="rwe-content-container">{children}</div>
          </div>
        ) : null}
      </div>
    );
  },
);
