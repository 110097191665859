import React from 'react';
import {Pill, PillProps} from './Pill';

export interface PillListProps {
  data: PillProps[];
}

export const PillList = React.memo(({data}: PillListProps) =>
  data.length ? (
    <div className="rwe-pill-list">
      {data.map((pillProps) => (
        <div className="rwe-pill-list__item">
          <Pill {...pillProps} />
        </div>
      ))}
    </div>
  ) : null,
);
