import {LogoWallFragment} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {transformDatoResponsiveImageFragment} from '../image/ImageRenderer';
import {DatoRunwayEastStructuredText} from '../text/DatoRunwayEastStructuredText';
import {LogoWallSection, LogoWallSectionProps} from './LogoWallSection';

export interface DatoLogoWallSectionProps extends LogoWallFragment {
  index: number;
}

const transformDatoLogoWallFragment = (
  props: DatoLogoWallSectionProps,
): LogoWallSectionProps => {
  // Each of the values in the returned object should be from the fragment argument
  return {
    withLine: !props.hideSeparatorLine,
    blockId: props.blockCardIdentify as string,
    blockName: props.blockMenuName as string,
    MobileVisible: props.onMobile as string,
    LaptopVisible: props.onLaptop as string,
    logos: props.logos.map((logo) => ({
      image: transformDatoResponsiveImageFragment(logo, props.index > 0),
    })),
    copy: <DatoRunwayEastStructuredText data={props.heading} />,
    backgroundColor: props.backgroundColour
      ? props.backgroundColour.hex || undefined
      : undefined,
  };
};

export const DatoLogoWallSection: React.FC<DatoLogoWallSectionProps> = React.memo(
  (props) => {
    const logoWallSectionProps = useMemo((): LogoWallSectionProps => {
      return transformDatoLogoWallFragment(props);
    }, [props]);

    return <LogoWallSection {...logoWallSectionProps} />;
  },
);
