import React from 'react';
import {CtaBox} from '../e-box/CtaBox';
import {Embed} from '../embed/Embed';
import {CallIcon} from '../icon/CallIcon';
import {EnvelopeIcon} from '../icon/EnvelopeIcon';
import {LocationIcon} from '../icon/LocationIcon';
import {colors} from '../theme';

export interface EmbedWithContactDetailsSectionProps {
  heading: React.ReactNode;
  address: React.ReactNode;
  emailAddress?: string;
  phoneNumber?: string;
  iframeSrc?: string;
}

export const EmbedWithContactDetailsSection = React.memo(
  ({
    address,
    iframeSrc,
    heading,
    emailAddress,
    phoneNumber,
  }: EmbedWithContactDetailsSectionProps) => (
    <div className="rwe-embed-with-contact-details-section">
      <div className="rwe-content-container">
        <div className="rwe-embed-with-contact-details-section__content-container">
          {iframeSrc ? (
            <div className="rwe-embed-with-contact-details-section__embed">
              <Embed src={iframeSrc} />
            </div>
          ) : null}
          <div className="rwe-embed-with-contact-details-section__ebox">
            <CtaBox
              backgroundColor={colors.primary.teal}
              textColor={colors.primary.white}
            >
              {heading ? (
                <div className="rwe-embed-with-contact-details-section__ebox-heading">
                  {heading}
                </div>
              ) : null}
              {phoneNumber ? (
                <div className="rwe-embed-with-contact-details-section__ebox-phone-number">
                  <div className="rwe-embed-with-contact-details-section__ebox-icon">
                    <CallIcon color={colors.slate.darkSlate} />
                  </div>
                  <div className="rwe-p1">
                    <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                  </div>
                </div>
              ) : null}
              {emailAddress ? (
                <div className="rwe-embed-with-contact-details-section__ebox-email-address">
                  <div className="rwe-embed-with-contact-details-section__ebox-icon">
                    <EnvelopeIcon color={colors.slate.darkSlate} />
                  </div>
                  <div className="rwe-p1">
                    <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                  </div>
                </div>
              ) : null}
              {address ? (
                <div className="rwe-embed-with-contact-details-section__ebox-address">
                  <div className="rwe-embed-with-contact-details-section__ebox-icon">
                    <LocationIcon color={colors.slate.darkSlate} />
                  </div>
                  <div className="rwe-embed-with-contact-details-section__ebox-address-text">
                    {address}
                  </div>
                </div>
              ) : null}
            </CtaBox>
          </div>
        </div>
      </div>
    </div>
  ),
);
