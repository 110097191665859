const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const getNumberSuffix = (number: number) => {
  if (number === 1 || (number > 20 && number.toString().endsWith('1')))
    return 'st';
  if (number === 2 || (number > 20 && number.toString().endsWith('2')))
    return 'nd';
  if (number === 3 || (number > 20 && number.toString().endsWith('3')))
    return 'rd';

  return 'th';
};

export const formatDate = (date: Date): string =>
  `${date.getDate()}${getNumberSuffix(date.getDate())} ${
    months[date.getMonth()]
  } ${date.getFullYear()}`;
