import {ECtaBlockFragment} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {DatoButtonLink} from '../link/DatoLink';
import {DatoRunwayEastStructuredText} from '../text/DatoRunwayEastStructuredText';
import {colors} from '../theme';
import {
  ImageWithOverlappingContent,
  ImageWithOverlappingContentProps,
} from './ImageWithOverlappingContent';
import {CtaBox} from '../e-box/CtaBox';
import {transformDatoResponsiveImageFragment} from '../image/ImageRenderer';

type DatoContentFragmentProps = Pick<
  ECtaBlockFragment,
  'structuredText' | 'cta'
>;

const DatoContentFragment = React.memo(
  ({structuredText, cta}: DatoContentFragmentProps) => (
    <CtaBox
      textColor={colors.primary.white}
      backgroundColor={colors.primary.teal}
    >
      <div>
        {structuredText ? (
          <DatoRunwayEastStructuredText
            data={structuredText}
            runwayEastStyle={{
              h1: {
                classNames: ['rwe-h1'],
                style: {color: colors.primary.white},
              },
              h2: {
                classNames: ['rwe-h2'],
                style: {color: colors.primary.white},
              },
              h3: {
                classNames: ['rwe-h3'],
                style: {color: colors.primary.white},
              },
              h4: {
                classNames: ['rwe-h4-alt'],
                style: {color: colors.primary.white},
              },
              h5: {
                classNames: ['rwe-h4-alt'],
                style: {color: colors.primary.white},
              },
              h6: {
                classNames: ['rwe-h4-alt'],
                style: {color: colors.primary.white},
              },
              p: {
                classNames: ['rwe-p1'],
                style: {color: colors.primary.white},
              },
            }}
          />
        ) : null}
      </div>
      {cta && cta.name ? (
        <div style={{marginTop: 32}}>
          <DatoButtonLink data={cta} />
        </div>
      ) : null}
    </CtaBox>
  ),
);

export interface DatoImageWithOverlappingContentProps
  extends ECtaBlockFragment {
  lazyLoadImage: boolean;
}

export const transformDatoImageWithOverlappingContentFragment = ({
  cta,
  image,
  structuredText,
  lazyLoadImage,
}: DatoImageWithOverlappingContentProps): ImageWithOverlappingContentProps => ({
  image: image
    ? transformDatoResponsiveImageFragment(image, lazyLoadImage)
    : undefined,
  children: <DatoContentFragment structuredText={structuredText} cta={cta} />,
});

export const DatoImageWithOverlappingContent = React.memo(
  (props: DatoImageWithOverlappingContentProps) => {
    const contentWithImageOnSideProps = useMemo(
      (): ImageWithOverlappingContentProps =>
        transformDatoImageWithOverlappingContentFragment(props),
      [props],
    );

    return <ImageWithOverlappingContent {...contentWithImageOnSideProps} />;
  },
);
