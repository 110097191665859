import React, {useEffect, useMemo} from 'react';
import {CustomHtml} from '../custom-html/CustomHtml';
import {useFloatingCta} from '../floating-cta/FloatingCtaContext';

export interface CustomHtmlSectionProps {
  backgroundColor?: string;
  html: string;
  withArticleLikeContainerWidth?: boolean;
  withLine?: boolean;
  blockId?: string,
  blockName?: string;
  LaptopVisible?: string;
  MobileVisible?: string;
}

export const CustomHtmlSection = React.memo(
  ({
    backgroundColor,
    html,
    withArticleLikeContainerWidth = false,
    withLine = true,
    blockId,
    blockName,
    LaptopVisible,
    MobileVisible,
  }: CustomHtmlSectionProps) => {
    const floatingCta = useFloatingCta();
    const style = useMemo(
      () => (backgroundColor ? {backgroundColor} : undefined),
      [backgroundColor],
    );
    const contentContainerStyle = useMemo(
      () => (withArticleLikeContainerWidth ? {width: 780} : undefined),
      [withArticleLikeContainerWidth],
    );
    const sectionClassNames = useMemo((): string => {
      const classNames = ['rwe-section'];

      if (!withLine) {
        classNames.push('rwe-section--without-line');
      }

      return classNames.join(' ');
    }, [withLine]);

    const closePopUpBtn = () => {
      const formPopUp = document.getElementById('popUpFormBlock');
      const meetPopUp = document.getElementById('popUpFormBlockMeeting');
      const formPopUp1 = document.getElementById('popUpFormBlock1');
      const meetPopUp1 = document.getElementById('popUpFormBlockMeeting1');
      const searchForm = document.getElementById('searchForm');
      if(formPopUp != null)
      {
        formPopUp.style.display = 'none';
      }
      else{
        if(meetPopUp != null){
          meetPopUp.style.display = 'none';
        }
      }
      if(formPopUp1 != null)
      {
        formPopUp1.style.display = 'none';
      }
      else{
        if(meetPopUp1 != null){
          meetPopUp1.style.display = 'none';
        }
      }
      if(searchForm != null){
        searchForm.style.display = 'none';
      }
    }
    useEffect(() => {
      document.addEventListener('keydown', function (e) {
        const formPopUp = document.getElementById('popUpFormBlock')
        const meetPopUp = document.getElementById('popUpFormBlockMeeting');
        const formPopUp1 = document.getElementById('popUpFormBlock1')
        const meetPopUp1 = document.getElementById('popUpFormBlockMeeting1')
        if(e.keyCode === 27) {
          if(formPopUp != null){
            formPopUp.style.display = 'none';
          } 
          else{
            if(meetPopUp != null){
              meetPopUp.style.display = 'none';
            }
          }
          if(formPopUp1 != null){
            formPopUp1.style.display = 'none';
          } 
          else{
            if(meetPopUp1 != null){
              meetPopUp1.style.display = 'none';
            }
          }
        }
      });
    })
    let DisplayMobile = '';
    let DisplayLaptop = '';
    if(MobileVisible == 'no'){
      DisplayMobile = 'DontDisplayMobile'
    }
    if(MobileVisible == 'yes'){
      DisplayMobile = 'DisplayMobile'
    }
    if(LaptopVisible == 'no'){
      DisplayLaptop = 'DontDisplayLaptop'
    }
    if(LaptopVisible == 'yes'){
      DisplayLaptop = 'DisplayLaptop'
    }
    return (
      <div className={"rwe-custom-html-section" + ' ' + DisplayMobile + ' ' + DisplayLaptop} id={blockId == '' ? undefined : blockId} attr-name={blockName == '' ? undefined : blockName}>
        <div className={sectionClassNames} style={style}>
          <div className="rwe-content-container" style={contentContainerStyle}>
            <div className='CloseBtn' onClick={closePopUpBtn}></div>
            {withLine ? <div className="rwe-section__line" /> : null}
            <div style={floatingCta.avoidanceStyle}>
              <CustomHtml>{html}</CustomHtml>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
