import React from 'react';
import {
  TestimonialCarousel,
  TestimonialCarouselProps,
} from '../carousel/testimonial/TestimonialCarousel';
import {
  ContentWithCopySection,
  ContentWithCopySectionProps,
} from './ContentWithCopySection';

export interface TestimonialsCarouselSectionProps
  extends TestimonialCarouselProps,
    Omit<ContentWithCopySectionProps, 'copyPosition' | 'children'> {}

export const TestimonialsCarouselSection = React.memo(
  ({data, ...rest}: TestimonialsCarouselSectionProps) => (
    <div className="rwe-testimonials-carousel-section">
      <ContentWithCopySection {...rest} copyPosition="above">
        <TestimonialCarousel data={data} />
      </ContentWithCopySection>
    </div>
  ),
);
