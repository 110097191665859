import {CardGridFragment} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {ArticleCard} from '../card/ArticleCard';
import {MemberCard} from '../card/MemberCard';
import {OfficeCard} from '../card/OfficeCard';
import {SolutionCard} from '../card/SolutionCard';
import {WorkspaceCard} from '../card/WorkspaceCard';
import {transformDatoResponsiveImageFragment} from '../image/ImageRenderer';
import {datoModelToHref, resolveHrefFromLinkFields} from '../link/DatoLink';
import {DatoRunwayEastStructuredText} from '../text/DatoRunwayEastStructuredText';
import {CardGridSection, CardGridSectionProps} from './CardGridSection';

export const transformDatoCardGridFragment = ({
  backgroundColour,
  heading,
  cards,
  numberOfColumns,
  link,
  hideSeparatorLine,
  blockCardIdentify,
  blockMenuName,
  onMobile,
  onLaptop,
  index: blockIndex,
}: DatoCardGridSectionProps): CardGridSectionProps => {
  const href = link ? resolveHrefFromLinkFields(link) : undefined;
  return {
    withLine: !hideSeparatorLine,
    blockId: blockCardIdentify as string,
    blockName: blockMenuName as string,
    LaptopVisible: onLaptop as string,
    MobileVisible: onMobile as string,
    backgroundColor: backgroundColour
      ? backgroundColour.hex || undefined
      : undefined,
    title: <DatoRunwayEastStructuredText data={heading} />,
    link:
      link && link.name && href
        ? {
            href,
            text: link.name,
            htmlId: link.externalElementId || undefined,
          }
        : undefined,
    numberOfColumnsAtLgBreakpoint: numberOfColumns
      ? Number(numberOfColumns)
      : 4,
    children: cards
      .map((card, index) => {
        // eslint-disable-next-line no-underscore-dangle
        if (card.__typename === 'LocationSiteRecord') {
          const transformedFragment = card.cardImage
            ? transformDatoResponsiveImageFragment(
                card.cardImage,
                blockIndex > 0 ? true : index > 5,
              )
            : undefined;
          return (
            <OfficeCard
              href={datoModelToHref(card) || undefined}
              image={
                typeof transformedFragment === 'object'
                  ? {
                      ...transformedFragment,
                      aspectRatio: 16 / 9,
                    }
                  : transformedFragment
              }
              name={card.cardHeading || undefined}
              phoneNumber={card.cardPhoneNumber || undefined}
              addressLines={card.cardAddress ? card.cardAddress.split(',') : []}
              stationDistance={card.cardDistance || undefined}
              pricePerDesk={card.cardPrice || undefined}
              optionalText={card.cardOptionalText || undefined}
              locationOffice={card.cardLocationOffice || undefined}
            />
          );
        }
        // eslint-disable-next-line no-underscore-dangle
        if (card.__typename === 'SolutionTypeRecord') {
          const transformedFragment = card.cardImage
            ? transformDatoResponsiveImageFragment(
                card.cardImage,
                blockIndex > 0 ? true : index > 5,
              )
            : undefined;
          return (
            <SolutionCard
              image={
                typeof transformedFragment === 'object'
                  ? {
                      ...transformedFragment,
                      aspectRatio: 16 / 9,
                    }
                  : transformedFragment
              }
              heading={card.cardHeading || undefined}
              subHeading={card.cardSubheading || undefined}
              copy={card.cardCopy || undefined}
              href={datoModelToHref(card) || undefined}
            />
          );
        }
        // eslint-disable-next-line no-underscore-dangle
        if (card.__typename === 'GenericCardRecord') {
          const transformedFragment = card.image
            ? transformDatoResponsiveImageFragment(
                card.image,
                blockIndex > 0 ? true : index > 5,
              )
            : undefined;
          return (
            <SolutionCard
              image={
                typeof transformedFragment === 'object'
                  ? {
                      ...transformedFragment,
                      aspectRatio: 16 / 9,
                    }
                  : transformedFragment
              }
              heading={card.heading || undefined}
              subHeading={card.subheading || undefined}
              copy={card.body || undefined}
              href={
                card.link ? resolveHrefFromLinkFields(card.link) : undefined
              }
              linkText={card.link?.name || undefined}
            />
          );
        }
        // eslint-disable-next-line no-underscore-dangle
        if (card.__typename === 'MemberCardRecord') {
          return (
            <MemberCard
              image={
                card.image
                  ? transformDatoResponsiveImageFragment(
                      card.image,
                      blockIndex > 0 ? true : index > 5,
                    )
                  : undefined
              }
              name={card.member || undefined}
              memberTitle={card.memberTitle || undefined}
              copy={card.bodyCopy || undefined}
              logoImageSrc={card.logo?.url}
            />
          );
        }
        // eslint-disable-next-line no-underscore-dangle
        if (card.__typename === 'ArticleRecord') {
          return (
            <ArticleCard
              image={
                card.cardImage
                  ? transformDatoResponsiveImageFragment(
                      card.cardImage,
                      blockIndex > 0 ? true : index > 5,
                    )
                  : undefined
              }
              heading={card.cardTitle || undefined}
              byline={card.cardByline || undefined}
              copy={card.cardExcerpt || undefined}
              href={card.pagePath as string}
              category={card.categories[0]?.categoryName || undefined}
              categoryColor={
                card.categories[0]?.categoryColour?.hex || undefined
              }
            />
          );
        }
        // eslint-disable-next-line no-underscore-dangle
        if (card.__typename === 'WorkspaceRecord') {
          return (
            <WorkspaceCard
              withinCardGrid
              href={card.urlPath || undefined}
              name={card.name || ''}
              image={
                card.cardImage
                  ? transformDatoResponsiveImageFragment(card.cardImage)
                  : undefined
              }
              price={card.price || ''}
              siteName={card.site?.siteName || ''}
              capacity={card.capacity || ''}
              labelColor={card.cardLabelColour?.hex || ''}
              labelText={card.cardLabel || undefined}
              transportLinks={
                card.site?.transportLinkCopy1 || card.site?.transportLinkCopy2
                  ? [
                      {
                        imageSrc:
                          card.site.transportLinkImage1?.url || undefined,
                        text: card.site.transportLinkCopy1 || '',
                      },
                      {
                        imageSrc:
                          card.site.transportLinkImage2?.url || undefined,
                        text: card.site.transportLinkCopy2 || '',
                      },
                    ].filter((obj) => obj.text)
                  : undefined
              }
            />
          );
        }
        return null;
      })
      .filter(Boolean),
  };
};

export interface DatoCardGridSectionProps extends CardGridFragment {
  index: number;
}

export const DatoCardGridSection: React.FC<DatoCardGridSectionProps> = React.memo(
  (props) => {
    const cardGridSectionProps = useMemo((): CardGridSectionProps => {
      return transformDatoCardGridFragment(props);
    }, [props]);

    return <CardGridSection {...cardGridSectionProps} />;
  },
);
