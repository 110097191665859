import React, {useCallback, useState} from 'react';
import {RunwayEastButton} from '../button/RunwayEastButton';
import {Dropdown} from '../dropdown/Dropdown';
import {
  ResponsiveFindASpaceDropdownContent,
  ResponsiveFindASpaceDropdownContentProps,
} from './ResponsiveFindASpaceDropdownContent';

export type FindASpaceDropdownProps = ResponsiveFindASpaceDropdownContentProps;

export const FindASpaceDropdown = React.memo<FindASpaceDropdownProps>(
  (props) => {
    const [isDropdownShowing, setIsDropdownShowing] = useState(false);
    const handleClick = useCallback(
      () => setIsDropdownShowing((value) => !value),
      [],
    );
    return (
      <div className="rwe-find-a-space-dropdown">
        <RunwayEastButton
          variant="primary"
          RightIconComponent={
            <div className="rwe-find-a-space-dropdown__arrow" />
          }
          onClick={handleClick}
        >
          Find your space
        </RunwayEastButton>

        <Dropdown variant="find-a-space" isShowing={isDropdownShowing}>
          <ResponsiveFindASpaceDropdownContent {...props} />
        </Dropdown>
      </div>
    );
  },
);
