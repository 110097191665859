import React, {useEffect, useState} from 'react';
import {BreadcrumbProps} from '../breadcrumb/Breadcrumb';
import {GalleryCarouselItem} from '../carousel/gallery/GalleryCarousel';
import {FloatingCta, FloatingCtaProps} from '../floating-cta/FloatingCta';
import {HeaderTextMask} from '../header/HeaderTextMask';
import {useNavbarContext} from '../navbar/NavbarContextProvider';
import {BreadcrumbsSection} from '../section/BreadcrumbsSection';
import {GalleryCarouselSection} from '../section/GalleryCarouselSection';
import {colors} from '../theme';
import { useRouter } from 'next/router'

export interface LocationSitePageProps {
  headerText?: string;
  maskedImageSrc?: string;
  address?: string;
  phoneNumber?: string;
  addressLocation?: string;
  emailAddress?: string;
  assets: GalleryCarouselItem[];
  children?: React.ReactNode;
  floatingCta?: FloatingCtaProps;
  breadcrumbs: BreadcrumbProps[];
}

export const LocationSitePage = React.memo(
  ({
    address,
    children,
    headerText,
    maskedImageSrc,
    phoneNumber,
    addressLocation,
    emailAddress,
    assets,
    floatingCta,
    breadcrumbs,
  }: LocationSitePageProps) => {
    const {setBackgroundColor} = useNavbarContext();

    useEffect(() => {
      setBackgroundColor(colors.primary.white);
    }, [setBackgroundColor]);

    const [headers, setHeaders] = useState<{ id: string, name: string }[]>([]);

    const { asPath } = useRouter()

    useEffect(() => {
      const getElements = () => {
        const elements = document.querySelectorAll('[attr-name]');
        const data: { id: string, name: string }[] = [];

        elements.forEach(el => {
          data.push({
            id: el.id,
            name: el.getAttribute('attr-name') || ''
          })
        })
        setHeaders(data);
      }

      getElements();

    }, [asPath])
    const [heig, setHeig] = useState(0);

    useEffect(() => {
      if (document.getElementById('openPopUp') != undefined){
        let elem = document.getElementById('openPopUp');
        elem?.addEventListener('click' , () => {
          setTimeout(() => {
            if(document.querySelectorAll('.hs-form-iframe')[0] != undefined){
              document.querySelectorAll<HTMLElement>('.hs-form-iframe')[0].style.height = '624px'
            }
            if(document.querySelectorAll('.hs-form-iframe')[1] != undefined){
              document.querySelectorAll<HTMLElement>('.hs-form-iframe')[1].style.height = '624px'
            }
            if(document.querySelectorAll('.hs-form-iframe')[2] != undefined){
              document.querySelectorAll<HTMLElement>('.hs-form-iframe')[2].style.height = '624px'
            }
          }, 200)
        })
      }
      if (document.getElementById('openPopUp1') != undefined){
        let elem = document.getElementById('openPopUp1');
        elem?.addEventListener('click' , () => {
          setTimeout(() => {
            if(document.querySelectorAll('.hs-form-iframe')[0] != undefined){
              document.querySelectorAll<HTMLElement>('.hs-form-iframe')[0].style.height = '624px'
            }
            if(document.querySelectorAll('.hs-form-iframe')[1] != undefined){
              document.querySelectorAll<HTMLElement>('.hs-form-iframe')[1].style.height = '624px'
            }
            if(document.querySelectorAll('.hs-form-iframe')[2] != undefined){
              document.querySelectorAll<HTMLElement>('.hs-form-iframe')[2].style.height = '624px'
            }
          }, 200)
        })
      }
      setTimeout(() => {
        if(document.querySelectorAll('.hs-form-iframe')[0] != undefined){
          document.querySelectorAll<HTMLElement>('.hs-form-iframe')[0].style.height = '624px'
        }
        if(document.querySelectorAll('.hs-form-iframe')[1] != undefined){
          document.querySelectorAll<HTMLElement>('.hs-form-iframe')[1].style.height = '624px'
        }
        if(document.querySelectorAll('.hs-form-iframe')[2] != undefined){
          document.querySelectorAll<HTMLElement>('.hs-form-iframe')[2].style.height = '624px'
        }
      }, 5000)
      let heii = document.getElementsByClassName('rwe-navbar-container')[0].clientHeight;
      document.querySelectorAll<HTMLElement>('.rwe-custom-site-page__subHeader')[0].style.paddingTop = heii+14+'px';
      let oldValue = 0;
      window.addEventListener('scroll', function(e){

        // Get the new Value
        let newValue = window.pageYOffset;
    
        //Subtract the two and conclude
        if(oldValue - newValue < 0){
          document.querySelectorAll<HTMLElement>('.rwe-custom-site-page__subHeader')[0].style.paddingTop = '14px';
            //console.log("Up");
        } else if(oldValue - newValue > 0){
          document.querySelectorAll<HTMLElement>('.rwe-custom-site-page__subHeader')[0].style.paddingTop = heii+14+'px';
            //console.log("Down");
        }
        if(document.getElementsByClassName('rwe-navbar-container')[0].classList.contains('rwe-navbar-container--hidden')){
          document.querySelectorAll<HTMLElement>('.rwe-custom-site-page__subHeader')[0].style.paddingTop = '14px';        
        }
        // Update the old value
        oldValue = newValue;
    });
      setHeig(heii)
    }, [])

    //console.log("email: " + emailAddress);
    //console.log("phone: " + phoneNumber);
    //console.log("height: " + heig)


    useEffect(() => {
      if(headers.length < 2){
        document.querySelectorAll<HTMLElement>('.rwe-custom-site-page__subHeader')[0].style.display = 'none';
      }
      else{
        document.querySelectorAll<HTMLElement>('.rwe-custom-site-page__subHeader')[0].style.display = 'flex';
      }
    })


    const [activeSection, setActiveSection] = useState<string | null>(null);

    useEffect(() => {
      const handleScroll = () => {
        // Get a list of all the sections on the page
        const sections = Array.from(document.querySelectorAll('[attr-name]'));
        sections.reverse();
        
  
        // Loop through the sections and check if the scroll position is past each one
        for (const section of sections) {
          let head = document.querySelector('[href="#'+section.id+'"]') as HTMLElement | null;
          let otherhead = document.querySelectorAll<HTMLElement>('.rwe-custom-site-page__subHeader li a');
          if (otherhead != null){
            otherhead.forEach(box => { box.style.color = "black"; box.style.borderBottom = 'none' })
          }
          if (section != null){
            if (section instanceof HTMLElement){
              if (window.scrollY >= (section.offsetTop - 100)) {
                if (head != null){
                  head.style.borderBottom = '5px solid #20caab'
                  head.style.color = '#20caab'
                }
                setActiveSection(section.id);
                break;
              }
            }
          }
        }
        
      };
  
      // Attach the event listener
      window.addEventListener('scroll', handleScroll);
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    const handleClick = () => {
      // @ts-ignore
      window.__rwe_scroll_locked = true;


      // @ts-ignore
      setTimeout(()=>{
        // @ts-ignore
        window.__rwe_scroll_locked = false;
      }
        , 750)
      
    }
    return (
      <div className="rwe-location-site-page">
        <div className='rwe-custom-site-page__subHeader'>
          {headers.map((element, i) => 
            <li key={i}><a href={"#" + element.id} onClick={handleClick}>{element.name}</a></li>
          )}
        </div>
        <BreadcrumbsSection data={breadcrumbs} />
        <div className="rwe-location-site-page__header">
          <div className="rwe-content-container">
            {headerText ? (
              <div className="rwe-location-site-page__header-text">
                <HeaderTextMask imageSrc={maskedImageSrc}>
                  {headerText}
                </HeaderTextMask>
              </div>
            ) : null}
            {address || phoneNumber ? (
              <div className="rwe-location-site-page__address-and-phone-number">
                {address ? (
                  <div className="rwe-location-site-page__address">
                    <a href={addressLocation} className="rwe-p1" target='_blank'>{address}</a>
                  </div>
                ) : null}
                {address && phoneNumber ? (
                  <div className="rwe-location-site-page__address-dot" />
                ) : null}
                {phoneNumber ? (
                  <div className="rwe-location-site-page__phone-number">
                    <p className="rwe-p1">
                      <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                    </p>
                  </div>
                ) : null}
                {phoneNumber && emailAddress ? (
                  <div className="rwe-location-site-page__address-dot" />
                ) : null}
               {emailAddress ? (
                  <div className="rwe-location-site-page__email-address">
                    <p className="rwe-p1">
                      <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                    </p>
                  </div>
                ) : null}                
              </div>
            ) : null}
          </div>
        </div>
        <GalleryCarouselSection
          backgroundColor={colors.primary.white}
          data={assets}
        >
          {floatingCta ? <FloatingCta {...floatingCta} /> : null}
        </GalleryCarouselSection>
        {children ? (
          <div className="rwe-location-site-page__reusable-components">
            {children}
          </div>
        ) : null}
      </div>
    );
  },
);
