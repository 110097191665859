import React from 'react';
import {BreadcrumbProps} from './Breadcrumb';
import {BreadcrumbListItem} from './BreadcrumbListItem';

export interface BreadcrumbListProps {
  data: BreadcrumbProps[];
}

export const BreadcrumbList = React.memo(({data}: BreadcrumbListProps) =>
  data.length ? (
    <div className="rwe-breadcrumb-list">
      {data.map((breadcrumbProps, index) => {
        const isLastItem = index === data.length - 1;
        return (
          <BreadcrumbListItem
            {...breadcrumbProps}
            key={breadcrumbProps.children}
            isLastItem={isLastItem}
          />
        );
      })}
    </div>
  ) : null,
);
