import {TestimonialGridFragment} from 'graphql/generated/graphql';
import React from 'react';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';
import {DatoRunwayEastStructuredText} from '../text/DatoRunwayEastStructuredText';
import {colors} from '../theme';
import {ContentWithCopySection} from './ContentWithCopySection';

export interface DatoTestimonialGridProps extends TestimonialGridFragment {
  index: number;
}

export const DatoTestimonialGridSection = React.memo(
  ({blockCardIdentify, blockMenuName, onLaptop, onMobile, ...rest}: DatoTestimonialGridProps) => {
    let DisplayMobile = '';
    let DisplayLaptop = '';
    if(onMobile == 'no'){
      DisplayMobile = 'DontDisplayMobile'
    }
    if(onMobile == 'yes'){
      DisplayMobile = 'DisplayMobile'
    }
    if(onLaptop == 'no'){
      DisplayLaptop = 'DontDisplayLaptop'
    }
    if(onLaptop == 'yes'){
      DisplayLaptop = 'DisplayLaptop'
    }
    return (
      <div className={"rwe-testimonial-grid-section" + ' ' + DisplayMobile + ' ' + DisplayLaptop} id={blockCardIdentify == null ? undefined : blockCardIdentify} attr-name={blockMenuName == '' ? undefined : blockMenuName}>
        <ContentWithCopySection
          {...rest}
          backgroundColor={colors.slate.veryLightSlate}
          copy={<DatoRunwayEastStructuredText data={rest.heading} />}
          copyPosition="above"
        >
          <ResponsiveMasonry
            columnsCountBreakPoints={{350: 1, 750: 2, 1050: 3}}
          >
            <Masonry gutter="32px">
              {rest.testimonials.map((testimonial) => (
                <div className="rwe-testimonial-grid-section__card">
                  <div className="rwe-testimonial-grid-section__head">
                    <svg
                      width="75"
                      height="61"
                      viewBox="0 0 75 61"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.0526 0.0996094C6.57895 0.0996094 0 6.97186 0 21.245V60.0996H31.5789V26.5313H16.3158V22.5666C16.3158 17.5445 19.2105 14.6371 24.2105 14.6371H31.5789V0.0996094H21.0526ZM64.4737 0.0996094C50 0.0996094 43.4211 6.97186 43.4211 21.245V60.0996H75V26.5313H59.7368V22.5666C59.7368 17.5445 62.6316 14.6371 67.6316 14.6371H75V0.0996094H64.4737Z"
                        fill="#7770FF"
                      />
                    </svg>
                    <img
                      className="rwe-structured-text__icon"
                      src={testimonial.logo?.url}
                      alt={testimonial.logo?.alt || testimonial.logo?.url}
                      loading="lazy"
                    />
                  </div>
                  <div className="rwe-testimonial-grid-section__card-content">
                    <DatoRunwayEastStructuredText data={testimonial.quote} />
                  </div>
                  <div className="rwe-testimonial-grid-section__quotee">
                    {testimonial.quotee}
                  </div>
                </div>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </ContentWithCopySection>
      </div>
    );
  },
);
