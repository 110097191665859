import React, {useMemo} from 'react';

export interface CtaBoxProps {
  backgroundColor: string;
  textColor: string;
  children: React.ReactNode;
}

export const CtaBox = React.memo(
  ({backgroundColor, textColor, children}: CtaBoxProps) => {
    const contentStyle = useMemo(
      () => ({
        color: textColor,
        backgroundColor,
      }),
      [backgroundColor, textColor],
    );
    return (
      <div className="rwe-cta-box">
        <div className="rwe-cta-box__content" style={contentStyle}>
          {children}
        </div>
      </div>
    );
  },
);
