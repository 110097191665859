import React from 'react';
import {ImageRendererConfig, OptimizedImage} from './ImageRenderer';

export interface ImageWithCaptionProps {
  image: ImageRendererConfig;
  caption?: string;
}

export const ImageWithCaption = React.memo(
  ({image, caption}: ImageWithCaptionProps) => (
    <div className="rwe-image-with-caption">
      <OptimizedImage data={image} />
      {caption ? (
        <div className="rwe-image-with-caption__caption">
          <p className="rwe-p2">{caption}</p>
        </div>
      ) : null}
    </div>
  ),
);
