import React, {useMemo} from 'react';
import {CallIcon} from '../icon/CallIcon';
import {LocationIcon} from '../icon/LocationIcon';
import {ImageRendererConfig, OptimizedImage} from '../image/ImageRenderer';
import {RunwayEastLink} from '../link/RunwayEastLink';

export interface OfficeCardProps {
  image?: ImageRendererConfig;
  name?: string;
  addressLines?: string[];
  phoneNumber?: string;
  stationDistance?: string;
  pricePerDesk?: string;
  optionalText?: string;
  locationOffice?: string;
  href?: string;
}

export const OfficeCard = React.memo(
  ({image, name, addressLines, phoneNumber, stationDistance, pricePerDesk, optionalText, locationOffice, href}: OfficeCardProps) => {
    const renderedAddressLines = useMemo(
      () =>
        addressLines && addressLines.length
          ? addressLines.map((addressLine) => <div>{addressLine}</div>)
          : null,
      [addressLines],
    );
    return (
      <div className="rwe-card rwe-card--office">
        {image ? (
          <div className="rwe-card__image">
            <RunwayEastLink href={href}>
              <OptimizedImage data={image} />
            </RunwayEastLink>
          </div>
        ) : null}
        {name ? (
            <RunwayEastLink href={href}>
              <div className="rwe-card__heading">{name}</div>
            </RunwayEastLink>
        ) : null}
        {renderedAddressLines ? (
          <div className="rwe-card__icon-and-text-container">
            <div className="rwe-card--office__icon">
              <LocationIcon />
            </div>
            <div className="rwe-card--office__address card-address">
              <a href={locationOffice} target="_blank">
                {renderedAddressLines}
              </a>
            </div>
          </div>
        ) : null}
        {/* {phoneNumber ? (
          <div className="rwe-card__icon-and-text-container">
            <div className="rwe-card--office__icon">
              <CallIcon />
            </div>
            <div className="rwe-card--office__number">
              <RunwayEastLink href={href}>{phoneNumber}</RunwayEastLink>
            </div>
          </div>
        ) : null} */}
        {stationDistance ? (
          <div className="rwe-card__icon-and-text-container">
            <div className="rwe-card--undeground__icon">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0v24h24V0H0zm22 22H2V8.86C2 6.05 3.53 3.84 6.2 2.8 8 2.09 10.14 2 12 2c1.86 0 4 .09 5.8.8C20.47 3.84 22 6.05 22 8.86V22z" fill="none"/><circle cx="15.5" cy="16" r="1"/><circle cx="8.5" cy="16" r="1"/><path d="M7.01 9h10v5h-10zM17.8 2.8C16 2.09 13.86 2 12 2c-1.86 0-4 .09-5.8.8C3.53 3.84 2 6.05 2 8.86V22h20V8.86c0-2.81-1.53-5.02-4.2-6.06zm.2 13.08c0 1.45-1.18 2.62-2.63 2.62l1.13 1.12V20H15l-1.5-1.5h-2.83L9.17 20H7.5v-.38l1.12-1.12C7.18 18.5 6 17.32 6 15.88V9c0-2.63 3-3 6-3 3.32 0 6 .38 6 3v6.88z"/></svg>            </div>
            <div className="rwe-card--office__number subway-text">
              <div>{stationDistance}</div>
            </div>
          </div>
        ) : null}
        {pricePerDesk ? (
          <div className="rwe-card__icon-and-text-container price-container">
            <div className="rwe-card--office__icon price-basic-text">
              Private offices
            </div>
            <div className="rwe-card--office__number price-text">
              <div>{pricePerDesk}</div>
            </div>
          </div>
        ) : null}
        {optionalText ? (
          <div className="rwe-card__icon-and-text-container card-optional-text">
            <div className="rwe-card--office__number rwe-optional-text">
              <div>{optionalText}</div>
            </div>
          </div>
        ) : null}
      </div>
    );
  },
);
