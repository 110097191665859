import React from 'react';
import {RunwayEastLink} from '../link/RunwayEastLink';

export interface BreadcrumbProps {
  children: string;
  href?: string;
}

export const Breadcrumb = React.memo(({children, href}: BreadcrumbProps) => (
  <div className="rwe-breadcrumb">
    <RunwayEastLink href={href}>
      <span className="rwe-p2">{children}</span>
    </RunwayEastLink>
  </div>
));
