import React, {useMemo} from 'react';
import {
  CollapsibleSectionList,
  CollapsibleSectionListProps,
} from '../collapsible-section/CollapsibleSectionList';
import {useFloatingCta} from '../floating-cta/FloatingCtaContext';

import {
  ContentWithCopySection,
  ContentWithCopySectionProps,
} from './ContentWithCopySection';

export interface CollapsibleSectionListWithCopySectionProps
  extends CollapsibleSectionListProps,
    Omit<ContentWithCopySectionProps, 'children' | 'copyPosition'> {}

export const CollapsibleSectionListWithCopySection = React.memo(
  ({
    backgroundColor,
    copy,
    layoutReEvalDeps,
    cta,
    withLine,
    blockId,
    blockName,
    LaptopVisible,
    MobileVisible,
    ...rest
  }: CollapsibleSectionListWithCopySectionProps) => {
    const {avoidanceStyle} = useFloatingCta();

    const memoedLayoutReEvalDeps = useMemo(
      () => [avoidanceStyle, layoutReEvalDeps],
      [avoidanceStyle, layoutReEvalDeps],
    );

    return (
      <ContentWithCopySection
        backgroundColor={backgroundColor}
        copyPosition="above"
        copy={copy}
        cta={cta}
        withLine={withLine}
        blockId={blockId}
        blockName={blockName}
        LaptopVisible={LaptopVisible}
        MobileVisible={MobileVisible}
      >
        <CollapsibleSectionList
          {...rest}
          layoutReEvalDeps={memoedLayoutReEvalDeps}
        />
      </ContentWithCopySection>
    );
  },
);
