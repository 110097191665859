import {
  ApolloClient,
  DocumentNode,
  gql,
  QueryResult,
  useQuery,
} from '@apollo/client';
import {
  DatoCustomPageDocument,
  DatoArticlePageDocument,
  DatoLocationSitePageDocument,
  DatoSolutionTypePageDocument,
  DatoArticlePageQuery,
  DatoCustomPageQuery,
  DatoLocationSitePageQuery,
  DatoSolutionTypePageQuery,
  DatoBlogHomePageQuery,
  DatoBlogHomePageDocument,
  DatoArticleCategoryPageQuery,
  DatoArticleCategoryPageDocument,
  DatoArticlesInCategoryQuery,
  DatoArticlesInCategoryQueryVariables,
  DatoArticlesInBlogHomePageQuery,
  DatoArticlesInBlogHomePageQueryVariables,
  DatoArticlesInCategoryDocument,
  DatoArticlesInBlogHomePageDocument,
  DatoArticleCategoryPageQueryVariables,
  DatoArticlePageQueryVariables,
  DatoBlogHomePageQueryVariables,
  DatoCustomPageQueryVariables,
  DatoLocationSitePageQueryVariables,
  DatoSolutionTypePageQueryVariables,
  DatoArticleCategoriesQuery,
  DatoArticleCategoriesQueryVariables,
  DatoArticleCategoriesDocument,
  DatoArticleCountInCategoryQuery,
  DatoArticleCountInCategoryQueryVariables,
  DatoArticleCountInCategoryDocument,
  DatoArticleCountInBlogHomePageDocument,
  DatoArticleCountInBlogHomePageQuery,
  DatoArticleCountInBlogHomePageQueryVariables,
  NavbarDocument,
  NavbarQuery,
  NavbarQueryVariables,
  DatoWorkspacePageDocument,
  DatoWorkspacePageQuery,
  DatoWorkspacePageQueryVariables,
} from 'graphql/generated/graphql';
import {useRouter} from 'next/router';
import {useQuerySubscription} from 'react-datocms';
import {ArticleCategoryPageQuery} from 'ui/components/runway-east/page/DatoArticleCategoryPage';
import {WorkspacePageQuery} from 'ui/components/runway-east/page/DatoWorkspacePage';
import {BlogHomePageQuery} from '../ui/components/runway-east/page/DatoBlogHomePage';
import {CustomPageQuery} from '../ui/components/runway-east/page/DatoCustomPage';
import {LocationSitePageQuery} from '../ui/components/runway-east/page/DatoLocationSitePage';
import {SolutionTypePageQuery} from '../ui/components/runway-east/page/DatoSolutionTypePage';
import {ArticlePageQuery} from '../ui/components/runway-east/page/DatoArticlePage';

export type DatoPageQuery = DatoArticlePageQuery &
  DatoCustomPageQuery &
  DatoLocationSitePageQuery &
  DatoBlogHomePageQuery &
  DatoArticleCategoryPageQuery &
  DatoWorkspacePageQuery &
  DatoSolutionTypePageQuery;

export type DatoPageQueryResultDataValue =
  | DatoArticlePageQuery['article']
  | DatoCustomPageQuery['customPage']
  | DatoLocationSitePageQuery['locationSite']
  | DatoSolutionTypePageQuery['solutionType']
  | DatoWorkspacePageQuery['workspace']
  | DatoArticleCategoryPageQuery['articleCategory']
  | DatoBlogHomePageQuery['blogHomepage'];

export type PageQuery =
  | ArticlePageQuery
  | CustomPageQuery
  | LocationSitePageQuery
  | SolutionTypePageQuery
  | ArticleCategoryPageQuery
  | WorkspacePageQuery
  | BlogHomePageQuery;

export interface PageQueryOptions {
  path: string;
}

export const fetchPageQuery = async (
  apolloClient: ApolloClient<any>,
  {path}: PageQueryOptions,
): Promise<PageQuery | null> => {
  // Pre-fetch data required for the navbar and footer
  await apolloClient.query<NavbarQuery, NavbarQueryVariables>({
    query: NavbarDocument,
  });

  const variables = {
    path,
  };

  // Pre-fetch data required for the pages
  const [
    articlePageQueryResult,
    customPageQueryResult,
    locationSitePageQueryResult,
    solutionTypePageQueryResult,
    workspacePageQueryResult,
    blogHomePageQueryResult,
    articleCategoryPageQueryResult,
  ] = await Promise.all([
    apolloClient.query<DatoArticlePageQuery, DatoArticlePageQueryVariables>({
      query: DatoArticlePageDocument,
      variables,
    }),
    apolloClient.query<DatoCustomPageQuery, DatoCustomPageQueryVariables>({
      query: DatoCustomPageDocument,
      variables,
    }),
    apolloClient.query<
      DatoLocationSitePageQuery,
      DatoLocationSitePageQueryVariables
    >({
      query: DatoLocationSitePageDocument,
      variables,
    }),
    apolloClient.query<
      DatoSolutionTypePageQuery,
      DatoSolutionTypePageQueryVariables
    >({
      query: DatoSolutionTypePageDocument,
      variables,
    }),
    apolloClient.query<DatoWorkspacePageQuery, DatoWorkspacePageQueryVariables>(
      {
        query: DatoWorkspacePageDocument,
        variables,
      },
    ),
    apolloClient.query<DatoBlogHomePageQuery, DatoBlogHomePageQueryVariables>({
      query: DatoBlogHomePageDocument,
    }),
    apolloClient.query<
      DatoArticleCategoryPageQuery,
      DatoArticleCategoryPageQueryVariables
    >({
      query: DatoArticleCategoryPageDocument,
      variables,
    }),
  ]);

  if (articlePageQueryResult.data.article) {
    if (!DatoArticlePageDocument.loc) {
      throw new Error(
        'DatoArticlePageDocument does not contain a GraphQL query.',
      );
    }
    return {
      type: 'article',
      data: articlePageQueryResult.data.article,
      queryVariables: variables,
      query: DatoArticlePageDocument.loc.source.body,
    };
  }
  if (customPageQueryResult.data.customPage) {
    if (!DatoCustomPageDocument.loc) {
      throw new Error(
        'DatoCustomPageDocument does not contain a GraphQL query.',
      );
    }
    return {
      type: 'custom',
      data: customPageQueryResult.data.customPage,
      queryVariables: variables,
      query: DatoCustomPageDocument.loc.source.body,
    };
  }
  if (locationSitePageQueryResult.data.locationSite) {
    if (!DatoLocationSitePageDocument.loc) {
      throw new Error(
        'DatoLocationSitePageDocument does not contain a GraphQL query.',
      );
    }
    return {
      type: 'locationSite',
      data: locationSitePageQueryResult.data.locationSite,
      queryVariables: variables,
      query: DatoLocationSitePageDocument.loc.source.body,
    };
  }
  if (solutionTypePageQueryResult.data.solutionType) {
    if (!DatoSolutionTypePageDocument.loc) {
      throw new Error(
        'DatoSolutionTypePageDocument does not contain a GraphQL query.',
      );
    }
    return {
      type: 'solutionType',
      data: solutionTypePageQueryResult.data.solutionType,
      queryVariables: variables,
      query: DatoSolutionTypePageDocument.loc.source.body,
    };
  }
  if (workspacePageQueryResult.data.workspace) {
    if (!DatoWorkspacePageDocument.loc) {
      throw new Error(
        'DatoWorkspacePageDocument does not contain a GraphQL query.',
      );
    }
    return {
      type: 'workspace',
      data: workspacePageQueryResult.data.workspace,
      queryVariables: variables,
      query: DatoWorkspacePageDocument.loc.source.body,
    };
  }
  if (articleCategoryPageQueryResult.data.articleCategory) {
    // Pre-fetch articles in the article category
    await apolloClient.query<
      DatoArticlesInCategoryQuery,
      DatoArticlesInCategoryQueryVariables
    >({
      query: DatoArticlesInCategoryDocument,
      variables: {
        categoryId: articleCategoryPageQueryResult.data.articleCategory.id,
        excludingIds: [],
      },
    });

    // Pre-fetch article count in the article category
    await apolloClient.query<
      DatoArticleCountInCategoryQuery,
      DatoArticleCountInCategoryQueryVariables
    >({
      query: DatoArticleCountInCategoryDocument,
      variables: {
        categoryId: articleCategoryPageQueryResult.data.articleCategory.id,
      },
    });

    // Pre-fetch all categories
    await apolloClient.query<
      DatoArticleCategoriesQuery,
      DatoArticleCategoriesQueryVariables
    >({
      query: DatoArticleCategoriesDocument,
    });

    if (!DatoArticleCategoryPageDocument.loc) {
      throw new Error(
        'DatoArticleCategoryPageDocument does not contain a GraphQL query.',
      );
    }

    return {
      type: 'articleCategory',
      data: articleCategoryPageQueryResult.data.articleCategory,
      queryVariables: variables,
      query: DatoArticleCategoryPageDocument.loc.source.body,
    };
  }
  if (
    blogHomePageQueryResult.data.blogHomepage &&
    // The GraphQL query for this content does not have a filter on the pagePath
    blogHomePageQueryResult.data.blogHomepage.pagePath === variables?.path
  ) {
    // Pre-fetch articles for the blog home page
    await apolloClient.query<
      DatoArticlesInBlogHomePageQuery,
      DatoArticlesInBlogHomePageQueryVariables
    >({
      query: DatoArticlesInBlogHomePageDocument,
      variables: {
        excludingIds: [],
      },
    });

    // Pre-fetch article count for the blog home page
    await apolloClient.query<
      DatoArticleCountInBlogHomePageQuery,
      DatoArticleCountInBlogHomePageQueryVariables
    >({
      query: DatoArticleCountInBlogHomePageDocument,
    });

    // Pre-fetch all categories
    await apolloClient.query<
      DatoArticleCategoriesQuery,
      DatoArticleCategoriesQueryVariables
    >({
      query: DatoArticleCategoriesDocument,
    });

    if (!DatoBlogHomePageDocument.loc) {
      throw new Error(
        'DatoBlogHomePageDocument does not contain a GraphQL query.',
      );
    }

    return {
      type: 'blogHomepage',
      data: blogHomePageQueryResult.data.blogHomepage,
      queryVariables: {},
      query: DatoBlogHomePageDocument.loc.source.body,
    };
  }

  return null;
};

const splitPathIntoSegments = (pathString: string) => {
  const [, ...pathSegments] = pathString ? pathString.split('/') : [];

  return pathSegments;
};

type PageableDatoModel =
  | {urlPath?: string | null; pagePath?: undefined}
  | {pagePath?: string | null; urlPath?: undefined};

const getPathsFromQueryResult = (queryResult: PageableDatoModel[]) =>
  queryResult
    .filter(({urlPath, pagePath}) => !!urlPath || !!pagePath)
    .map(({urlPath, pagePath}) =>
      urlPath
        ? splitPathIntoSegments(urlPath)
        : splitPathIntoSegments(pagePath as string),
    );

const getPathsFromQueryResultList = (queryResultList: PageableDatoModel[][]) =>
  queryResultList.map(getPathsFromQueryResult).flat();

export const getPathsFromQuery = <
  PageableModelQueryResult extends Record<
    string,
    PageableDatoModel[] | PageableDatoModel | unknown
  >
>(
  queryResult: PageableModelQueryResult,
): string[][] => {
  const queryResultValues = Object.values(queryResult)
    .filter((queryResultValue) => typeof queryResultValue === 'object')
    .map(
      (queryResultValue) =>
        (Array.isArray(queryResultValue)
          ? queryResultValue
          : [queryResultValue]) as PageableDatoModel[],
    );

  return getPathsFromQueryResultList(queryResultValues);
};

export const useDatoPreviewQuerySubscription = <
  QueryData,
  QueryVariables = undefined
>(
  query: string | DocumentNode,
  variables?: QueryVariables,
): QueryResult<QueryData> &
  Pick<ReturnType<typeof useQuerySubscription>, 'status'> & {
    subscriptionError: Pick<
      ReturnType<typeof useQuerySubscription>,
      'error'
    >['error'];
  } => {
  const {isPreview} = useRouter();

  const {data: initialData, ...restQuery} = useQuery(
    typeof query === 'string' ? gql(query) : query,
    {
      variables,
      fetchPolicy: 'cache-only',
    },
  );

  if (typeof query !== 'string' && !query.loc) {
    throw new Error(
      `The GraphQL DocumentNode does not contain a query: ${JSON.stringify(
        query,
        undefined,
        2,
      )}`,
    );
  }

  const {data, status, error} = useQuerySubscription({
    query: typeof query === 'string' ? query : query.loc?.source.body || '',
    variables,
    token: process.env.DATOCMS_API_TOKEN as string,
    preview: true,
    enabled: isPreview,
    initialData,
  });

  return {
    ...restQuery,
    status,
    subscriptionError: error,
    data,
  };
};
