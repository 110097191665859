import React from 'react';
import {
  BreadcrumbList,
  BreadcrumbListProps,
} from '../breadcrumb/BreadcrumbList';

export type BreadcrumbsSectionProps = BreadcrumbListProps;

export const BreadcrumbsSection = React.memo(
  (props: BreadcrumbsSectionProps) => (
    <div className="rwe-breadcrumb-list-section">
      <div className="rwe-content-container">
        <BreadcrumbList {...props} />
      </div>
    </div>
  ),
);
