import {HeaderTextAndImageFragment} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {transformDatoResponsiveImageFragment} from '../image/ImageRenderer';
import {DatoButtonLink} from '../link/DatoLink';
import {DatoRunwayEastStructuredText} from '../text/DatoRunwayEastStructuredText';
import {colors} from '../theme';
import {
  ContentWithImageOnSide,
  ContentWithImageOnSideProps,
} from './ContentWithImageOnSide';

type DatoContentFragmentProps = Pick<
  HeaderTextAndImageFragment,
  'heading' | 'link'
>;

const DatoContentFragment = React.memo(
  ({heading, link}: DatoContentFragmentProps) => (
    <>
      <div>
        {heading ? (
          <DatoRunwayEastStructuredText
            data={heading}
            runwayEastStyle={{
              h4: {
                classNames: ['rwe-h4-alt'],
                style: {color: colors.slate.darkSlate},
              },
              p: {
                classNames: ['rwe-p1'],
                style: {color: colors.slate.darkSlate},
              },
            }}
          />
        ) : null}
      </div>
      {/* eslint-disable-next-line no-underscore-dangle */}
      {link && link.__typename === 'LinkRecord' && link.name ? (
        <div style={{marginTop: 24}}>
          <DatoButtonLink data={link} />
        </div>
      ) : null}
    </>
  ),
);

export interface DatoHeaderTextAndImageSectionProps
  extends HeaderTextAndImageFragment {
  index: number;
}

const transformDatoHeaderTextAndImageFragment = ({
  link,
  image,
  imageSide,
  heading,
  index,
}: DatoHeaderTextAndImageSectionProps): ContentWithImageOnSideProps => ({
  imagePosition: imageSide && imageSide === 'left' ? 'on-left' : 'on-right',
  image: image ? transformDatoResponsiveImageFragment(image, index > 0) : '',
  children: <DatoContentFragment heading={heading} link={link} />,
});

export const DatoHeaderTextAndImageSection = React.memo(
  (props: DatoHeaderTextAndImageSectionProps) => {
    const contentWithImageOnSideProps = useMemo(
      (): ContentWithImageOnSideProps =>
        transformDatoHeaderTextAndImageFragment(props),
      [props],
    );

    return (
      <div className="rwe-header-text-and-image-section">
        <ContentWithImageOnSide
          {...contentWithImageOnSideProps}
          withLine={false}
          lazyLoadImage={false}
          shouldPreload
        />
      </div>
    );
  },
);
