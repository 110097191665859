import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export interface CollapsibleSectionProps {
  header: React.ReactElement;
  children: React.ReactElement;
  startCollapsed?: boolean;
  layoutReEvalDeps?: any[];
}

const useHeight = () => {
  const ref = useRef<HTMLElement>(null);
  const [height, setSize] = React.useState<number>(1);

  useLayoutEffect(() => {
    setSize(ref.current?.offsetHeight ?? 1);
  }, [ref]);

  useResizeObserver(ref, () => setSize(ref.current?.offsetHeight ?? 1));
  return [ref, height] as const;
};

export const CollapsibleSection = React.memo(
  ({header, children, startCollapsed = true}: CollapsibleSectionProps) => {
    const [headerRef, headerHeight] = useHeight();
    const [contentRef, contentHeight] = useHeight();
    const [isCollapsed, setIsCollapsed] = useState(startCollapsed);

    const containerClassNames = useMemo((): string => {
      const classNames = ['rwe-collapsible-section'];
      if (isCollapsed) {
        classNames.push('rwe-collapsible-section--collapsed');
      }
      return classNames.join(' ');
    }, [isCollapsed]);

    const handleToggleClick = useCallback(() => {
      setIsCollapsed((value) => !value);
    }, []);

    return (
      <div className={containerClassNames}>
        <div
          className="rwe-collapsible-section__content-container"
          style={{
            height: isCollapsed ? headerHeight : headerHeight + contentHeight,
          }}
        >
          <div className="rwe-collapsible-section__header-and-body">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              ref={headerRef as any}
              className="rwe-collapsible-section__header"
              onClick={handleToggleClick}
            >
              <div className="rwe-collapsible-section__header-title">
                {header}
              </div>
            </div>
            <div
              ref={contentRef as any}
              className="rwe-collapsible-section__body"
            >
              {children}
            </div>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className="rwe-collapsible-section__icon-container"
            style={{
              height: headerHeight,
            }}
            onClick={handleToggleClick}
          >
            <i className="rwe-collapsible-section__icon" />
          </div>
        </div>
      </div>
    );
  },
);
