import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useNavbarContext} from '../navbar/NavbarContextProvider';
import {
  FloatingCtaContent,
  FloatingCtaContentProps,
} from './FloatingCtaContent';
import {useFloatingCta} from './FloatingCtaContext';

const floatingCtaTopAndLeftOffset = 24;

export type FloatingCtaProps = FloatingCtaContentProps;

export const FloatingCta = React.memo((props: FloatingCtaProps) => {
  const floatingCtaContainerElementRef = useRef<HTMLDivElement | null>(null);
  const [floatingCtaPositionValue, setFloatingCtaPositionValue] = useState<
    'static' | 'fixed' | 'absolute'
  >('static');
  const [floatingCtaElementWidth, setFloatingCtaElementWidth] = useState<
    number | undefined
  >(undefined);

  const {setIsFloating, setFloatingContentWidth} = useFloatingCta();

  const {navbarContainerElementRef, isNavbarHiding} = useNavbarContext();

  const floatingCtaElementFragment = useMemo(() => {
    const fixedStyle: React.CSSProperties = {
      position: 'fixed',
      top: isNavbarHiding
        ? floatingCtaTopAndLeftOffset + 63
        : (navbarContainerElementRef &&
            navbarContainerElementRef.current &&
            navbarContainerElementRef.current.clientHeight +
              floatingCtaTopAndLeftOffset + 63) ||
          0,
      width: floatingCtaElementWidth,
    };
    return (
      <div style={{flex: 1}}>
        <div
          ref={floatingCtaContainerElementRef}
          className="rwe-floating-cta-container"
          style={floatingCtaPositionValue === 'fixed' ? fixedStyle : undefined}
        >
          <div className="rwe-floating-cta">
            <FloatingCtaContent {...props} />
          </div>
        </div>
      </div>
    );
  }, [
    floatingCtaPositionValue,
    navbarContainerElementRef,
    isNavbarHiding,
    floatingCtaElementWidth,
    props,
  ]);

  const measureAndSetFloatingCtaLayoutProperties = useCallback(() => {
    if (!floatingCtaContainerElementRef.current) return;
    if (!navbarContainerElementRef || !navbarContainerElementRef.current)
      return;

    const floatingCtaElement = floatingCtaContainerElementRef.current;
    const floatingCtaParentElement = floatingCtaElement.parentElement as HTMLElement;
    const navbarContainerElement = navbarContainerElementRef.current;
    const {
      height: navbarContainerHeight,
    } = navbarContainerElement.getBoundingClientRect();
    const {
      top: floatingCtaParentYPosition,
      width: floatingCtaParentWidth,
    } = floatingCtaParentElement.getBoundingClientRect();

    const isFloatingCtaIntersectingWithNavbar =
      floatingCtaParentYPosition <=
      (isNavbarHiding
        ? floatingCtaTopAndLeftOffset
        : navbarContainerHeight + floatingCtaTopAndLeftOffset);

    setFloatingCtaElementWidth(
      floatingCtaParentWidth - floatingCtaTopAndLeftOffset,
    );

    if (
      isFloatingCtaIntersectingWithNavbar &&
      floatingCtaPositionValue !== 'fixed'
    ) {
      setFloatingCtaPositionValue('fixed');
    } else if (
      !isFloatingCtaIntersectingWithNavbar &&
      floatingCtaPositionValue !== 'static'
    ) {
      setFloatingCtaPositionValue('static');
    }
  }, [floatingCtaPositionValue, isNavbarHiding, navbarContainerElementRef]);

  useEffect(() => {
    setIsFloating(floatingCtaPositionValue === 'fixed');

    return () => setIsFloating(false);
  }, [floatingCtaPositionValue, setIsFloating]);

  useEffect(() => {
    setFloatingContentWidth(
      floatingCtaElementWidth
        ? floatingCtaElementWidth + floatingCtaTopAndLeftOffset
        : undefined,
    );

    return () => setFloatingContentWidth(undefined);
  }, [floatingCtaElementWidth, setFloatingContentWidth]);

  useLayoutEffect(() => {
    measureAndSetFloatingCtaLayoutProperties();

    window.addEventListener('scroll', measureAndSetFloatingCtaLayoutProperties);
    window.addEventListener('resize', measureAndSetFloatingCtaLayoutProperties);

    return () => {
      window.removeEventListener(
        'scroll',
        measureAndSetFloatingCtaLayoutProperties,
      );
      window.removeEventListener(
        'resize',
        measureAndSetFloatingCtaLayoutProperties,
      );
    };
  }, [measureAndSetFloatingCtaLayoutProperties]);

  return floatingCtaElementFragment;
});
