import React, {useMemo} from 'react';
import {
  CollapsibleSection,
  CollapsibleSectionProps,
} from './CollapsibleSection';

export interface CollapsibleSectionListProps {
  sections: CollapsibleSectionProps[];
  layoutReEvalDeps?: any[];
}

export const CollapsibleSectionList = React.memo(
  ({sections, layoutReEvalDeps}: CollapsibleSectionListProps) => {
    const renderedCollapsibleSections = useMemo(() => {
      return sections.map((section) => (
        <CollapsibleSection {...section} layoutReEvalDeps={layoutReEvalDeps} />
      ));
    }, [sections, layoutReEvalDeps]);

    return (
      <div className="rwe-collapsible-section-list">
        {renderedCollapsibleSections}
      </div>
    );
  },
);
