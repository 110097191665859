import React from 'react';
import {ImageRendererConfig, OptimizedImage} from '../image/ImageRenderer';
import {RunwayEastLink} from '../link/RunwayEastLink';

export interface ArticleCardProps {
  image?: ImageRendererConfig;
  heading?: string;
  byline?: string;
  copy?: string;
  href: string;
  category?: string;
  categoryColor?: string;
}

export const ArticleCard = React.memo(
  ({
    image,
    heading,
    byline,
    copy,
    href,
    category,
    categoryColor,
  }: ArticleCardProps) => (
    <RunwayEastLink href={href}>
      <div className="rwe-card rwe-card--article">
        {category ? (
          <div
            className="rwe-card--article__category-label"
            style={categoryColor ? {backgroundColor: categoryColor} : undefined}
          >
            <span className="rwe-p1">{category}</span>
          </div>
        ) : null}
        <div className="rwe-card__image">
          {image ? <OptimizedImage data={image} /> : null}
        </div>
        <div className="rwe-card__text-content">
          {heading ? (
            <div className="rwe-card--article__heading">
              <h4 className="rwe-h4-alt">{heading}</h4>
            </div>
          ) : null}
          {byline ? (
            <div className="rwe-card--article__byline">
              <p className="rwe-p2">{byline}</p>
            </div>
          ) : null}
          {copy ? (
            <div className="rwe-card--article__copy">
              <p className="rwe-p2">{copy}</p>
            </div>
          ) : null}
        </div>
      </div>
    </RunwayEastLink>
  ),
);
