import React, {useCallback} from 'react';
import {CarouselWindow} from '../CarouselWindow';
import {CarouselContextProvider} from '../CarouselContext';
import {GalleryCarouselThumbnails} from './GalleryCarouselThumbnails';
import {GalleryActiveNumberIndicator} from './GalleryActiveNumberIndicator';
import {
  GalleryCarouselLeftButton,
  GalleryCarouselRightButton,
} from './GalleryCarouselButton';
import {GalleryCarouselVideo} from './GalleryCarouselVideo';
import {ImageRendererConfig, OptimizedImage} from '../../image/ImageRenderer';

interface GalleryCarouselImageItem {
  type: 'image';
  data: ImageRendererConfig;
}

interface GalleryCarouselVimeoVideoItem {
  type: 'video';
  data: string;
}

export type GalleryCarouselItem =
  | GalleryCarouselImageItem
  | GalleryCarouselVimeoVideoItem;

export interface GalleryCarouselProps {
  data: GalleryCarouselItem[];
}

export const GalleryCarousel = React.memo(({data}: GalleryCarouselProps) => {
  const renderItem = useCallback(
    (
      item: GalleryCarouselItem,
      index: number,
      visibleIndex: number | undefined,
    ) => {
      switch (item.type) {
        case 'image':
          return (
            <OptimizedImage
              shouldPreload={index === 0}
              lazyLoad={index !== 0}
              data={
                index === 0 && typeof item.data === 'object'
                  ? {...item.data, placeholderImageBlob: undefined}
                  : item.data
              }
            />
          );
        case 'video':
          return (
            <GalleryCarouselVideo
              src={item.data}
              isItemActive={visibleIndex === 0}
            />
          );
        default:
          throw new Error(
            `Unrecognised GalleryCarousel item config: ${JSON.stringify(item)}`,
          );
      }
    },
    [],
  );

  return (
    <div className="rwe-gallery-carousel">
      <CarouselContextProvider data={data}>
        <CarouselWindow
          renderItem={renderItem}
          windowContainerChildren={
            <>
              <GalleryCarouselLeftButton />
              <GalleryCarouselRightButton />
            </>
          }
        >
          <GalleryActiveNumberIndicator />
        </CarouselWindow>
        <GalleryCarouselThumbnails />
      </CarouselContextProvider>
    </div>
  );
});
