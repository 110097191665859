import React, {useMemo} from 'react';
import {DatoPageQueryResultDataValue} from '../../../../hooks/use-page-query';
import {BreadcrumbProps} from '../breadcrumb/Breadcrumb';
import {BreadcrumbsSection} from './BreadcrumbsSection';

export interface DatoBreadcrumbsSectionProps {
  data: DatoPageQueryResultDataValue;
}

const getBreadcrumbFromDatoRecord = (record: any): BreadcrumbProps => {
  // eslint-disable-next-line no-underscore-dangle
  switch (record.__typename) {
    case 'CustomPageRecord':
      return {
        href: record.urlPath || '',
        children: record.pageName || '',
      };
    case 'LocationSiteRecord':
      return {
        href: record.urlPath || '',
        children: record.siteName || '',
      };
    case 'SolutionTypeRecord':
      return {
        href: record.urlPath || '',
        children: record.solutionType || '',
      };
    case 'ArticleCategoryRecord':
      return {
        href: record.pagePath || '',
        children: record.categoryName || '',
      };
    case 'ArticleRecord':
      return {
        href: record.pagePath || '',
        children: record.title || '',
      };
    case 'BlogHomepageRecord':
      return {
        href: record.pagePath || '',
        children: record.pageTitle || '',
      };
    case 'WorkspaceRecord':
      return {
        href: record.urlPath || '',
        children: record.name || '',
      };
    default:
      // never
      return {href: '', children: ''};
  }
};

export const transformDatoBreadcrumbsFragment = (
  data: DatoPageQueryResultDataValue,
): BreadcrumbProps[] =>
  data && data.breadcrumb
    ? [
        {
          href: '/',
          children: 'Home',
        },
        ...data.breadcrumb.map(getBreadcrumbFromDatoRecord),
        getBreadcrumbFromDatoRecord(data),
      ]
    : [];

export const DatoBreadcrumbsSection = React.memo(
  (props: DatoBreadcrumbsSectionProps) => {
    const breadcrumbsSectionProps = useMemo(
      () => transformDatoBreadcrumbsFragment(props.data),
      [props],
    );

    return <BreadcrumbsSection data={breadcrumbsSectionProps} />;
  },
);
