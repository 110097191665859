import {
  DatoSolutionTypePageQuery,
  DatoSolutionTypePageQueryVariables,
} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {DatoReusableComponents} from '../DatoReusableComponents';
import {DatoButtonLink} from '../link/DatoLink';
import {DatoRunwayEastStructuredText} from '../text/DatoRunwayEastStructuredText';
import {SolutionTypePage, SolutionTypePageProps} from './SolutionTypePage';
import {transformAssetsFieldValueToAssetsProp} from '../section/GalleryCarouselSection';
import {transformDatoBreadcrumbsFragment} from '../section/DatoBreadcrumbsSection';
import {JsonLd} from '../json-ld/JsonLd';

type SolutionTypeData = NonNullable<DatoSolutionTypePageQuery['solutionType']>;
export interface SolutionTypePageQuery {
  type: 'solutionType';
  data: SolutionTypeData;
  queryVariables: DatoSolutionTypePageQueryVariables;
  query: string;
}

export const transformDatoSolutionTypePageFragment = (
  solutionType: SolutionTypeData,
): SolutionTypePageProps => {
  return {
    headerText: solutionType.solutionType || undefined,
    children: solutionType.reusableComponents ? (
      <DatoReusableComponents data={solutionType.reusableComponents} />
    ) : null,
    assets: transformAssetsFieldValueToAssetsProp(solutionType.assets),
    floatingCta: solutionType.floatingCta
      ? {
          headingText: solutionType.floatingCta.heading || undefined,
          children: (
            <DatoRunwayEastStructuredText
              data={solutionType.floatingCta.body}
            />
          ),
          pricePrefixText: solutionType.floatingCta.pricePrefix || undefined,
          priceText: solutionType.floatingCta.price || undefined,
          buttons: solutionType.floatingCta.links.map((link) => (
            <DatoButtonLink data={link} />
          )),
        }
      : undefined,
    breadcrumbs: solutionType.showBreadcrumb
      ? transformDatoBreadcrumbsFragment(solutionType)
      : [],
  };
};

export interface DatoSolutionTypePageProps {
  data: SolutionTypeData;
}

export const DatoSolutionTypePage = React.memo(
  (props: DatoSolutionTypePageProps) => {
    const locationSitePageProps = useMemo((): SolutionTypePageProps => {
      return transformDatoSolutionTypePageFragment(props.data);
    }, [props]);

    return (
      <>
        {props.data.seoStructuredData ? (
          <JsonLd
            schema={{
              ...props.data.seoStructuredData,
            }}
          />
        ) : null}
        <SolutionTypePage {...locationSitePageProps} />
      </>
    );
  },
);
