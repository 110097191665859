import {HeaderTextLeftFragment} from 'graphql/generated/graphql';
import React from 'react';
import {DatoRunwayEastStructuredText} from '../text/DatoRunwayEastStructuredText';
import {colors} from '../theme';

export interface DatoHeaderTextLeftSectionProps extends HeaderTextLeftFragment {
  index: number;
}

export const DatoHeaderTextLeftSection: React.FC<DatoHeaderTextLeftSectionProps> = React.memo(
  (props) => {
    return (
      <div className="rwe-header-text-left-section">
        <div className="rwe-content-container">
          <DatoRunwayEastStructuredText
            data={props.heading}
            runwayEastStyle={{
              h1: {
                classNames: ['rwe-h1'],
                style: {
                  marginTop: 24,
                  color: colors.primary.charcoalBlack,
                },
              },
            }}
          />
        </div>
      </div>
    );
  },
);
