import {ContactEmbedFragment} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {
  DatoRunwayEastStructuredText,
  DatoStructuredTextConfig,
} from '../text/DatoRunwayEastStructuredText';
import {colors} from '../theme';
import {
  EmbedWithContactDetailsSection,
  EmbedWithContactDetailsSectionProps,
} from './EmbedWithContactDetailsSection';

const RUNWAY_EAST_STYLE: DatoStructuredTextConfig = {
  h1: {
    classNames: ['rwe-h1'],
    style: {
      color: colors.primary.white,
    },
  },
  h2: {
    classNames: ['rwe-h2'],
    style: {
      color: colors.primary.white,
    },
  },
  h3: {
    classNames: ['rwe-h3'],
    style: {
      color: colors.primary.white,
    },
  },
  h4: {
    classNames: ['rwe-h4-alt'],
    style: {
      color: colors.primary.white,
    },
  },
  h5: {
    classNames: ['rwe-h4-alt'],
    style: {
      color: colors.primary.white,
    },
  },
  h6: {
    classNames: ['rwe-h4-alt'],
    style: {
      color: colors.primary.white,
    },
  },
  p: {
    classNames: ['rwe-p1'],
    style: {
      color: colors.primary.white,
    },
  },
};

export const transformDatoContactEmbedFragment = ({
  address,
  emailAddress,
  embedLink,
  heading,
  phoneNumber,
}: ContactEmbedFragment): EmbedWithContactDetailsSectionProps => {
  return {
    heading: (
      <DatoRunwayEastStructuredText
        data={heading}
        runwayEastStyle={RUNWAY_EAST_STYLE}
      />
    ),
    address: (
      <DatoRunwayEastStructuredText
        data={address}
        runwayEastStyle={RUNWAY_EAST_STYLE}
      />
    ),
    emailAddress: emailAddress || undefined,
    phoneNumber: phoneNumber || undefined,
    iframeSrc: embedLink || undefined,
  };
};

export interface DatoEmbedWithContactDetailsSectionProps
  extends ContactEmbedFragment {
  index: number;
}

export const DatoEmbedWithContactDetailsSection: React.FC<DatoEmbedWithContactDetailsSectionProps> = React.memo(
  (props) => {
    const cardGridSectionProps = useMemo((): EmbedWithContactDetailsSectionProps => {
      return transformDatoContactEmbedFragment(props);
    }, [props]);

    return <EmbedWithContactDetailsSection {...cardGridSectionProps} />;
  },
);
