import {
  DatoLocationSitePageQuery,
  DatoLocationSitePageQueryVariables,
} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {useRouter} from 'next/router';
import {HOST} from 'hooks/use-environment';
import {DatoReusableComponents} from '../DatoReusableComponents';
import {DatoButtonLink} from '../link/DatoLink';
import {DatoRunwayEastStructuredText} from '../text/DatoRunwayEastStructuredText';
import {LocationSitePage, LocationSitePageProps} from './LocationSitePage';
import {transformAssetsFieldValueToAssetsProp} from '../section/GalleryCarouselSection';
import {transformDatoBreadcrumbsFragment} from '../section/DatoBreadcrumbsSection';
import {JsonLd} from '../json-ld/JsonLd';

type LocationSiteData = NonNullable<DatoLocationSitePageQuery['locationSite']>;

export type LocationSitePageQuery = {
  type: 'locationSite';
  data: LocationSiteData;
  queryVariables: DatoLocationSitePageQueryVariables;
  query: string;
};

export const transformDatoLocationSitePageFragment = (
  locationSite: LocationSiteData,
): LocationSitePageProps => {
  return {
    address: locationSite.address || undefined,
    headerText: locationSite.siteName || undefined,
    maskedImageSrc: locationSite.textBackgroundImage
      ? locationSite.textBackgroundImage.responsiveImage?.src
      : undefined,
    phoneNumber: locationSite.phoneNumber || undefined,
    addressLocation: locationSite.addressLocation || undefined,
    emailAddress: locationSite.emailAddress || undefined,
    children: locationSite.reusableComponents ? (
      <DatoReusableComponents data={locationSite.reusableComponents} />
    ) : null,
    assets: transformAssetsFieldValueToAssetsProp(locationSite.assets),
    floatingCta: locationSite.floatingCta
      ? {
          headingText: locationSite.floatingCta.heading || undefined,
          children: (
            <DatoRunwayEastStructuredText
              data={locationSite.floatingCta.body}
            />
          ),
          pricePrefixText: locationSite.floatingCta.pricePrefix || undefined,
          priceText: locationSite.floatingCta.price || undefined,
          buttons: locationSite.floatingCta.links.map((link) => (
            <DatoButtonLink data={link} />
          )),
        }
      : undefined,
    breadcrumbs: locationSite.showBreadcrumb
      ? transformDatoBreadcrumbsFragment(locationSite)
      : [],
  };
};

export interface DatoLocationSitePageProps {
  data: LocationSiteData;
}

export const DatoLocationSitePage = React.memo(
  (props: DatoLocationSitePageProps) => {
    const router = useRouter();
    const locationSitePageProps = useMemo((): LocationSitePageProps => {
      return transformDatoLocationSitePageFragment(props.data);
    }, [props]);

    return (
      <>
        <JsonLd
          schema={{
            '@type': 'Place',
            name: props.data.siteName
              ? `Runway East ${props.data.siteName}`
              : undefined,
            url: `${HOST}${router.asPath}`,
            address: props.data.address || undefined,
            telephone: props.data.phoneNumber || undefined,
            locationAdr: props.data.addressLocation || undefined,
            emailAdr: props.data.emailAddress || undefined,
            ...props.data.seoStructuredData,
          }}
        />
        <LocationSitePage {...locationSitePageProps} />
      </>
    );
  },
);
