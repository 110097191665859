import React, {useEffect} from 'react';
import {BreadcrumbProps} from '../breadcrumb/Breadcrumb';
import {GalleryCarouselItem} from '../carousel/gallery/GalleryCarousel';
import {FloatingCta, FloatingCtaProps} from '../floating-cta/FloatingCta';
import {useNavbarContext} from '../navbar/NavbarContextProvider';
import {BreadcrumbsSection} from '../section/BreadcrumbsSection';
import {GalleryCarouselSection} from '../section/GalleryCarouselSection';
import {colors} from '../theme';

export interface WorkspacePageProps {
  headerText?: string;
  siteName?: string;
  address?: string;
  phoneNumber?: string;
  assets: GalleryCarouselItem[];
  children?: React.ReactNode;
  floatingCta?: FloatingCtaProps;
  breadcrumbs: BreadcrumbProps[];
}

export const WorkspacePage = React.memo(
  ({
    address,
    siteName,
    children,
    headerText,
    phoneNumber,
    assets,
    floatingCta,
    breadcrumbs,
  }: WorkspacePageProps) => {
    const {setBackgroundColor} = useNavbarContext();

    useEffect(() => {
      setBackgroundColor(colors.primary.white);
    }, [setBackgroundColor]);

    return (
      <div className="rwe-workspace-page">
        <BreadcrumbsSection data={breadcrumbs} />
        <div className="rwe-workspace-page__header">
          <div className="rwe-content-container">
            {headerText ? (
              <div className="rwe-workspace-page__header-text">
                <h1 className="rwe-h1">{headerText}</h1>
              </div>
            ) : null}
            {siteName || address || phoneNumber ? (
              <div className="rwe-workspace-page__site-name-address-and-phone-number">
                {siteName ? (
                  <div className="rwe-workspace-page__site-name">
                    <p className="rwe-p1">{siteName}</p>
                  </div>
                ) : null}
                {siteName && address ? (
                  <div className="rwe-workspace-page__address-dot" />
                ) : null}
                {address ? (
                  <div className="rwe-workspace-page__address">
                    <p className="rwe-p1">{address}</p>
                  </div>
                ) : null}
                {address && phoneNumber ? (
                  <div className="rwe-workspace-page__address-dot" />
                ) : null}
                {phoneNumber ? (
                  <div className="rwe-workspace-page__phone-number">
                    <p className="rwe-p1">
                      <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                    </p>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        <GalleryCarouselSection
          backgroundColor={colors.primary.white}
          data={assets}
        >
          {floatingCta ? <FloatingCta {...floatingCta} /> : null}
        </GalleryCarouselSection>
        {children ? (
          <div className="rwe-workspace-page__reusable-components">
            {children}
          </div>
        ) : null}
      </div>
    );
  },
);
