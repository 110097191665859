import React, {useCallback} from 'react';
import {RunwayEastButton} from '../button/RunwayEastButton';
import {FiltersDrawerProps} from '../drawer/FiltersDrawer';
import {
  ButtonWithCheckBoxTreeDropdown,
  ButtonWithCheckBoxTreeDropdownProps,
} from './ButtonWithCheckBoxTreeDropdown';

export type SmallFindASpaceDropdownContentProps = Pick<
  FiltersDrawerProps,
  'filters'
>;

export const SmallFindASpaceDropdownContent = React.memo(
  ({filters}: SmallFindASpaceDropdownContentProps) => {
    const renderButton: ButtonWithCheckBoxTreeDropdownProps['renderButton'] = useCallback(
      ({isDropdownShowing, selected, title, toggleDropdown, valueTextMap}) => (
        <RunwayEastButton
          variant="primary-dark"
          isActive={isDropdownShowing}
          onClick={toggleDropdown}
          RightIconComponent={
            <div className="rwe-find-a-space-dropdown__arrow" />
          }
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {!selected.length
            ? title
            : selected.length === 1
            ? valueTextMap[selected[0]]
            : `${title} (${selected.length})`}
        </RunwayEastButton>
      ),
      [],
    );

    return (
      <>
        {filters.map((filter) => (
          <div className="rwe-find-a-space-dropdown__content-item rwe-find-a-space-dropdown__content-item--small">
            <ButtonWithCheckBoxTreeDropdown
              {...filter}
              renderButton={renderButton}
            />
          </div>
        ))}
      </>
    );
  },
);
