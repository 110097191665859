import Head from 'next/head';
import React, {useMemo} from 'react';
import {Thing} from 'schema-dts';

export interface JsonLdProps<T extends Thing> {
  schema: Exclude<T, string>;
}

const JsonLd = <T extends Thing>({schema}: JsonLdProps<T>) => {
  const innerHtml = useMemo(
    () => ({
      __html: JSON.stringify(
        {...schema, '@context': 'https://schema.org'},
        undefined,
        2,
      ),
    }),
    [schema],
  );
  return (
    <Head>
      {/* eslint-disable-next-line react/no-danger */}
      <script type="application/ld+json" dangerouslySetInnerHTML={innerHtml} />
    </Head>
  );
};

const MemoedJsonLd = React.memo(JsonLd) as typeof JsonLd;

export {MemoedJsonLd as JsonLd};
