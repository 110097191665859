import {TestimonialsCarouselFragment} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {transformDatoResponsiveImageFragment} from '../image/ImageRenderer';
import {DatoRunwayEastStructuredText} from '../text/DatoRunwayEastStructuredText';
import {colors} from '../theme';
import {
  TestimonialsCarouselSection,
  TestimonialsCarouselSectionProps,
} from './TestimonialsCarouselSection';

export const transformDatoTestimonialsCarouselFragment = ({
  heading,
  testimonials,
  backgroundColour,
  hideSeparatorLine,
  blockCardIdentify,
  blockMenuName,
  onLaptop,
  onMobile,
  index,
}: DatoTestimonialsCarouselSectionProps): TestimonialsCarouselSectionProps => {
  return {
    withLine: !hideSeparatorLine,
    blockId: blockCardIdentify as string,
    blockName: blockMenuName as string,
    LaptopVisible: onLaptop as string,
    MobileVisible: onMobile as string,
    copy: <DatoRunwayEastStructuredText data={heading} />,
    data: testimonials.map((testimonial) => ({
      LogoComponent: testimonial.logo ? (
        <img
          src={testimonial.logo.url}
          alt={testimonial.logo.alt || testimonial.logo.url}
          loading="lazy"
        />
      ) : null,
      eBoxBackgroundColor: testimonial.backgroundColor
        ? testimonial.backgroundColor.hex || colors.secondary.pink
        : colors.secondary.pink,
      eBoxTextColor: colors.primary.white,
      image: testimonial.image
        ? transformDatoResponsiveImageFragment(testimonial.image, index > 0)
        : '',
      TestimonialBodyComponent: testimonial.quote ? (
        <DatoRunwayEastStructuredText
          data={{...testimonial.quote, links: []}}
          runwayEastStyle={{
            p: {
              classNames: ['rwe-h4-alt'],
              style: {color: colors.primary.white},
            },
          }}
        />
      ) : null,
      testimonialCreditText: testimonial.quotee || undefined,
    })),
    backgroundColor: backgroundColour?.hex || undefined,
  };
};

export interface DatoTestimonialsCarouselSectionProps
  extends TestimonialsCarouselFragment {
  index: number;
}

export const DatoTestimonialsCarouselSection: React.FC<DatoTestimonialsCarouselSectionProps> = React.memo(
  (props) => {
    const cardGridSectionProps = useMemo((): TestimonialsCarouselSectionProps => {
      return transformDatoTestimonialsCarouselFragment(props);
    }, [props]);

    return <TestimonialsCarouselSection {...cardGridSectionProps} />;
  },
);
