import {useRouter} from 'next/router';
import React, {useCallback} from 'react';
import {FindASpaceDropdown} from './FindASpaceDropdown';
import {useWorkspaceFilters} from './useWorkspaceFilters';

export const DatoFindASpaceDropdown = React.memo(() => {
  const {
    filters,
    clearSelectedValues,
    data,
    routerQuery,
  } = useWorkspaceFilters();
  const router = useRouter();

  const handleSubmit = useCallback(() => {
    router.push({
      pathname: '/memberships/search',
      query: routerQuery,
    });
  }, [router, routerQuery]);

  return (
    <FindASpaceDropdown
      filters={filters}
      onClear={clearSelectedValues}
      numberOfResults={data ? data.allWorkspaces.length : 0}
      onSubmit={handleSubmit}
    />
  );
});
