import React, {useMemo} from 'react';
import {RunwayEastButton} from '../button/RunwayEastButton';
import {
  FlexGrid,
  ItemsPerRowPerBreakpoint,
  NumberOfFlexGridColumns,
} from '../flex-grid/FlexGrid';
import {useFloatingCta} from '../floating-cta/FloatingCtaContext';
import {
  ContentWithCopySection,
  ContentWithCopySectionProps,
} from './ContentWithCopySection';

const FLEX_GRID_ITEMS_PER_ROW_PER_BREAKPOINT_MAP: Record<
  NumberOfFlexGridColumns,
  ItemsPerRowPerBreakpoint
> = {
  1: [undefined, 1, 1, 1, 2],
  2: [undefined, 1, 2, 2, 3],
  3: [undefined, 2, 2, 3, 4],
  4: [undefined, 2, 3, 4, 5],
};

const FLEX_GRID_ITEMS_PER_ROW_PER_BREAKPOINT_WITH_FLOATING_CTA_MAP: Record<
  NumberOfFlexGridColumns,
  ItemsPerRowPerBreakpoint
> = {
  1: [undefined, 1, 1, 1, 2],
  2: [undefined, 1, 1, 2, 3],
  3: [undefined, 2, 2, 2, 3],
  4: [undefined, 2, 2, 3, 4],
};

interface HrefAndText {
  href: string;
  text: string;
  htmlId?: string;
}
export interface CardGridSectionProps
  extends Omit<
    ContentWithCopySectionProps,
    'children' | 'copyPosition' | 'copy' | 'cta'
  > {
  backgroundColor?: string;
  title: string | React.ReactNode;
  link?: HrefAndText;
  children: React.ReactNodeArray;
  numberOfColumnsAtLgBreakpoint: number;
}

export const CardGridSection = React.memo(
  ({
    title,
    link,
    children,
    numberOfColumnsAtLgBreakpoint,
    ...rest
  }: CardGridSectionProps) => {
    const {isFloating} = useFloatingCta();
    const flexGridItemsPerRowPerBreakpointMap = isFloating
      ? FLEX_GRID_ITEMS_PER_ROW_PER_BREAKPOINT_WITH_FLOATING_CTA_MAP
      : FLEX_GRID_ITEMS_PER_ROW_PER_BREAKPOINT_MAP;

    const defaultFlexGridItemsPerRowPerBreakpoint: ItemsPerRowPerBreakpoint = [
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    ];

    const flexGridItemsPerRowPerBreakpoint = numberOfColumnsAtLgBreakpoint
      ? flexGridItemsPerRowPerBreakpointMap[
          Number(numberOfColumnsAtLgBreakpoint) as NumberOfFlexGridColumns
        ] || defaultFlexGridItemsPerRowPerBreakpoint
      : defaultFlexGridItemsPerRowPerBreakpoint;
    const renderedCta = useMemo(
      () =>
        link ? (
          <RunwayEastButton variant="primary" htmlId={link.htmlId} href={link.href}>
            {link.text}
          </RunwayEastButton>
        ) : null,

      [link],
    );

    return (
      <ContentWithCopySection
        {...rest}
        copyPosition="above"
        cta={renderedCta}
        copy={title}
      >
        <div className="rwe-card-grid">
          <FlexGrid itemsPerRowPerBreakpoint={flexGridItemsPerRowPerBreakpoint}>
            {children}
          </FlexGrid>
        </div>
      </ContentWithCopySection>
    );
  },
);
