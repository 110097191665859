import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router'

export interface FloatingCtaContentProps {
  headingText?: string;
  children?: React.ReactNode;
  priceText?: string;
  pricePrefixText?: string;
  priceSuffixText?: string;
  buttons?: React.ReactNodeArray;
}

export const FloatingCtaContent = React.memo(
  ({
    headingText,
    children,
    buttons,
    pricePrefixText,
    priceSuffixText,
    priceText,
  }: FloatingCtaContentProps) => {
    const { asPath } = useRouter()
    const [list, setList] = useState<{  }[]>([]);
    useEffect(() => {
      const getElements = () => {
        let arr = []
        arr = String(priceText).split(',')
        const data: {}[] = [];
          arr.forEach(el => {
            data.push(el)
        })
        setList(data);
      }
      getElements()
    },[asPath])

    const priceClick = () => {
      const selectBox = document.getElementById("selectBox") as HTMLSelectElement;
      let selectedValue;
      if(selectBox != null) {
          selectedValue = selectBox.options[selectBox.selectedIndex].value;    
      }
      const url = new URL(window.location.href)
      url.searchParams.delete("office");
      url.searchParams.append("office", String(selectedValue))
      window.history.pushState('', '', String(url));
    }
    
    return (
      <div className="rwe-floating-cta__content">
        <div className="rwe-floating-cta__text-content">
          {headingText ? (
            <div className="rwe-floating-cta__heading">
              <div className="rwe-h4-alt">{headingText}</div>
            </div>
          ) : null}
          {children ? (
            <div className="rwe-floating-cta__body">{children}</div>
          ) : null}
          {pricePrefixText || priceText || priceSuffixText ? (
            <div className="rwe-floating-cta__price-and-prefix-and-suffix">
              {pricePrefixText ? (
                <div className="rwe-floating-cta__price-prefix">
                  <div className="rwe-caption">{pricePrefixText}</div>
                </div>
              ) : null}
              {priceText || priceSuffixText ? (
                <div className="rwe-floating-cta__price-and-suffix">
                  {priceText ? (
                    <div className="rwe-floating-cta__price">
                      <div className="rwe-h4-alt">
                        <select id='selectBox' onChange={priceClick}>
                          <option defaultValue={'Select office'}>Select office</option>
                          {list.map((element, i) => 
                            <option key={i} value={''+element}>{element}</option>
                          )}
                        </select>
                      </div>
                    </div>
                  ) : null}
                  {priceSuffixText ? (
                    <div className="rwe-floating-cta__price-suffix">
                      <div className="rwe-caption">{priceSuffixText}</div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        {buttons ? (
          <div className="rwe-floating-cta__buttons">{buttons}</div>
        ) : null}
      </div>
    );
  },
);