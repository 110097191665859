import {MapFragment} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {MapSection, MapSectionProps} from './MapSection';

export interface DatoMapSectionProps extends MapFragment {
  index: number;
  blockId?: string;
  blockName?: string;
  LaptopVisible?: string;
  MobileVisible?: string;
}

type WithDefinedLatitudeLongitude<T extends Record<string, any>> = {
  latitude: number;
  longitude: number;
} & Omit<T, 'latitude' | 'longitude'>;

const transformDatoMapFragment = ({
  mapCentreLatitude: lat,
  mapCentreLongitude: lng,
  zoomLevel: zoom,
  blockCardIdentify: blockId,
  blockMenuName: blockName,
  onLaptop: LaptopVisible,
  onMobile: MobileVisible,
  mapPins,
}: DatoMapSectionProps): MapSectionProps => ({
  blockId: blockId || undefined,
  blockName: blockName || undefined,
  LaptopVisible: LaptopVisible || undefined,
  MobileVisible: MobileVisible || undefined,
  zoom: zoom || undefined,
  center: lat && lng ? {lat, lng} : undefined,
  markers: mapPins
    // eslint-disable-next-line no-underscore-dangle
    .filter((mapPin) => mapPin._isValid && mapPin.latitude && mapPin.longitude)
    .map((mapPin) => {
      const typedMapPin = mapPin as WithDefinedLatitudeLongitude<typeof mapPin>;
      return {
        markerId: typedMapPin.id,
        popupHref: typedMapPin.pinDestination || undefined,
        position: {
          lat: typedMapPin.latitude,
          lng: typedMapPin.longitude,
        },
        headingText: mapPin.pinTitle || undefined,
        addressLines: mapPin.pinCopy ? mapPin.pinCopy.split(',') : undefined,
      };
    }),
});
export const DatoMapSection = React.memo((props: DatoMapSectionProps) => {
  const mapSectionProps = useMemo(() => transformDatoMapFragment(props), [
    props,
  ]);
  return <MapSection {...mapSectionProps} />;
});
