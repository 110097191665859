import React, {useMemo} from 'react';
import Head from 'next/head';
import {renderMetaTags} from 'react-datocms';
import {
  DatoPageQuery,
  DatoPageQueryResultDataValue,
  PageQuery,
  useDatoPreviewQuerySubscription,
} from 'hooks/use-page-query';
import {AuthStore, CheckoutStore} from 'ui/components/withAppWrapper';
import Script from 'next/script';
import {RunwayEastLayout} from '../layout/RunwayEastLayout';

import {DatoCustomPage} from './DatoCustomPage';

import {DatoLocationSitePage} from './DatoLocationSitePage';
import {DatoSolutionTypePage} from './DatoSolutionTypePage';
import {DatoArticlePage} from './DatoArticlePage';
import {DatoArticleCategoryPage} from './DatoArticleCategoryPage';

import {DatoBlogHomePage} from './DatoBlogHomePage';
import {DatoWorkspacePage} from './DatoWorkspacePage';

const extractBackgroundColorFromTopHeaderCentre = (
  customPage: NonNullable<DatoPageQuery['customPage']>,
): string | undefined => {
  const [{reusableComponents}] = customPage.pageContent;

  if (!reusableComponents) return undefined;

  const [firstReusableComponent] = reusableComponents;

  if (!firstReusableComponent) return undefined;

  // eslint-disable-next-line no-underscore-dangle
  if (firstReusableComponent.__typename !== 'HeaderCentreRecord')
    return undefined;

  return firstReusableComponent.backgroundColour?.hex || undefined;
};

const getPageTitleFromQueryResult = (
  queryResultDataValue: DatoPageQueryResultDataValue,
): string | undefined => {
  if (!queryResultDataValue) return undefined;
  // eslint-disable-next-line no-underscore-dangle
  switch (queryResultDataValue.__typename) {
    case 'CustomPageRecord':
      return queryResultDataValue.pageName || undefined;
    case 'LocationSiteRecord':
      return queryResultDataValue.siteName || undefined;
    case 'SolutionTypeRecord':
      return queryResultDataValue.solutionType || undefined;
    case 'ArticleRecord':
      return queryResultDataValue.title || undefined;
    case 'BlogHomepageRecord':
      return queryResultDataValue.pageTitle || undefined;
    case 'ArticleCategoryRecord':
      return queryResultDataValue.categoryName || undefined;
    case 'WorkspaceRecord':
      return queryResultDataValue.name || undefined;
    default:
      throw new Error(
        // eslint-disable-next-line no-underscore-dangle
        `Unspecified page title for the Dato type: ${queryResultDataValue.__typename}`,
      );
  }
};

export interface DatoPageContentProps {
  pageQuery: PageQuery;
  authStore: AuthStore;
  checkoutStore: CheckoutStore;
}

const DatoPageContent = ({pageQuery, ...rest}: DatoPageContentProps) => {
  const renderedMetaTags = useMemo(() => {
    if (!pageQuery.data) return null;
    // eslint-disable-next-line no-underscore-dangle
    return pageQuery.data._seoMetaTags
      ? // eslint-disable-next-line no-underscore-dangle
        renderMetaTags(pageQuery.data._seoMetaTags as any)
      : null;
  }, [pageQuery.data]);

  const queryResult = useDatoPreviewQuerySubscription<
    DatoPageQuery,
    PageQuery['queryVariables']
  >(pageQuery.query, pageQuery.queryVariables);

  const renderedPageComponent = useMemo(() => {
    if (!queryResult.data) return null;
    if (queryResult.data.customPage) {
      return <DatoCustomPage data={queryResult.data.customPage} />;
    }
    if (queryResult.data.locationSite) {
      return <DatoLocationSitePage data={queryResult.data.locationSite} />;
    }
    if (queryResult.data.solutionType) {
      return <DatoSolutionTypePage data={queryResult.data.solutionType} />;
    }
    if (queryResult.data.article) {
      return <DatoArticlePage data={queryResult.data.article} />;
    }
    if (queryResult.data.articleCategory) {
      return (
        <DatoArticleCategoryPage data={queryResult.data.articleCategory} />
      );
    }
    if (queryResult.data.blogHomepage) {
      return <DatoBlogHomePage data={queryResult.data.blogHomepage} />;
    }
    if (queryResult.data.workspace) {
      return <DatoWorkspacePage data={queryResult.data.workspace} />;
    }

    throw new Error(
      `Unhandled page content render for the Dato key: ${
        Object.keys(queryResult.data)[0]
      }`,
    );
  }, [queryResult.data]);

  return (
    <RunwayEastLayout
      {...rest}
      title={getPageTitleFromQueryResult(pageQuery.data) as string}
      initialBackgroundColor={
        queryResult.data?.customPage
          ? extractBackgroundColorFromTopHeaderCentre(
              queryResult.data?.customPage,
            )
          : undefined
      }
    >
      <Head>{renderedMetaTags}</Head>

      {queryResult.data?.customPage?.pageContent
        .filter((page) => page.injectScript)
        .map((page) => (
          <Script strategy="afterInteractive">
            {
              /* js */ `
              try {
                ${page.injectScript}
              } catch (e) {
                e.message = 'CMS InjectedScript error: ' + e.message;
                console.error(e);
              }
            `
            }
          </Script>
        ))}
      {renderedPageComponent}
    </RunwayEastLayout>
  );
};

const MemoedDatoPageContent = React.memo(
  DatoPageContent,
) as typeof DatoPageContent;

export {MemoedDatoPageContent as DatoPageContent};
