import React from 'react';
import {ImageRendererConfig, OptimizedImage} from '../image/ImageRenderer';

export interface MemberCardProps {
  image?: ImageRendererConfig;
  name?: string;
  memberTitle?: string;
  copy?: string;
  logoImageSrc?: string;
}

export const MemberCard = React.memo(
  ({image, name, copy, logoImageSrc, memberTitle}: MemberCardProps) => (
    <div className="rwe-card rwe-card--member">
      <div className="rwe-card--member__image">
        {image ? <OptimizedImage data={image} /> : null}
      </div>
      <div className="rwe-card--member__body-content">
        {logoImageSrc ? (
          <div className="rwe-card--member__logo">
            <img src={logoImageSrc} alt={logoImageSrc} loading="lazy" />
          </div>
        ) : null}
        {copy ? (
          <div className="rwe-card--member__copy">
            <p className="rwe-p1">{copy}</p>
          </div>
        ) : null}
        {name ? (
          <div className="rwe-card--member__name">
            <h4 className="rwe-p1">{name}</h4>
          </div>
        ) : null}
        {memberTitle ? (
          <div className="rwe-card--member__member-title">
            <h4 className="rwe-p3">{memberTitle}</h4>
          </div>
        ) : null}
      </div>
    </div>
  ),
);
