import React from 'react';
import {ImageRendererConfig, OptimizedImage} from '../image/ImageRenderer';

export interface ImageWithOverlappingContentProps {
  contentContainerStyle?: React.CSSProperties;
  image?: ImageRendererConfig;
  children: React.ReactNode;
}

export const ImageWithOverlappingContent = React.memo(
  ({
    image,
    children,
    contentContainerStyle,
  }: ImageWithOverlappingContentProps) => (
    <div className="rwe-image-with-overlapping-content">
      <div className="rwe-image-with-overlapping-content__image">
        {image ? <OptimizedImage data={image} /> : null}
      </div>
      <div
        className="rwe-image-with-overlapping-content__content"
        style={contentContainerStyle}
      >
        {children}
      </div>
    </div>
  ),
);
