import dynamic from 'next/dynamic';
import React, {useCallback, useEffect, useState} from 'react';

const Vimeo = dynamic(async () => import('@u-wave/react-vimeo'));

type VimeoThumbnailRequestResponsePartial = {
  // eslint-disable-next-line camelcase
  thumbnail_large: string;
}[];

export const getThumbnailUrlFromVimeoUrl = async (
  url: string,
): Promise<string> => {
  const vimeoId = url.split('/').pop();
  const response = await fetch(
    `https://vimeo.com/api/v2/video/${vimeoId}.json`,
  );

  const responseAsString = await response.text();

  try {
    const [{thumbnail_large: thumbnailUrl}] = JSON.parse(
      responseAsString,
    ) as VimeoThumbnailRequestResponsePartial;

    return thumbnailUrl;
  } catch (e) {
    throw new Error(responseAsString);
  }
};

export interface GalleryCarouselVideoProps {
  src: string;
  isItemActive: boolean;
}

export const GalleryCarouselVideo = React.memo(
  ({src, isItemActive}: GalleryCarouselVideoProps) => {
    const [hasUserPlayedVideo, setHasUserPlayedVideo] = useState(false);

    const handlePlay = useCallback(() => {
      setHasUserPlayedVideo(true);
    }, []);

    useEffect(() => {
      if (!isItemActive) {
        setHasUserPlayedVideo(false);
      }
    }, [isItemActive]);

    return (
      <Vimeo
        video={src}
        autoplay={false}
        paused={!hasUserPlayedVideo || !isItemActive}
        style={{height: '100%', overflow: 'hidden'}}
        dnt
        onPlay={handlePlay}
      />
    );
  },
);
