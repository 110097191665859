import React, {CSSProperties, useCallback, useMemo} from 'react';
import {StructuredText, StructuredTextDocument} from 'react-datocms';
import {
  isHeading,
  RenderRule,
  Heading,
  isParagraph,
  isListItem,
  isLink,
  Link,
} from 'datocms-structured-text-utils';
import {
  StructuredTextModelStructuredTextBlocksField,
  PageContentModelReusableComponentsField,
  ArticleModelContentBlocksField,
  PageContentRecord,
  LinkableLinkRecordFragment,
  LinkableLocationSiteRecordFragment,
} from 'graphql/generated/graphql';
import {AnimatedHeaderText} from 'ui/components/AnimatedHeaderText';
import {colors} from '../theme';
import {DatoHighlightedText} from './DatoHighlightedText';
import {RunwayEastLink} from '../link/RunwayEastLink';
import {ImageWithCaption} from '../image/ImageWithCaption';
import {Quote} from '../quote/Quote';
import {DatoNewsletterSignupCtaBox} from '../e-box/DatoNewsletterSignupCtaBox';
import {transformDatoResponsiveImageFragment} from '../image/ImageRenderer';
import {
  DatoButtonLink,
  datoModelToHref,
  LinkableDatoModel,
  resolveHrefFromLinkFields,
} from '../link/DatoLink';
import {CustomHtml} from '../custom-html/CustomHtml';
import {DatoImageWithOverlappingContent} from '../image-with-overlapping-content/DatoImageWithOverlappingContent';
import {DatoFindASpaceDropdown} from '../find-a-space/DatoFindASpaceDropdown';

type UnusedBlockFields =
  | '_createdAt'
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | '_firstPublishedAt'
  | '_isValid'
  | '_modelApiKey'
  | '_publicationScheduledAt'
  | '_publishedAt'
  | '_seoMetaTags'
  | '_status'
  | '_unpublishingScheduledAt'
  | '_updatedAt';

type Blocks =
  | StructuredTextModelStructuredTextBlocksField
  | PageContentModelReusableComponentsField
  | PageContentRecord
  | ArticleModelContentBlocksField;

type Links = LinkableLinkRecordFragment | LinkableLocationSiteRecordFragment;
interface DatoStructuredTextIntrinsicElementRule {
  classNames?: string[];
  style?: CSSProperties;
}

export type DatoStructuredTextConfig = {
  [element in keyof JSX.IntrinsicElements]?:
    | DatoStructuredTextIntrinsicElementRule
    | undefined;
};

type RunwayEastRenderRuleResolver = (
  runwayEastStyle: DatoStructuredTextConfig | undefined,
) => RenderRule<any, any, any>;

export const runwayEastDefaultStyle: DatoStructuredTextConfig = {
  p: {
    classNames: ['rwe-p1'],
    style: {color: colors.slate.darkSlate},
  },
  h1: {
    classNames: ['rwe-h1'],
    style: {color: colors.primary.charcoalBlack},
  },
  h2: {
    classNames: ['rwe-h2'],
    style: {color: colors.primary.charcoalBlack},
  },
  h3: {
    classNames: ['rwe-h3'],
    style: {color: colors.primary.charcoalBlack},
  },
  h4: {
    classNames: ['rwe-h4-alt'],
    style: {color: colors.slate.darkSlate},
  },
  h5: {
    classNames: ['rwe-h4-alt'],
    style: {color: colors.slate.darkSlate},
  },
  h6: {
    classNames: ['rwe-h4-alt'],
    style: {color: colors.slate.darkSlate},
  },
  li: {
    classNames: ['rwe-li'],
    style: {color: colors.slate.darkSlate},
  },
  a: {
    classNames: ['rwe-a'],
    style: {
      color: colors.primary.teal,
      textDecoration: 'underline',
    },
  },
};

const headingRenderRule: RunwayEastRenderRuleResolver = (
  datoStructuredTextConfigOverride,
) => {
  return {
    appliable: isHeading,
    apply: ({node, children, key}) => {
      const typedNode = node as Heading;
      const HeadingTag = `h${typedNode.level}` as keyof JSX.IntrinsicElements; // Use this as a primitive HTML React component constructor
      const defaultStyle = runwayEastDefaultStyle[HeadingTag] || {};
      const {classNames, style} = datoStructuredTextConfigOverride
        ? datoStructuredTextConfigOverride[HeadingTag] || defaultStyle
        : defaultStyle;
      return children ? (
        <HeadingTag
          key={key}
          className={classNames ? classNames.join(' ') : undefined}
          style={style}
        >
          <AnimatedHeaderText>{children}</AnimatedHeaderText>
        </HeadingTag>
      ) : null;
    },
  };
};

const paragraphRenderRule: RunwayEastRenderRuleResolver = (
  datoStructuredTextConfigOverride,
) => {
  const defaultStyle = runwayEastDefaultStyle.p || {};
  const {classNames, style} = datoStructuredTextConfigOverride
    ? datoStructuredTextConfigOverride.p || defaultStyle
    : defaultStyle;
  return {
    appliable: isParagraph,
    apply: ({children, key}) =>
      children ? (
        <p
          key={key}
          className={classNames ? classNames.join(' ') : undefined}
          style={style}
        >
          {children}
        </p>
      ) : null,
  };
};

const listItemRenderRule: RunwayEastRenderRuleResolver = (
  datoStructuredTextConfigOverride,
) => {
  const defaultStyle = runwayEastDefaultStyle.li || {};
  const {classNames, style} = datoStructuredTextConfigOverride
    ? datoStructuredTextConfigOverride.li || defaultStyle
    : defaultStyle;
  return {
    appliable: isListItem,
    apply: ({children, key}) =>
      children ? (
        <li
          key={key}
          className={classNames ? classNames.join(' ') : undefined}
          style={style}
        >
          {children}
        </li>
      ) : null,
  };
};

const linkRenderRule: RunwayEastRenderRuleResolver = (
  datoStructuredTextConfigOverride,
) => {
  const defaultStyle = runwayEastDefaultStyle.a || {};
  const {classNames, style} = datoStructuredTextConfigOverride
    ? datoStructuredTextConfigOverride.a || defaultStyle
    : defaultStyle;
  return {
    appliable: isLink,
    apply: ({children, key, node}) =>
      children ? (
        <RunwayEastLink
          key={key}
          href={(node as Link).url}
          wrapChildrenWithAnchorElementForInternalLinks
          className={classNames ? classNames.join(' ') : undefined}
          style={style}
        >
          {children}
        </RunwayEastLink>
      ) : null,
  };
};

const blockRenderer = (record: Blocks): React.ReactElement | null => {
  // eslint-disable-next-line no-underscore-dangle
  switch (record.__typename) {
    case 'HighlightedTextBlockRecord':
      return record.structuredText ? (
        <DatoHighlightedText
          backgroundColor={
            record.backgroundColour
              ? record.backgroundColour.hex || undefined
              : undefined
          }
          data={record.structuredText}
        />
      ) : null;
    case 'IconBlockRecord':
      return record.icon ? (
        <img
          className="rwe-structured-text__icon"
          src={record.icon.url}
          alt={record.icon.alt || record.icon.url}
          loading="lazy"
        />
      ) : null;
    case 'ImageBlockRecord':
      // eslint-disable-next-line no-case-declarations
      const transformedDatoResponsiveImageFragment = record.image
        ? transformDatoResponsiveImageFragment(record.image)
        : null;
      return transformedDatoResponsiveImageFragment ? (
        <div className="rwe-structured-text__image-with-caption">
          <ImageWithCaption
            image={
              typeof transformedDatoResponsiveImageFragment === 'string'
                ? transformedDatoResponsiveImageFragment
                : {
                    ...transformedDatoResponsiveImageFragment,
                    // it is likely that the image is going to be below the fold
                    lazyLoad: true,
                  }
            }
            caption={record.caption || undefined}
          />
        </div>
      ) : null;
    case 'QuoteBlockRecord':
      return record.quote ? (
        <div className="rwe-structured-text__quote">
          <Quote>{record.quote}</Quote>
        </div>
      ) : null;
    case 'ECtaBlockRecord':
      return (
        <div className="rwe-structured-text__e-cta-block">
          <DatoImageWithOverlappingContent {...(record as any)} lazyLoadImage />
        </div>
      );
    case 'NewsletterSignupBlockRecord':
      return (
        <div className="rwe-structured-text__newsletter-signup">
          <DatoNewsletterSignupCtaBox {...(record as any)} />
        </div>
      );
    case 'CustomHtmlBlockRecord':
      return record.customHtml ? (
        <div className="rwe-structured-text__custom-html">
          <CustomHtml>{record.customHtml}</CustomHtml>
        </div>
      ) : null;
    case 'FindASpaceButtonRecord':
      return <DatoFindASpaceDropdown />;
    default:
      return null;
  }
};

const linkToRecordRenderer = (
  record: LinkableDatoModel,
  children: React.ReactNode,
  datoStructuredTextConfigOverride: DatoStructuredTextConfig | undefined,
): React.ReactElement | null => {
  if (!record) return <RunwayEastLink>{children}</RunwayEastLink>;

  const href =
    // eslint-disable-next-line no-underscore-dangle
    record.__typename === 'LinkRecord'
      ? resolveHrefFromLinkFields(record)
      : datoModelToHref(record);

  const defaultStyle = runwayEastDefaultStyle.a || {};
  const {classNames, style} = datoStructuredTextConfigOverride
    ? datoStructuredTextConfigOverride.a || defaultStyle
    : defaultStyle;

  return (
    <RunwayEastLink
      href={href || undefined}
      wrapChildrenWithAnchorElementForInternalLinks
      className={classNames ? classNames.join(' ') : undefined}
      style={style}
    >
      {children}
    </RunwayEastLink>
  );
};

const inlineRecordRenderer = (
  record: LinkableLinkRecordFragment,
): React.ReactElement | null => (
  <div className="rwe-structured-text__button">
    <DatoButtonLink data={record} />
  </div>
);

export interface DatoRunwayEastStructuredTextProps {
  data:
    | {
        value: StructuredTextDocument;
        blocks?: NonNullable<Omit<Blocks, UnusedBlockFields>[]>;
        links?: NonNullable<Omit<Links | Blocks, UnusedBlockFields>[]>;
      }
    | null
    | undefined;
  runwayEastStyle?: DatoStructuredTextConfig;
}

export const DatoRunwayEastStructuredText = React.memo(
  ({runwayEastStyle, ...rest}: DatoRunwayEastStructuredTextProps) => {
    const renderRules = useMemo(
      (): RenderRule<any, any, any>[] =>
        [
          headingRenderRule,
          paragraphRenderRule,
          listItemRenderRule,
          linkRenderRule,
        ].map((rule) => rule(runwayEastStyle)),
      [runwayEastStyle],
    );

    const renderBlock = useCallback(
      ({record}: {record: Blocks}) => blockRenderer(record),
      [],
    );
    const renderLinkToRecord = useCallback(
      ({
        record,
        children,
      }: {
        record: LinkableDatoModel;
        children: React.ReactNode;
      }) => linkToRecordRenderer(record, children, runwayEastStyle),
      [runwayEastStyle],
    );
    const renderInlineRecord = useCallback(
      ({record}: {record: LinkableLinkRecordFragment}) =>
        inlineRecordRenderer(record),
      [],
    );

    return (
      <StructuredText<any>
        {...rest}
        customRules={renderRules}
        renderBlock={renderBlock}
        renderLinkToRecord={renderLinkToRecord}
        renderInlineRecord={renderInlineRecord}
      />
    );
  },
);
