import {
  DatoArticlePageQuery,
  DatoArticlePageQueryVariables,
} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {HOST} from 'hooks/use-environment';
import {useRouter} from 'next/router';
import {transformDatoResponsiveImageFragment} from '../image/ImageRenderer';
import {transformDatoBreadcrumbsFragment} from '../section/DatoBreadcrumbsSection';
import {
  DatoRunwayEastStructuredText,
  DatoStructuredTextConfig,
} from '../text/DatoRunwayEastStructuredText';
import {colors} from '../theme';
import {ArticlePage, ArticlePageProps} from './ArticlePage';
import {JsonLd} from '../json-ld/JsonLd';

type ArticleData = NonNullable<DatoArticlePageQuery['article']>;

export type ArticlePageQuery = {
  type: 'article';
  data: ArticleData;
  queryVariables: DatoArticlePageQueryVariables;
  query: string;
};

const articleStructuredTextConfig: DatoStructuredTextConfig = {
  h1: {
    classNames: ['rwe-h1', 'rwe-h1--article'],
    style: {
      color: colors.primary.charcoalBlack,
    },
  },
  h2: {
    classNames: ['rwe-h2', 'rwe-h2--article'],
    style: {
      color: colors.primary.charcoalBlack,
    },
  },
  h3: {
    classNames: ['rwe-h3', 'rwe-h3--article'],
    style: {
      color: colors.primary.charcoalBlack,
    },
  },
  h4: {
    classNames: ['rwe-h4-alt'],
    style: {
      color: colors.slate.darkSlate,
    },
  },
  h5: {
    classNames: ['rwe-h4-alt'],
    style: {
      color: colors.slate.darkSlate,
    },
  },
  h6: {
    classNames: ['rwe-h4-alt'],
    style: {
      color: colors.slate.darkSlate,
    },
  },
  p: {
    classNames: ['rwe-p1', 'rwe-p1--article'],
    style: {
      color: colors.slate.darkSlate,
    },
  },
  li: {
    classNames: ['rwe-li'],
    style: {color: colors.slate.darkSlate},
  },
};

const transformDatoArticlePageFragment = (
  article: ArticleData,
): ArticlePageProps => {
  return {
    byline: article.author || '',
    breadcrumbs: article.showBreadcrumb
      ? transformDatoBreadcrumbsFragment(article)
      : [],
    image: article.image
      ? transformDatoResponsiveImageFragment(article.image, false)
      : undefined,
    title: article.title || '',
    date: article.date ? new Date(article.date).toString() : undefined,
    categories: article.categories.map((category) => ({
      href: category.pagePath || '',
      children: category.categoryName || '',
      isActive: false,
      backgroundColor: category.categoryColour?.hex || '',
    })),
    children: article.content ? (
      <DatoRunwayEastStructuredText
        data={article.content}
        runwayEastStyle={articleStructuredTextConfig}
      />
    ) : null,
  };
};
export interface DatoArticlePageProps {
  data: ArticleData;
}

export const DatoArticlePage = React.memo(({data}: DatoArticlePageProps) => {
  const router = useRouter();
  const articlePageProps = useMemo(
    () => transformDatoArticlePageFragment(data),
    [data],
  );

  // eslint-disable-next-line no-underscore-dangle
  const datePublished = new Date(data.date || data._createdAt).toISOString();

  return (
    <>
      <JsonLd
        schema={{
          '@type': 'BlogPosting',
          url: `${HOST}${router.asPath}`,
          author: {
            '@type': 'Person',
            name: articlePageProps.byline,
          },
          datePublished,
          dateCreated: datePublished,
          dateModified: datePublished,
          headline: articlePageProps.title,
          image: data.image?.url,
          ...data.seoStructuredData,
        }}
      />
      <ArticlePage {...articlePageProps} />
    </>
  );
});
