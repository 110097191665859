import React, {useMemo} from 'react';
import {CtaBox} from '../../e-box/CtaBox';
import {useCarousel} from '../CarouselContext';
import {TestimonialCarouselItem} from './TestimonialCarousel';

export const TestimonialCarouselTestimonialBox = React.memo(() => {
  const {data, visibleIndexes} = useCarousel<TestimonialCarouselItem>();
  const activeItem = data[visibleIndexes.indexOf(0)];
  return (
    <div className="rwe-testimonial-carousel__testimonial-container">
      {activeItem ? (
        <CtaBox
          backgroundColor={activeItem.eBoxBackgroundColor}
          textColor={activeItem.eBoxTextColor}
        >
          {activeItem.LogoComponent ? (
            <div className="rwe-cta-box__testimonial-logo">
              {activeItem.LogoComponent}
            </div>
          ) : null}
          {activeItem.TestimonialBodyComponent ? (
            <div className="rwe-cta-box__testimonial-text">
              {activeItem.TestimonialBodyComponent}
            </div>
          ) : null}
          {activeItem.testimonialCreditText ? (
            <div className="rwe-p1">{activeItem.testimonialCreditText}</div>
          ) : null}
        </CtaBox>
      ) : null}
    </div>
  );
});
