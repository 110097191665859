import React, {useMemo} from 'react';
import {TestimonialCarouselItem} from '../carousel/testimonial/TestimonialCarousel';
import {useFloatingCta} from '../floating-cta/FloatingCtaContext';
import {ImageRendererConfig, OptimizedImage} from '../image/ImageRenderer';
import {colors} from '../theme';

export interface TestimonialSingleSectionProps {
  image?: ImageRendererConfig;
  backgroundColor?: string;
  blockId?: string;
  blockName?: string;
  LaptopVisible?: string;
  MobileVisible?: string;
  testimonial: TestimonialCarouselItem;
}

export const TestimonialSingleSection = React.memo(
  ({image, backgroundColor, testimonial, blockId, blockName, LaptopVisible, MobileVisible}: TestimonialSingleSectionProps) => {
    const floatingCta = useFloatingCta();
    const style = useMemo(
      () => (backgroundColor ? {backgroundColor} : {paddingTop: 0}),
      [backgroundColor],
    );
    const testimonialContainerStyle = useMemo(
      (): React.CSSProperties => ({
        ...floatingCta.avoidanceStyle,
        backgroundColor: testimonial.eBoxBackgroundColor,
        color: colors.primary.white,
      }),
      [floatingCta.avoidanceStyle, testimonial.eBoxBackgroundColor],
    );
    let DisplayMobile = '';
    let DisplayLaptop = '';
    if(MobileVisible == 'no'){
      DisplayMobile = 'DontDisplayMobile'
    }
    if(MobileVisible == 'yes'){
      DisplayMobile = 'DisplayMobile'
    }
    if(LaptopVisible == 'no'){
      DisplayLaptop = 'DontDisplayLaptop'
    }
    if(LaptopVisible == 'yes'){
      DisplayLaptop = 'DisplayLaptop'
    }
    return (
      <div className={"rwe-testimonial-single-section" + ' ' + DisplayMobile + ' ' + DisplayLaptop} id={blockId == '' ? undefined : blockId} attr-name={blockName == '' ? undefined : blockName}>
        <div className="rwe-section" style={style}>
          <div className="rwe-content-container">
            <div className="rwe-testimonial-single">
              {image ? (
                <div className="rwe-testimonial-single__image">
                  <OptimizedImage data={image} />
                </div>
              ) : null}
              <div
                className="rwe-testimonial-single__testimonial"
                style={testimonialContainerStyle}
              >
                {testimonial.LogoComponent ? (
                  <div className="rwe-testimonial-single__testimonial-logo">
                    {testimonial.LogoComponent}
                  </div>
                ) : null}
                {testimonial.TestimonialBodyComponent ? (
                  <div className="rwe-testimonial-single__testimonial-text">
                    {testimonial.TestimonialBodyComponent}
                  </div>
                ) : null}
                {testimonial.testimonialCreditText ? (
                  <div className="rwe-p1">
                    {testimonial.testimonialCreditText}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
