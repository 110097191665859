import {
  DatoMembershipsSearchPageQuery,
  DatoWorkspacesQuery,
  useDatoMembershipsSearchPageQuery,
  useDatoWorkspacesQuery,
} from 'graphql/generated/graphql';
import {useFilter} from 'hooks/use-filter';
import {useCallback, useMemo} from 'react';

export const capacities = ['1-4', '4-8', '8-12', '12-20', '20-40', '40+'];

export const getSiteIdsFromLocationSitesData = (
  data: DatoMembershipsSearchPageQuery,
): string[] => data.allLocationSites.map((site) => site.id);

interface UseWorkspaceFiltersReturnValue {
  data: DatoWorkspacesQuery | undefined;
  clearSelectedValues: () => void;
  filters: {
    title: string;
    type: 'checkbox' | 'radio';
    selected: string[];
    onSelect: (values: string[] | null) => any;
    items: {
      text: string;
      items: {text: string}[];
    }[];
  }[];
  routerQuery: Record<string, string | string[]>;
}

interface UseWorkspaceFiltersOptions {
  updateRouterQueryOnChange?: boolean;
}

const locationsRouterQueryKey = 'locations';
const capacitiesRouterQueryKey = 'capacities';

export const useWorkspaceFilters = (
  options: UseWorkspaceFiltersOptions = {updateRouterQueryOnChange: false},
): UseWorkspaceFiltersReturnValue => {
  const locationsFilter = useFilter({
    ...options,
    routerQueryKey: locationsRouterQueryKey,
    type: 'multipleSelect',
  });
  const capacitiesFilter = useFilter({
    ...options,
    routerQueryKey: capacitiesRouterQueryKey,
    type: 'multipleSelect',
  });

  const membershipsSearchPageQuery = useDatoMembershipsSearchPageQuery();

  const allSitesIds = useMemo(
    () =>
      membershipsSearchPageQuery.data
        ? getSiteIdsFromLocationSitesData(membershipsSearchPageQuery.data)
        : [],
    [membershipsSearchPageQuery.data],
  );

  const {data} = useDatoWorkspacesQuery({
    variables: {
      /**
       * The 'in' operator for the 'filter' field in a DatoCMS GraphQL query means that
       * if an empty array is passed, only those with an empty value for the field will be in the result
       */
      siteIds: locationsFilter.selectedValues.length
        ? locationsFilter.selectedValues
        : allSitesIds,
      capacities: capacitiesFilter.selectedValues.length
        ? capacitiesFilter.selectedValues
        : capacities,
    },
  });

  const citiesAndSitesMap = useMemo(() => {
    if (!membershipsSearchPageQuery.data) return [];

    const membershipsSearchPageQueryData = membershipsSearchPageQuery.data;

    return membershipsSearchPageQuery.data.allCities.map((city) => ({
      text: city.name as string,
      items: membershipsSearchPageQueryData.allLocationSites
        .filter((site) => site.city?.name === city.name)
        .map((site) => ({text: site.siteName as string, value: site.id})),
    }));
  }, [membershipsSearchPageQuery.data]);

  const clearSelectedValues = useCallback(() => {
    locationsFilter.setSelectedValues([]);
    capacitiesFilter.setSelectedValues([]);
  }, [capacitiesFilter, locationsFilter]);

  const filters = useMemo(
    (): UseWorkspaceFiltersReturnValue['filters'] => [
      {
        title: 'Location',
        selected: locationsFilter.selectedValues,
        type: 'checkbox',
        items: [
          {
            text: 'All Locations',
            items: citiesAndSitesMap,
          },
        ],
        onSelect: locationsFilter.onValueSelect,
      },
      {
        title: 'Team Sizes',
        selected: capacitiesFilter.selectedValues,
        type: 'checkbox',
        items: [
          {
            text: 'All Sizes',
            items: capacities.map((text) => ({text})),
          },
        ],
        onSelect: capacitiesFilter.onValueSelect,
      },
    ],
    [
      citiesAndSitesMap,
      locationsFilter.onValueSelect,
      capacitiesFilter.onValueSelect,
      locationsFilter.selectedValues,
      capacitiesFilter.selectedValues,
    ],
  );

  return useMemo(
    () => ({
      data,
      clearSelectedValues,
      filters,
      routerQuery: {
        [capacitiesRouterQueryKey]: capacitiesFilter.selectedValues,
        [locationsRouterQueryKey]: locationsFilter.selectedValues,
      },
    }),
    [
      data,
      clearSelectedValues,
      filters,
      capacitiesFilter.selectedValues,
      locationsFilter.selectedValues,
    ],
  );
};
