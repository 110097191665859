import React from 'react';
import {NewsletterSignUpInput} from '../input/NewsletterSignUpInput';
import {colors} from '../theme';
import {CtaBox} from './CtaBox';

export interface NewsletterSignupCtaBoxProps {
  bodyContent: React.ReactNode;
  textInputPlaceholderText?: string;
  contentUnderTextInput: React.ReactNode;
  buttonText?: string;
}

export const NewsletterSignupCtaBox = React.memo(
  ({
    bodyContent,
    buttonText,
    contentUnderTextInput,
    textInputPlaceholderText,
  }: NewsletterSignupCtaBoxProps) => (
    <div className="rwe-newsletter-signup-ebox">
      <CtaBox
        backgroundColor={colors.primary.teal}
        textColor={colors.primary.white}
      >
        <div className="rwe-newsletter-signup-ebox__text">{bodyContent}</div>
        <div className="rwe-newsletter-signup-ebox__input">
          <NewsletterSignUpInput
            buttonVariant="secondary"
            buttonText={buttonText}
            placeholder={textInputPlaceholderText}
          />
        </div>
        <div className="rwe-newsletter-signup-ebox__under-input-text">
          {contentUnderTextInput}
        </div>
      </CtaBox>
    </div>
  ),
);
