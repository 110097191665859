import {MultiColumnBlockFragment} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {
  FlexGrid,
  ItemsPerRowPerBreakpoint,
  NumberOfFlexGridColumns,
} from '../flex-grid/FlexGrid';
import {useFloatingCta} from '../floating-cta/FloatingCtaContext';
import {DatoRunwayEastStructuredText} from '../text/DatoRunwayEastStructuredText';
import {
  ContentWithCopySection,
  ContentWithCopySectionProps,
} from './ContentWithCopySection';

const FLEX_GRID_ITEMS_PER_ROW_PER_BREAKPOINT_MAP: Record<
  NumberOfFlexGridColumns,
  ItemsPerRowPerBreakpoint
> = {
  1: [1, 1, 1, 1, 1],
  2: [1, 1, 2, 2, 2],
  3: [1, 2, 3, 3, 3],
  4: [1, 2, 4, 4, 4],
};

const FLEX_GRID_ITEMS_PER_ROW_PER_BREAKPOINT_WITH_FLOATING_CTA_MAP: Record<
  NumberOfFlexGridColumns,
  ItemsPerRowPerBreakpoint
> = {
  1: [1, 1, 1, 1, 1],
  2: [1, 1, 1, 2, 2],
  3: [1, 2, 2, 2, 2],
  4: [1, 2, 3, 3, 3],
};

export const transformDatoMultiColumnBlockFragment = (
  {
    backgroundColour,
    heading,
    numberOfColumns,
    textBlocks,
    hideSeparatorLine,
    blockCardIdentify,
    blockMenuName,
    onLaptop,
    onMobile,
    theme,
  }: MultiColumnBlockFragment,
  isFloatingCtaComponentPresent: boolean,
): ContentWithCopySectionProps => {
  const flexGridItemsPerRowPerBreakpointMap = isFloatingCtaComponentPresent
    ? FLEX_GRID_ITEMS_PER_ROW_PER_BREAKPOINT_WITH_FLOATING_CTA_MAP
    : FLEX_GRID_ITEMS_PER_ROW_PER_BREAKPOINT_MAP;

  const defaultFlexGridItemsPerRowPerBreakpoint =
    flexGridItemsPerRowPerBreakpointMap[4];

  const flexGridItemsPerRowPerBreakpoint = numberOfColumns
    ? flexGridItemsPerRowPerBreakpointMap[
        Number(numberOfColumns) as NumberOfFlexGridColumns
      ] || defaultFlexGridItemsPerRowPerBreakpoint
    : defaultFlexGridItemsPerRowPerBreakpoint;
  return {
    withLine: !hideSeparatorLine,
    blockId: blockCardIdentify as string,
    blockName: blockMenuName as string,
    LaptopVisible: onLaptop as string,
    MobileVisible: onMobile as string,
    backgroundColor: backgroundColour
      ? backgroundColour.hex || undefined
      : undefined,
    copy: heading ? <DatoRunwayEastStructuredText data={heading} /> : null,
    copyPosition: 'above',
    children: (
      <FlexGrid itemsPerRowPerBreakpoint={flexGridItemsPerRowPerBreakpoint}>
        {textBlocks.map((textBlock) =>
          textBlock.structuredText ? (
            <div
              style={{maxWidth: 800}}
              className={
                theme === 'card' && textBlock.structuredText.blocks?.length
                  ? 'rwe-multi-column-section__card-theme'
                  : ''
              }
            >
              <DatoRunwayEastStructuredText data={textBlock.structuredText} />
            </div>
          ) : null,
        )}
      </FlexGrid>
    ),
  };
};

export interface DatoMultiColumnSectionProps extends MultiColumnBlockFragment {
  index: number;
}

export const DatoMultiColumnSection: React.FC<DatoMultiColumnSectionProps> = React.memo(
  (props) => {
    const {isFloatingCtaPresent} = useFloatingCta();
    const contentWithCopySectionProps = useMemo((): ContentWithCopySectionProps => {
      return transformDatoMultiColumnBlockFragment(props, isFloatingCtaPresent);
    }, [props, isFloatingCtaPresent]);

    return (
      <div className="rwe-multi-column-section">
        <ContentWithCopySection {...contentWithCopySectionProps} />
      </div>
    );
  },
);
