import React from 'react';
import {formatDate} from '../../../../utils/date';
import {ImageRendererConfig, OptimizedImage} from '../image/ImageRenderer';
import {PillProps} from '../pill/Pill';
import {PillList} from '../pill/PillList';

export interface ArticleHeaderProps {
  image?: ImageRendererConfig;
  title: string;
  byline: string;
  date?: string;
  categories: PillProps[];
}

export const ArticleHeader = React.memo(
  ({byline, image, title, date, categories}: ArticleHeaderProps) => (
    <div className="rwe-article-header">
      <div className="rwe-content-container">
        <div className="rwe-article-header__content-container">
          {image ? (
            <div className="rwe-article-header__image">
              <OptimizedImage data={image} lazyLoad={false} shouldPreload />
            </div>
          ) : null}
          <div className="rwe-article-header__heading">
            <div className="rwe-article-header__title">
              <h1 className="rwe-h1 rwe-h1--article">{title}</h1>
            </div>
            <div className="rwe-article-header__author-and-date">
              <div className="rwe-article-header__author">
                <p className="rwe-h4-alt">{byline}</p>
              </div>
              {date ? (
                <div className="rwe-article-header__date">
                  <p className="rwe-h4-alt">{formatDate(new Date(date))}</p>
                </div>
              ) : null}
            </div>
            {categories.length ? (
              <div className="rwe-article-header__categories">
                <PillList data={categories} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ),
);
