import React, {useEffect, useMemo, useState, useRef} from 'react';
import {useFloatingCta} from '../floating-cta/FloatingCtaContext';
import {ImageRendererConfig, OptimizedImage} from '../image/ImageRenderer';

export interface ContentWithImageOnSideProps {
  /**
   * This only specifies the position on "desktop", "desktop large", and "desktop extra large" sized viewports.
   * On other viewport sizes, the image is always above the content
   */
  imagePosition: 'on-left' | 'on-right';
  image: ImageRendererConfig;
  backgroundColor?: string;
  children: React.ReactNode;
  withLine?: boolean;
  lazyLoadImage?: boolean;
  shouldPreload?: boolean;
  blockId?: string;
  blockName?: string;
  LaptopVisible?: string;
  MobileVisible?: string;
  FormSearch?: string;
}

export const ContentWithImageOnSide = React.memo(
  ({
    imagePosition,
    image,
    backgroundColor,
    children,
    withLine = true,
    lazyLoadImage,
    shouldPreload,
    blockId,
    blockName,
    MobileVisible,
    LaptopVisible,
    FormSearch,
  }: ContentWithImageOnSideProps) => {
    const floatingCta = useFloatingCta();
    const style = useMemo(
      () => (backgroundColor ? {backgroundColor} : undefined),
      [backgroundColor],
    );
    const sectionClassNames = useMemo((): string => {
      const classNames = ['rwe-section', `rwe-section--image-${imagePosition}`];

      if (!withLine) {
        classNames.push('rwe-section--without-line');
      }

      return classNames.join(' ');
    }, [imagePosition, withLine]);
    let searchForm = FormSearch;

    // if ((FormSearch != undefined) || FormSearch != null){
    //   const testObj = JSON.parse(FormSearch)
    //   //console.log(testObj)
    // }

    function SearchFormBlock() {
      const [cityData, setCityData] = useState<any[]>([]);
      let clickedBlock = '';
      

      useEffect(() => {
        try {
            if (FormSearch) {
                const obj: any = JSON.parse(FormSearch);
                setCityData(obj);
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    }, []);

      const checkParameters = (cityName: string, deskNum: string, bookDate: string) => {
        if(!cityData) {
          console.log('data error');
          return;
        }

        let foundCity: { desks: any; } | null = null;
          Object.values(cityData).forEach((cityArray: any) => {
            const city = cityArray.find((city: any) => city.name === cityName);
            if (city) {
              foundCity = city;
            }
          })
        
        if(!foundCity) {
          console.log("city not found");
          return;
        }
        //console.log('check for city ${cityName}');
        //console.log('City data: ', foundCity);
        if ('desks' in foundCity){
          let loadWrapper = document.getElementById('loadWrapper');
          if(loadWrapper != null){
            loadWrapper.style.display = 'block';
            if(bookDate == 'Select start date'){
              let errorSearch = document.getElementById('errorSearch');
              if(errorSearch != null){
                errorSearch.style.display = 'flex';
                setTimeout(() => {
                  if(loadWrapper != null && errorSearch != null){
                  loadWrapper.style.display = 'none'; 
                  errorSearch.style.display = 'none';
                  }
                }, 3000);
              }
            }
            else{
              setTimeout(() => {
                if((foundCity as {desks: any}).desks[deskNum][bookDate]){
                  let form = document.getElementById('searchForm');
                  if (form != null && loadWrapper != null){
                    form.style.display = 'block';
                    loadWrapper.style.display = 'none';
                  }
                }
                else{
                  let errorSearch = document.getElementById('errorSearch');
                  if(errorSearch != null){
                    errorSearch.style.display = 'flex';
                    setTimeout(() => {
                      if(loadWrapper != null && errorSearch != null){
                      loadWrapper.style.display = 'none'; 
                      errorSearch.style.display = 'none';
                      }
                    }, 3000);
                  }
                }
              }, 2000);
            }
          }
        }
      }
      const nameElementRef = useRef<HTMLDivElement>(null)
      const deskElementRef = useRef<HTMLDivElement>(null)
      const dateElementRef = useRef<HTMLDivElement>(null)
      let chooseOffice = nameElementRef.current
      let deskOffice = deskElementRef.current
      let dateOffice = dateElementRef.current
      useEffect(() => {
        if (cityData){
          const handleClick = (event: any) => {
            if ((chooseOffice != undefined && chooseOffice != null) && 
                (deskOffice != undefined && deskOffice != null) &&
                (dateOffice != undefined && dateOffice != null)){
                  if(chooseOffice.textContent == 'Search locations'){
                    chooseOffice.style.color = 'red';
                    if(deskOffice.textContent == 'no. of desks'){
                      deskOffice.style.color = 'red';
                      if(dateOffice.textContent == 'Select start date'){
                        dateOffice.style.color = 'red';
                      }
                    }
                    else{
                      if(dateOffice.textContent == 'Select start date'){
                        dateOffice.style.color = 'red';
                      }
                    }
                  }
                  else{
                    if(deskOffice.textContent == 'no. of desks'){
                      deskOffice.style.color = 'red';
                      if(dateOffice.textContent == 'Select start date'){
                        dateOffice.style.color = 'red';
                      }
                    }
                    else{
                      if(dateOffice.textContent == 'Select start date'){
                        dateOffice.style.color = 'red';
                      }
                      else{
                        checkParameters('' + chooseOffice.textContent, '' + deskOffice.textContent, '' + dateOffice.textContent);
                      }
                    }
                  }
            }
          }

          const btnSearch = document.getElementById('searchBtn');

            if(btnSearch){
              btnSearch.addEventListener('click', handleClick)
            }
        }
      }, [cityData])

      
      

      if (FormSearch != undefined || FormSearch != null){
        let testObj = [];
        try {
          testObj = JSON.parse(FormSearch);
      } catch (error) {
          console.error("Error parsing FormSearch JSON:", error);
      }

        const [showBlock, setShowBlock] = useState(false);
        const [showBlock2, setShowBlock2] = useState(false);
        const [showBlock3, setShowBlock3] = useState(false);

        const blockRef = useRef<HTMLDivElement>(null);
        const blockRef2 = useRef<HTMLDivElement>(null);
        const blockRef3 = useRef<HTMLDivElement>(null);

        useEffect(() => {
          function handleClickOustide(event: MouseEvent){
            if((blockRef.current && !blockRef.current.contains(event.target as Node)) || 
            (blockRef2.current && !blockRef2.current.contains(event.target as Node)) ||
            (blockRef3.current && !blockRef3.current.contains(event.target as Node))){
              setShowBlock(false);
              setShowBlock2(false);
              setShowBlock3(false);
            }
          }
          if (showBlock || showBlock2 || showBlock3){
            document.addEventListener('click', handleClickOustide)
          }

          return () => {
            document.removeEventListener('click', handleClickOustide)
          }
        }, [showBlock, showBlock2, showBlock3])

        const handleInnerBlockClick = (event: React.MouseEvent<HTMLDivElement>) => {
          event.stopPropagation();
          setShowBlock(false);
          setShowBlock2(false);
          setShowBlock3(false);
        }
        useEffect(() => {
          let searchBlock = document.getElementById('searchBlock');
          let blocks = document.querySelectorAll('.office-name-choose');
          const officeNameLayout = document.getElementById('nameOffice');
          if(searchBlock != undefined){
            if(blocks != undefined){
              blocks.forEach(function(block){
                block.addEventListener('click', function(event){
                clickedBlock = (event.target as HTMLTextAreaElement).id;
                if(!officeNameLayout) return;
                officeNameLayout.innerHTML = clickedBlock;
                officeNameLayout.style.color = '#000'
                if (document.querySelector<HTMLElement>('.office-name-wrapper') != null){
                  let dropdown1 = document.querySelector<HTMLElement>('.office-name-wrapper')
                  if (dropdown1 != null){
                    //dropdown1.style.display = 'none';
                  }
                }
              })
              })
            }
          }
        }, [clickedBlock])
        useEffect(() => {
          let searchBlock = document.getElementById('searchBlock');
          let blocks = document.querySelectorAll('.office-desks-choose');
          const officeDesksLayout = document.getElementById('noOfDesks');
          if(searchBlock != undefined){
            if(blocks != undefined){
              blocks.forEach(function(block){
                block.addEventListener('click', function(event){
                let clickedBlock = (event.target as HTMLTextAreaElement).id;
                if(!officeDesksLayout) return;
                officeDesksLayout.innerHTML = clickedBlock;
                officeDesksLayout.style.color = '#000'
                if (document.querySelector<HTMLElement>('.desks-number-wrapper') != null){
                  let dropdown1 = document.querySelector<HTMLElement>('.desks-number-wrapper')
                  if (dropdown1 != null){
                    //dropdown1.style.display = 'none';
                  }
                }
              })
              })
            }
          }
        }, [])
        useEffect(() => {
          let searchBlock = document.getElementById('searchBlock');
          let blocks = document.querySelectorAll('.office-date-choose');
          const officeDateLayout = document.getElementById('startDate');
          if(searchBlock != undefined){
            if(blocks != undefined){
              blocks.forEach(function(block){
                block.addEventListener('click', function(event){
                let clickedBlock = (event.target as HTMLTextAreaElement).id;
                if(!officeDateLayout) return;
                officeDateLayout.innerHTML = clickedBlock;
                officeDateLayout.style.color = '#000'
                if (document.querySelector<HTMLElement>('.data-office-wrapper') != null){
                  let dropdown1 = document.querySelector<HTMLElement>('.data-office-wrapper')
                  if (dropdown1 != null){
                    //dropdown1.style.display = 'none';
                  }
                }
              })
              })
            }
          }
        }, [])

        if(testObj && Object.keys(testObj).length > 0) {
          return(
            <div id='searchBlock'>
              <div className='search-wrapper'>
                <div className='search-wrapper-block search-name-block'>
                  <div className='search-open-btn' onClick={() => setShowBlock(true)}>
                    <div>Where</div>
                    <div id='nameOffice' className='gray-text' ref={nameElementRef}>Search locations</div>
                  </div>
                  <div className='office-name-wrapper' ref={blockRef} style={{display: showBlock ? 'flex' : 'none'}}>
                    <div>
                      <p>London</p>
                      {testObj.London.map((office: any )=> (
                        <div className='gray-text office-name-choose' onClick={handleInnerBlockClick} id={office.name} key={office.name}>{office.name}</div>
                      ))}  
                    </div>   
                    <div>
                    <p>UK</p>
                    {testObj.UnitedKingdom.map((office: any) => (
                      <div className='gray-text office-name-choose' onClick={handleInnerBlockClick} id={office.name} key={office.name}>{office.name}</div>
                    ))}    
                    </div>           
                  </div>
                </div>
                <div className='search-wrapper-block search-wrapper-block-center'>
                  <div className='search-open-btn' onClick={() => setShowBlock2(true)}>
                    <div>How many</div>
                    <div id='noOfDesks' className='gray-text' ref={deskElementRef}>no. of desks</div>
                  </div>
                  <div className='desks-number-wrapper' ref={blockRef2} style={{display: showBlock2 ? 'block' : 'none'}}>
                    <div className='gray-text office-desks-choose' onClick={handleInnerBlockClick} id='just me'>Just me</div>
                    <div className='gray-text office-desks-choose' onClick={handleInnerBlockClick} id='2 - 5'>2 - 5</div>
                    <div className='gray-text office-desks-choose' onClick={handleInnerBlockClick} id='6 - 10'>6 - 10</div>
                    <div className='gray-text office-desks-choose' onClick={handleInnerBlockClick} id='11 - 20'>11 - 20</div>
                    <div className='gray-text office-desks-choose' onClick={handleInnerBlockClick} id='21 - 30'>21 - 30</div>
                    <div className='gray-text office-desks-choose' onClick={handleInnerBlockClick} id='51 - 100'>51 - 100</div>
                  </div>
                </div>
                <div className='search-wrapper-block'>
                  <div className='search-open-btn' onClick={() => setShowBlock3(true)}>
                    <div>When</div>
                    <div id='startDate' className='gray-text' ref={dateElementRef}>Select start date</div>
                  </div>
                  <div className='data-office-wrapper' ref={blockRef3} style={{display: showBlock3 ? 'block' : 'none'}}>
                    <div className='gray-text office-date-choose' onClick={handleInnerBlockClick} id='ASAP'>ASAP</div>
                    <div className='gray-text office-date-choose' onClick={handleInnerBlockClick} id='Within 1 month'>Within 1 month</div>
                    <div className='gray-text office-date-choose' onClick={handleInnerBlockClick} id='Within 2 months'>Within 2 months</div>
                    <div className='gray-text office-date-choose' onClick={handleInnerBlockClick} id='Over 2 months'>Over 2 months</div>
                    <div className='gray-text office-date-choose' onClick={handleInnerBlockClick} id='Unsure / flexible'>Unsure / flexible</div>
                    <div className='gray-text office-date-choose' onClick={handleInnerBlockClick} id='Other'>Other</div>
                  </div>
                </div>
                <div className='search-wrapper-button'>
                  <div className='search-button' id='searchBtn'>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 488.40 488.40" width="20px" height="20px" fill="#fff" stroke="#fff" strokeWidth="26.3736" transform="matrix(1, 0, 0, 1, 0, 0)">
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="4.884"></g>
                      <g id="SVGRepo_iconCarrier">
                        <g>
                          <g>
                            <path d="M0,203.25c0,112.1,91.2,203.2,203.2,203.2c51.6,0,98.8-19.4,134.7-51.2l129.5,129.5c2.4,2.4,5.5,3.6,8.7,3.6 s6.3-1.2,8.7-3.6c4.8-4.8,4.8-12.5,0-17.3l-129.6-129.5c31.8-35.9,51.2-83,51.2-134.7c0-112.1-91.2-203.2-203.2-203.2 S0,91.15,0,203.25z M381.9,203.25c0,98.5-80.2,178.7-178.7,178.7s-178.7-80.2-178.7-178.7s80.2-178.7,178.7-178.7 S381.9,104.65,381.9,203.25z"></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                    Search</div>
                </div>
              </div>
              <div className='load-wrapper' id='loadWrapper'>
                <div className='load-wrapper-block'>
                  <div className='spinner-image'><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{margin: 'auto', background: 'rgb(255, 255, 255)', display: 'block', shapeRendering: 'auto'}} width="227px" height="227px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g transform="rotate(0 50 50)">
                      <rect x="46" y="28" rx="4" ry="4" width="8" height="8" fill="#a0a0a0">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.875s" repeatCount="indefinite"/>
                      </rect>
                    </g><g transform="rotate(45 50 50)">
                      <rect x="46" y="28" rx="4" ry="4" width="8" height="8" fill="#a0a0a0">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"/>
                      </rect>
                    </g><g transform="rotate(90 50 50)">
                      <rect x="46" y="28" rx="4" ry="4" width="8" height="8" fill="#a0a0a0">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.625s" repeatCount="indefinite"/>
                      </rect>
                    </g><g transform="rotate(135 50 50)">
                      <rect x="46" y="28" rx="4" ry="4" width="8" height="8" fill="#a0a0a0">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"/>
                      </rect>
                    </g><g transform="rotate(180 50 50)">
                      <rect x="46" y="28" rx="4" ry="4" width="8" height="8" fill="#a0a0a0">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.375s" repeatCount="indefinite"/>
                      </rect>
                    </g><g transform="rotate(225 50 50)">
                      <rect x="46" y="28" rx="4" ry="4" width="8" height="8" fill="#a0a0a0">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"/>
                      </rect>
                    </g><g transform="rotate(270 50 50)">
                      <rect x="46" y="28" rx="4" ry="4" width="8" height="8" fill="#a0a0a0">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.125s" repeatCount="indefinite"/>
                      </rect>
                    </g><g transform="rotate(315 50 50)">
                      <rect x="46" y="28" rx="4" ry="4" width="8" height="8" fill="#a0a0a0">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"/>
                      </rect>
                    </g></svg>
                  </div>
                  <div className='load-text'>Searching for available office spaces</div>
                  <div className='error-result' id='errorSearch'>
                    Oops, we've not got a site there yet, but we'll let you know when we do!
                  </div>
                </div>
              </div>
            </div>
          )
        }
        else {
          return (
            <div></div>
          )
        }

    }}

    let DisplayMobile = '';
    let DisplayLaptop = '';
    if(MobileVisible == 'no'){
      DisplayMobile = 'DontDisplayMobile'
    }
    if(MobileVisible == 'yes'){
      DisplayMobile = 'DisplayMobile'
    }
    if(LaptopVisible == 'no'){
      DisplayLaptop = 'DontDisplayLaptop'
    }
    if(LaptopVisible == 'yes'){
      DisplayLaptop = 'DisplayLaptop'
    }
    return (
      <div className={sectionClassNames + ' ' + DisplayMobile + ' ' + DisplayLaptop} style={style} id={blockId == '' ? undefined : blockId} attr-name={blockName == '' ? undefined : blockName}>
        <div className="rwe-content-container">
          {withLine ? <div className="rwe-section__line" /> : null}
          <div className="rwe-section__content-container">
            <div
              className="rwe-section__image"
              style={
                // This value will be overridden on the rwe-breakpoint called "tablet" from ./_section.scss. The <FloatingCta /> is fix-positioned at the bottom of the page and the content offset provided by the below will not be required
                imagePosition === 'on-right'
                  ? floatingCta.avoidanceStyle
                  : undefined
              }
            >
              <OptimizedImage
                lazyLoad={lazyLoadImage}
                shouldPreload={shouldPreload}
                data={image}
              />
            </div>
            <div
              className="rwe-section__content"
              style={
                // This value will be overridden on the rwe-breakpoint called "tablet" from ./_section.scss. The <FloatingCta /> is fix-positioned at the bottom of the page and the content offset provided by the below will not be required
                imagePosition === 'on-left'
                  ? floatingCta.avoidanceStyle
                  : undefined
              }
            >
              {children}
              <div>
                {
                  (!FormSearch)
                  ? <div></div>
                  : SearchFormBlock()
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
