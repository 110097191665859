import React from 'react';
import {RunwayEastButton} from '../button/RunwayEastButton';
import {ImageRendererConfig, OptimizedImage} from '../image/ImageRenderer';

export interface SolutionCardProps {
  image?: ImageRendererConfig;
  heading?: string;
  subHeading?: string;
  copy?: string;
  href?: string;
  linkText?: string;
}

export const SolutionCard = React.memo(
  ({
    image,
    heading,
    subHeading,
    copy,
    href,
    linkText = 'Learn more',
  }: SolutionCardProps) => (
    <div className="rwe-card rwe-card--solution">
      {image ? (
        <div className="rwe-card__image">
          <OptimizedImage data={image} />
        </div>
      ) : null}
      {heading ? (
        <div className="rwe-card__heading rwe-card--solution__heading">
          {heading}
        </div>
      ) : null}
      {subHeading ? (
        <div className="rwe-card--solution__sub-heading">{subHeading}</div>
      ) : null}
      {copy ? <div className="rwe-card--solution__copy">{copy}</div> : null}
      {href ? (
        <div className="rwe-card--solution__link">
          <RunwayEastButton variant="tertiary" href={href}>
            {linkText}
          </RunwayEastButton>
        </div>
      ) : null}
    </div>
  ),
);
