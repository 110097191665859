import {
  DatoCustomPageQuery,
  DatoCustomPageQueryVariables,
} from 'graphql/generated/graphql';
import React, {useEffect, useState} from 'react';
import {DatoReusableComponents} from '../DatoReusableComponents';
import {JsonLd} from '../json-ld/JsonLd';
import {DatoBreadcrumbsSection} from '../section/DatoBreadcrumbsSection';
import { useRouter } from 'next/router'

export type CustomPageQuery = {
  type: 'custom';
  data: DatoCustomPageQuery['customPage'];
  queryVariables: DatoCustomPageQueryVariables;
  query: string;
};

export interface DatoCustomPageProps {
  data: DatoCustomPageQuery['customPage'];
}

export const DatoCustomPage = React.memo(({data}: DatoCustomPageProps) => {
  if (!data) return null;
  const [headers, setHeaders] = useState<{ id: string, name: string }[]>([]);

  const { asPath } = useRouter()

  useEffect(() => {
    const getElements = () => {
      const elements = document.querySelectorAll('[attr-name]');
      const data: { id: string, name: string }[] = [];

      elements.forEach(el => {
        data.push({
          id: el.id,
          name: el.getAttribute('attr-name') || ''
        })
      })
      setHeaders(data);
    }

    getElements();

  }, [asPath])

  const [heig, setHeig] = useState(0);

  useEffect(() => {
    let heii = document.getElementsByClassName('rwe-navbar-container')[0].clientHeight;
    document.querySelectorAll<HTMLElement>('.rwe-custom-site-page__subHeader')[0].style.paddingTop = heii+14+'px';
    let oldValue = 0;
    window.addEventListener('scroll', function(e){

      // Get the new Value
      let newValue = window.pageYOffset;
  
      //Subtract the two and conclude
      if(oldValue - newValue < 0){
        document.querySelectorAll<HTMLElement>('.rwe-custom-site-page__subHeader')[0].style.paddingTop = '14px';
          //console.log("Up");
      } else if(oldValue - newValue > 0){
        document.querySelectorAll<HTMLElement>('.rwe-custom-site-page__subHeader')[0].style.paddingTop = heii+14+'px';
          //console.log("Down");
      }
      if(document.getElementsByClassName('rwe-navbar-container')[0].classList.contains('rwe-navbar-container--hidden')){
        document.querySelectorAll<HTMLElement>('.rwe-custom-site-page__subHeader')[0].style.paddingTop = '14px';        
      }
      // Update the old value
      oldValue = newValue;
  });
    setHeig(heii)
  }, [])

  useEffect(() => {
    if(headers.length < 2){
      document.querySelectorAll<HTMLElement>('.rwe-custom-site-page__subHeader')[0].style.display = 'none';
    }
    else{
      document.querySelectorAll<HTMLElement>('.rwe-custom-site-page__subHeader')[0].style.display = 'flex';
    }
  })

  const [activeSection, setActiveSection] = useState<string | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      // Get a list of all the sections on the page
      const sections = Array.from(document.querySelectorAll('[attr-name]'));
      sections.reverse();
      

      // Loop through the sections and check if the scroll position is past each one
      for (const section of sections) {
        let head = document.querySelector('[href="#'+section.id+'"]') as HTMLElement | null;
        let otherhead = document.querySelectorAll<HTMLElement>('.rwe-custom-site-page__subHeader li a');
        if (otherhead != null){
          otherhead.forEach(box => { box.style.color = "black"; box.style.borderBottom = 'none' })
        }
        if (section != null){
          if (section instanceof HTMLElement){
            if (window.scrollY >= (section.offsetTop - 100)) {
              if (head != null){
                head.style.borderBottom = '5px solid #20caab'
                head.style.color = '#20caab'
              }
              setActiveSection(section.id);
              break;
            }
          }
        }
      }
      
    };

    // Attach the event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    // @ts-ignore
    window.__rwe_scroll_locked = true;


    // @ts-ignore
    setTimeout(()=>{
      // @ts-ignore
      window.__rwe_scroll_locked = false;
    }
      , 750)
    
  }

  return (
    <>
      {data.seoStructuredData ? (
        <JsonLd
          schema={{
            ...data.seoStructuredData,
          }}
        />
      ) : null}
      <div className='rwe-custom-site-page__subHeader'>
        {headers.map((element, i) => 
          <li key={i}><a href={"#" + element.id} onClick={handleClick}>{element.name}</a></li>
        )}
      </div>
      {data.showBreadcrumb ? <DatoBreadcrumbsSection data={data} /> : null}
      {data.pageContent.map((pageContentItem, index) => (
        <DatoReusableComponents data={pageContentItem} index={index} />
      ))}
    </>
  );
});
