import React, {useMemo} from 'react';
import {useCarousel} from './CarouselContext';

export interface CarouselIndicatorsProps<Item> {
  renderIndicator: (
    item: Item,
    isActive: boolean,
    index: number,
  ) => React.ReactNode;
}

const CarouselIndicators = <Item extends any>({
  renderIndicator,
}: CarouselIndicatorsProps<Item>) => {
  const {data, visibleIndexes} = useCarousel<Item>();

  const renderedIndicators = useMemo(
    () =>
      data.map((item, index) => {
        const indicatorClassNames = ['rwe-carousel__indicator'];

        const activeIndex = visibleIndexes.indexOf(0);

        if (activeIndex === index) {
          indicatorClassNames.push('rwe-carousel__indicator--active');
        }
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={indicatorClassNames.join(' ')}>
            {renderIndicator(item, activeIndex === index, index)}
          </div>
        );
      }),
    [visibleIndexes, renderIndicator, data],
  );

  return (
    <div className="rwe-carousel__indicators-container">
      {renderedIndicators}
    </div>
  );
};

const MemoedCarouselIndicators = React.memo(
  CarouselIndicators,
) as typeof CarouselIndicators;

export {MemoedCarouselIndicators as CarouselIndicators};
