import React, {useCallback} from 'react';
import {CarouselIndicators} from '../CarouselIndicators';
import {TestimonialCarouselItem} from './TestimonialCarousel';

export const TestimonialCarouselDots = React.memo(() => {
  const renderDot = useCallback(
    (item: TestimonialCarouselItem, isActive: boolean) => {
      const dotClassNames = ['rwe-testimonial-carousel__dot'];

      if (isActive) {
        dotClassNames.push('rwe-testimonial-carousel__dot--active');
      }

      return (
        <div
          className={dotClassNames.join(' ')}
          style={
            isActive ? {backgroundColor: item.eBoxBackgroundColor} : undefined
          }
        />
      );
    },
    [],
  );

  return <CarouselIndicators renderIndicator={renderDot} />;
});
