import React from 'react';
import {QuoteIcon} from '../icon/QuoteIcon';

export interface QuoteProps {
  children: string;
}

export const Quote = React.memo(({children}: QuoteProps) => (
  <div className="rwe-quote">
    <div className="rwe-quote__icon">
      <QuoteIcon />
    </div>
    <div className="rwe-quote__text">
      <p className="rwe-h3-alt">{children}</p>
    </div>
  </div>
));
