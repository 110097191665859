import React, {useMemo} from 'react';

export interface FeatureProps {
  backgroundColor: string;
  icon?: any;
  text?: string;
}

export const Feature = React.memo(
  ({backgroundColor, icon, text}: FeatureProps) => {
    const style = useMemo(() => ({backgroundColor}), [backgroundColor]);

    return (
      <div className="rwe-feature" style={style}>
        <div className="rwe-feature__container">
          {icon ? <div className="rwe-feature__icon">{icon}</div> : null}
          {text ? <div className="rwe-feature__text">{text}</div> : null}
        </div>
      </div>
    );
  },
);
