import React, {useCallback, useContext, useRef, useState} from 'react';
import {HorizontalScrollableElementRefAttributes} from '../horizontal-scrollable-element/HorizontalScrollableElement';

interface CarouselContextValue<CarouselItem> {
  data: CarouselItem[];
  visibleIndexes: (number | undefined)[];
  setVisibleIndexes: (visibleIndexes: (number | undefined)[]) => any;
  isScrolling: boolean;
  setIsScrolling: (isScrolling: boolean) => any;
  scrollLeft: () => any;
  scrollRight: () => any;
  scrollTo: (index: number) => any;
  horizontalScrollableElementRef: React.MutableRefObject<HorizontalScrollableElementRefAttributes | null>;
}

const CarouselContext = React.createContext<
  CarouselContextValue<unknown> | undefined
>(undefined);

interface CarouselContextProviderProps<CarouselItem> {
  children: React.ReactNode;
  data: CarouselItem[];
}

const CarouselContextProvider = <CarouselItem extends any>({
  children,
  data,
}: CarouselContextProviderProps<CarouselItem>) => {
  const horizontalScrollableElementRef = useRef<HorizontalScrollableElementRefAttributes | null>(
    null,
  );
  const [isScrolling, setIsScrolling] = useState(false);
  const [visibleIndexes, setVisibleIndexes] = useState<(number | undefined)[]>(
    [],
  );

  const scrollLeft = useCallback(() => {
    if (!horizontalScrollableElementRef.current) return;

    const firstVisibleIndex = visibleIndexes.indexOf(0);

    if (firstVisibleIndex === -1) return;

    horizontalScrollableElementRef.current.scrollToIndex(firstVisibleIndex - 1);
  }, [visibleIndexes]);

  const scrollRight = useCallback(() => {
    if (!horizontalScrollableElementRef.current) return;

    const firstVisibleIndex = visibleIndexes.indexOf(0);

    if (firstVisibleIndex === visibleIndexes.length - 1) return;

    horizontalScrollableElementRef.current.scrollToIndex(firstVisibleIndex + 1);
  }, [visibleIndexes]);

  const scrollTo = useCallback(
    (index: number) => {
      if (!horizontalScrollableElementRef.current) return;

      if (index < 0 || index >= data.length) return;

      horizontalScrollableElementRef.current.scrollToIndex(index);
    },
    [data.length],
  );

  return (
    <CarouselContext.Provider
      value={{
        data,
        isScrolling,
        setIsScrolling,
        scrollLeft,
        scrollRight,
        scrollTo,
        visibleIndexes,
        setVisibleIndexes,
        horizontalScrollableElementRef,
      }}
    >
      {children}
    </CarouselContext.Provider>
  );
};

const MemoedCarouselContextProvider = React.memo(
  CarouselContextProvider,
) as typeof CarouselContextProvider;

export {MemoedCarouselContextProvider as CarouselContextProvider};

export const useCarousel = <
  CarouselItem extends any
>(): CarouselContextValue<CarouselItem> => {
  const context = useContext(CarouselContext);

  if (!context) throw new Error('Missing CarouselContext provider');

  return context as any;
};
