import React, {useCallback} from 'react';
import {RunwayEastButton} from '../button/RunwayEastButton';
import {
  ButtonWithCheckBoxTreeDrawer,
  ButtonWithCheckBoxTreeDrawerProps,
} from './ButtonWithCheckBoxTreeDrawer';

export type ExtraSmallFindASpaceDropdownContentProps = Omit<
  ButtonWithCheckBoxTreeDrawerProps,
  'renderButton' | 'drawerButtonText'
>;

export const ExtraSmallFindASpaceDropdownContent = React.memo(
  ({filters, ...rest}: ExtraSmallFindASpaceDropdownContentProps) => {
    const renderButton: ButtonWithCheckBoxTreeDrawerProps['renderButton'] = useCallback(
      ({isDrawerShowing, showDrawer}) => (
        <div className="rwe-find-a-space-dropdown__content-item rwe-find-a-space-dropdown__content-item--extra-small">
          <RunwayEastButton
            variant="primary-dark"
            blockButton
            isActive={isDrawerShowing}
            onClick={showDrawer}
            RightIconComponent={
              <div className="rwe-find-a-space-dropdown__arrow" />
            }
          >
            {filters.map(({title}) => title).join(' / ')}
          </RunwayEastButton>
        </div>
      ),
      [filters],
    );

    return (
      <ButtonWithCheckBoxTreeDrawer
        {...rest}
        filters={filters}
        renderButton={renderButton}
        drawerButtonText="Find a space"
      />
    );
  },
);
