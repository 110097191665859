import React, {useMemo} from 'react';
import {useFloatingCta} from '../floating-cta/FloatingCtaContext';

export interface ContentWithCopySectionProps {
  backgroundColor?: string;
  blockId?: string;
  blockName?: string;
  MobileVisible?: string;
  LaptopVisible?: string;
  copyPosition: 'above' | 'on-left';
  copy: React.ReactNode;
  cta?: React.ReactNode;
  children: React.ReactNode;
  withLine?: boolean;
}

export const ContentWithCopySection = React.memo(
  ({
    backgroundColor,
    copyPosition,
    copy,
    cta,
    children,
    withLine = true,
    blockId,
    blockName,
    LaptopVisible,
    MobileVisible,
  }: ContentWithCopySectionProps) => {
    const floatingCta = useFloatingCta();
    const style = useMemo(
      () => (backgroundColor ? {backgroundColor} : undefined),
      [backgroundColor],
    );
    const sectionClassNames = useMemo((): string => {
      const classNames = ['rwe-section', `rwe-section--copy-${copyPosition}`];

      if (!withLine) {
        classNames.push('rwe-section--without-line');
      }

      return classNames.join(' ');
    }, [copyPosition, withLine]);

    let DisplayMobile = '';
    let DisplayLaptop = '';
    if(MobileVisible == 'no'){
      DisplayMobile = 'DontDisplayMobile'
    }
    if(MobileVisible == 'yes'){
      DisplayMobile = 'DisplayMobile'
    }
    if(LaptopVisible == 'no'){
      DisplayLaptop = 'DontDisplayLaptop'
    }
    if(LaptopVisible == 'yes'){
      DisplayLaptop = 'DisplayLaptop'
    }

    return (
      <div className={sectionClassNames + ' ' + DisplayMobile + ' ' + DisplayLaptop} style={style} id={blockId == '' ? undefined : blockId} attr-name={blockName == '' ? undefined : blockName}>
        <div className="rwe-content-container">
          {withLine ? <div className="rwe-section__line" /> : null}
          <div
            className="rwe-section__content-container"
            style={
              // This value will be overridden on the rwe-breakpoint called "tablet" from ./_section.scss. The <FloatingCta /> is fix-positioned at the bottom of the page and the content offset provided by the below will not be required
              floatingCta.avoidanceStyle
            }
          >
            {copy || cta ? (
              <div className="rwe-section__copy-and-cta">
                {copy ? <div className="rwe-section__copy">{copy}</div> : null}
                {cta ? <div className="rwe-section__cta">{cta}</div> : null}
              </div>
            ) : null}
            <div className="rwe-section__content">{children}</div>
          </div>
        </div>
      </div>
    );
  },
);
