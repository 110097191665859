import React from 'react';
import {colors} from '../theme';

interface QuoteIconProps {
  color?: any;
  width?: number;
  height?: number;
}

export const QuoteIcon = React.memo(
  ({color = colors.primary.teal, width = 55, height = 43}: QuoteIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 55 43"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.5586 0C5.22999 0 0.535156 4.89012 0.535156 15.0465V42.6944H23.0704V18.8081H12.1783V15.9869C12.1783 12.4134 14.2441 10.3445 17.8122 10.3445H23.0704V0H15.5586ZM46.5445 0C36.2159 0 31.5211 4.89012 31.5211 15.0465V42.6944H54.0563V18.8081H43.1643V15.9869C43.1643 12.4134 45.23 10.3445 48.7981 10.3445H54.0563V0H46.5445Z" />
    </svg>
  ),
);
