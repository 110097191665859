/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
  PageContentModelReusableComponentsField,
  TestimonialGridFragment,
} from 'graphql/generated/graphql';
import {
  DatoHeaderCenter,
  DatoHeaderCenterProps,
} from './header/DatoHeaderCenter';
import {
  DatoCardGridSection,
  DatoCardGridSectionProps,
} from './section/DatoCardGridSection';
import {DatoMapSection, DatoMapSectionProps} from './section/DatoMapSection';
import {
  DatoFeaturesSection,
  DatoFeaturesSectionProps,
} from './section/DatoFeaturesSection';
import {DatoFaqsSection, DatoFaqsSectionProps} from './section/DatoFaqsSection';
import {
  DatoSimpleCtaSection,
  DatoSimpleCtaSectionProps,
} from './section/DatoSimpleCtaSection';
import {DatoCtaSection, DatoCtaSectionProps} from './section/DatoCtaSection';
import {
  DatoMultiColumnSection,
  DatoMultiColumnSectionProps,
} from './section/DatoMultiColumnSection';
import {
  DatoTestimonialsCarouselSection,
  DatoTestimonialsCarouselSectionProps,
} from './section/DatoTestimonialsCarouselSection';
import {
  DatoHeaderTextLeftSection,
  DatoHeaderTextLeftSectionProps,
} from './section/DatoHeaderTextLeftSection';
import {
  DatoEmbedWithContactDetailsSection,
  DatoEmbedWithContactDetailsSectionProps,
} from './section/DatoEmbedWithContactDetailsSection';
import {
  DatoLogoWallSection,
  DatoLogoWallSectionProps,
} from './section/DatoLogoWallSection';
import {
  DatoHeaderTextAndImageSection,
  DatoHeaderTextAndImageSectionProps,
} from './section/DatoHeaderTextAndImageSection';
import {
  DatoTestimonialSingleSection,
  DatoTestimonialSingleSectionProps,
} from './section/DatoTestimonialSingleSection';
import {
  DatoCustomHtmlSection,
  DatoCustomHtmlSectionProps,
} from './section/DatoCustomHtmlSection';
import {
  DatoTestimonialGridProps,
  DatoTestimonialGridSection,
} from './section/DatoTestimonialGridSection';

type UnusedDatoCmsFields =
  | '_createdAt'
  | '_firstPublishedAt'
  | '_modelApiKey'
  | '_publicationScheduledAt'
  | '_publishedAt'
  | '_seoMetaTags'
  | '_status'
  | '_unpublishingScheduledAt'
  | '_updatedAt'
  | 'createdAt'
  | 'updatedAt';

interface DatoBlockProps
  extends Omit<PageContentModelReusableComponentsField, UnusedDatoCmsFields> {
  index: number;
}

const isHeaderCentre = (
  datoBlock: DatoBlockProps,
): datoBlock is DatoHeaderCenterProps =>
  datoBlock.__typename === 'HeaderCentreRecord';

const isCardGrid = (
  datoBlock: DatoBlockProps,
): datoBlock is DatoCardGridSectionProps =>
  datoBlock.__typename === 'CardGridRecord';

const isMap = (datoBlock: DatoBlockProps): datoBlock is DatoMapSectionProps =>
  datoBlock.__typename === 'MapRecord';

const isFeaturesSection = (
  datoBlock: DatoBlockProps,
): datoBlock is DatoFeaturesSectionProps =>
  datoBlock.__typename === 'FeatureBlockRecord';

const isFaqsSection = (
  datoBlock: DatoBlockProps,
): datoBlock is DatoFaqsSectionProps =>
  datoBlock.__typename === 'FaqsBlockRecord';

const isSimpleCtaSection = (
  datoBlock: DatoBlockProps,
): datoBlock is DatoSimpleCtaSectionProps =>
  datoBlock.__typename === 'SimpleCtaBlockRecord';

const isCtaSection = (
  datoBlock: DatoBlockProps,
): datoBlock is DatoCtaSectionProps =>
  datoBlock.__typename === 'ECtaBlockRecord';

const isMultiColumnSection = (
  datoBlock: DatoBlockProps,
): datoBlock is DatoMultiColumnSectionProps =>
  datoBlock.__typename === 'MultiColumnBlockRecord';

const isTestimonialsCarouselSection = (
  datoBlock: DatoBlockProps,
): datoBlock is DatoTestimonialsCarouselSectionProps =>
  datoBlock.__typename === 'TestimonialsCarouselRecord';

const isTestimonialGridSection = (
  datoBlock: DatoBlockProps,
): datoBlock is DatoTestimonialGridProps =>
  datoBlock.__typename === 'TestimonialGridRecord';

const isHeaderTextLeftSection = (
  datoBlock: DatoBlockProps,
): datoBlock is DatoHeaderTextLeftSectionProps =>
  datoBlock.__typename === 'HeaderTextLeftRecord';

const isHeaderTextAndImageSection = (
  datoBlock: DatoBlockProps,
): datoBlock is DatoHeaderTextAndImageSectionProps =>
  datoBlock.__typename === 'HeaderTextAndImageRecord';

const isContactEmbedSection = (
  datoBlock: DatoBlockProps,
): datoBlock is DatoEmbedWithContactDetailsSectionProps =>
  datoBlock.__typename === 'ContactEmbedRecord';

const isLogoWallSection = (
  datoBlock: DatoBlockProps,
): datoBlock is DatoLogoWallSectionProps =>
  datoBlock.__typename === 'LogoWallRecord';

const isTestimonialSingleSection = (
  datoBlock: DatoBlockProps,
): datoBlock is DatoTestimonialSingleSectionProps =>
  datoBlock.__typename === 'TestimonialSingleRecord';

const isCustomHtmlSection = (
  datoBlock: DatoBlockProps,
): datoBlock is DatoCustomHtmlSectionProps =>
  datoBlock.__typename === 'CustomHtmlBlockRecord';

export const DatoBlock = React.memo((props: DatoBlockProps) => {
  if (!props._isValid) {
    // eslint-disable-next-line no-console
    console.warn(
      `The reusable component of type: ${props.__typename} with ID: ${props.id} has an invalid configuration and cannot be rendered. Please fix the configuration in DatoCMS to render the component`,
    );
    return null;
  }

  if (isHeaderCentre(props)) {
    return <DatoHeaderCenter {...props} />;
  }
  if (isCardGrid(props)) {
    return <DatoCardGridSection {...props} />;
  }
  if (isMap(props)) {
    return <DatoMapSection {...props} />;
  }
  if (isFeaturesSection(props)) {
    return <DatoFeaturesSection {...props} />;
  }
  if (isFaqsSection(props)) {
    return <DatoFaqsSection {...props} />;
  }
  if (isSimpleCtaSection(props)) {
    return <DatoSimpleCtaSection {...props} />;
  }
  if (isCtaSection(props)) {
    return <DatoCtaSection {...props} />;
  }
  if (isMultiColumnSection(props)) {
    return <DatoMultiColumnSection {...props} />;
  }
  if (isTestimonialsCarouselSection(props)) {
    return <DatoTestimonialsCarouselSection {...props} />;
  }
  if (isTestimonialGridSection(props)) {
    return <DatoTestimonialGridSection {...props} />;
  }
  if (isHeaderTextLeftSection(props)) {
    return <DatoHeaderTextLeftSection {...props} />;
  }
  if (isHeaderTextAndImageSection(props)) {
    return <DatoHeaderTextAndImageSection {...props} />;
  }
  if (isContactEmbedSection(props)) {
    return <DatoEmbedWithContactDetailsSection {...props} />;
  }
  if (isLogoWallSection(props)) {
    return <DatoLogoWallSection {...props} />;
  }
  if (isTestimonialSingleSection(props)) {
    return <DatoTestimonialSingleSection {...props} />;
  }
  if (isCustomHtmlSection(props)) {
    return <DatoCustomHtmlSection {...props} />;
  }
  return null;
});
