import React from 'react';
import {AnimatedHeaderText} from 'ui/components/AnimatedHeaderText';
import {colors} from '../theme';

export interface HeaderTextMaskProps {
  imageSrc?: string;
  children: string;
  ElementName?: keyof JSX.IntrinsicElements;
}

export const HeaderTextMask = React.memo(
  ({imageSrc, children, ElementName = 'h1'}: HeaderTextMaskProps) => {
    return (
      <div className="rwe-header-with-masked-image">
        <ElementName
          className="rwe-header-with-masked-image__text"
          style={
            imageSrc
              ? {backgroundImage: `url(${imageSrc})`}
              : {color: colors.primary.charcoalBlack}
          }
        >
          <AnimatedHeaderText>{children}</AnimatedHeaderText>
        </ElementName>
      </div>
    );
  },
);
