import {
  ArticleCardFragment,
  DatoArticleCategoriesDocument,
  DatoArticleCategoriesQuery,
  DatoArticleCategoriesQueryVariables,
  DatoArticleCountInBlogHomePageDocument,
  DatoArticleCountInBlogHomePageQuery,
  DatoArticlesInBlogHomePageDocument,
  DatoArticlesInBlogHomePageQuery,
  DatoArticlesInBlogHomePageQueryVariables,
  DatoBlogHomePageQuery,
  DatoBlogHomePageQueryVariables,
} from 'graphql/generated/graphql';
import {useDatoPreviewQuerySubscription} from 'hooks/use-page-query';
import React, {useCallback, useMemo} from 'react';
import {transformDatoResponsiveImageFragment} from '../image/ImageRenderer';
import {JsonLd} from '../json-ld/JsonLd';
import {transformDatoBreadcrumbsFragment} from '../section/DatoBreadcrumbsSection';
import {
  ArticleCategoryPage,
  ArticleCategoryPageProps,
} from './ArticleCategoryPage';

type BlogHomePageData = NonNullable<DatoBlogHomePageQuery['blogHomepage']>;

export interface BlogHomePageQuery {
  type: 'blogHomepage';
  data: BlogHomePageData;
  queryVariables: DatoBlogHomePageQueryVariables;
  query: string;
}

type SomeArticleCategoryPageProps = Omit<
  ArticleCategoryPageProps,
  'hasMoreArticles' | 'isLoadingMoreArticles' | 'onLoadMoreArticlesClick'
>;

const transformDatoBlogHomePageFragment = (
  blogHomepage: BlogHomePageData,
  articles: ArticleCardFragment[],
  categories: {
    pagePath?: string | null;
    categoryName?: string | null;
    categoryColour?: {hex?: string | null} | null;
  }[],
): SomeArticleCategoryPageProps => ({
  title: blogHomepage.pageTitle || '',
  articles: articles.map((article, index) => ({
    href: article.pagePath || '',
    image: article.cardImage
      ? transformDatoResponsiveImageFragment(article.cardImage, index > 5)
      : undefined,
    copy: article.cardExcerpt || undefined,
    heading: article.cardTitle || undefined,
    byline: article.cardByline || undefined,
    category: article.categories.length
      ? article.categories[0].categoryName || undefined
      : undefined,
    categoryColor: article.categories.length
      ? article.categories[0].categoryColour?.hex || undefined
      : undefined,
  })),
  categories: [
    {
      children: 'All Articles',
      isActive: true,
      backgroundColor: blogHomepage.categoryColour?.hex || '',
      href: blogHomepage.pagePath || '',
    },
    ...categories.map((category) => ({
      children: category.categoryName || '',
      backgroundColor: category.categoryColour?.hex || '',
      isActive: false,
      href: category.pagePath || '',
    })),
  ],
  breadcrumbs: blogHomepage.showBreadcrumb
    ? transformDatoBreadcrumbsFragment(blogHomepage)
    : [],
});

export interface DatoBlogHomePageProps {
  data: BlogHomePageData;
}

export const DatoBlogHomePage = React.memo(({data}: DatoBlogHomePageProps) => {
  const articlesInBlogHomePage = useDatoPreviewQuerySubscription<
    DatoArticlesInBlogHomePageQuery,
    DatoArticlesInBlogHomePageQueryVariables
  >(DatoArticlesInBlogHomePageDocument, {
    excludingIds: [],
  });

  const articleCategoriesQuery = useDatoPreviewQuerySubscription<
    DatoArticleCategoriesQuery,
    DatoArticleCategoriesQueryVariables
  >(DatoArticleCategoriesDocument);

  const articlesCountQuery = useDatoPreviewQuerySubscription<DatoArticleCountInBlogHomePageQuery>(
    DatoArticleCountInBlogHomePageDocument,
  );

  const articleCategoryProps = useMemo(
    () =>
      transformDatoBlogHomePageFragment(
        data,
        articlesInBlogHomePage.data
          ? articlesInBlogHomePage.data.allArticles
          : [],
        articleCategoriesQuery.data
          ? articleCategoriesQuery.data.allArticleCategories
          : [],
      ),
    [articleCategoriesQuery.data, articlesInBlogHomePage.data, data],
  );

  const handleLoadMoreArticlesClick = useCallback(() => {
    if (articleCategoriesQuery.loading) return;
    if (!articlesInBlogHomePage.data) return;

    articlesInBlogHomePage.fetchMore({
      variables: {
        excludingIds: articlesInBlogHomePage.data.allArticles.map(
          (article) => article.id,
        ),
      },
      updateQuery: (previous, {fetchMoreResult}) => ({
        // eslint-disable-next-line no-underscore-dangle
        __typename: previous.__typename,
        allArticles: fetchMoreResult
          ? [...previous.allArticles, ...fetchMoreResult.allArticles]
          : previous.allArticles,
      }),
    });
  }, [articleCategoriesQuery.loading, articlesInBlogHomePage]);

  return (
    <>
      {data.seoStructuredData ? (
        <JsonLd
          schema={{
            ...data.seoStructuredData,
          }}
        />
      ) : null}

      <ArticleCategoryPage
        {...articleCategoryProps}
        hasMoreArticles={
          articlesInBlogHomePage.data && articlesCountQuery.data
            ? articlesInBlogHomePage.data.allArticles.length <
              // eslint-disable-next-line no-underscore-dangle
              articlesCountQuery.data._allArticlesMeta.count
            : false
        }
        onLoadMoreArticlesClick={handleLoadMoreArticlesClick}
        isLoadingMoreArticles={articlesInBlogHomePage.loading}
      />
    </>
  );
});
