import React from 'react';
import {colors} from '../theme';

interface LocationIconProps {
  color?: any;
  width?: number;
  height?: number;
}

export const locationIconPath =
  'M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z';

export const LocationIcon = React.memo(
  ({
    color = colors.slate.midSlate,
    width = 24,
    height = 24,
  }: LocationIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d={locationIconPath} />
    </svg>
  ),
);
