import React from 'react';
import {colors} from '../theme';

interface EnvelopeIconProps {
  color?: any;
  width?: number;
  height?: number;
}

export const EnvelopeIcon = React.memo(
  ({
    color = colors.slate.midSlate,
    width = 24,
    height = 24,
  }: EnvelopeIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 11L4 6H20ZM20 18H4V8L12 13L20 8V18Z" />
    </svg>
  ),
);
