import React, {useCallback, useEffect, useState} from 'react';
import {PlayIcon} from '../../icon/PlayIcon';
import {OptimizedImage} from '../../image/ImageRenderer';
import {useCarousel} from '../CarouselContext';
import {CarouselIndicators} from '../CarouselIndicators';
import {GalleryCarouselItem} from './GalleryCarousel';
import {getThumbnailUrlFromVimeoUrl} from './GalleryCarouselVideo';

export const GalleryCarouselThumbnails = React.memo(() => {
  const {scrollTo, data} = useCarousel<GalleryCarouselItem>();
  const [vimeoThumbnails, setVimeoThumbnails] = useState<
    Record<string, string>
  >({});

  const thumbnailContent = useCallback(
    (item: GalleryCarouselItem) => {
      if (item.type === 'image') {
        return <OptimizedImage data={item.data} lazyLoad={false} />;
      }
      if (item.type === 'video') {
        return (
          <>
            {vimeoThumbnails[item.data] ? (
              <img
                src={vimeoThumbnails[item.data]}
                alt={vimeoThumbnails[item.data]}
                loading="lazy"
              />
            ) : null}
            <div className="rwe-gallery-carousel__thumbnail-video-icon">
              <PlayIcon />
            </div>
          </>
        );
      }
      return null;
    },
    [vimeoThumbnails],
  );

  const renderThumbnail = useCallback(
    (item: GalleryCarouselItem, isActive: boolean, index: number) => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        className={`rwe-gallery-carousel__thumbnail rwe-gallery-carousel__thumbnail--${item.type}`}
        onClick={!isActive ? () => scrollTo(index) : undefined}
      >
        {thumbnailContent(item)}
      </div>
    ),
    [scrollTo, thumbnailContent],
  );

  useEffect(() => {
    data.forEach((item) => {
      if (item.type !== 'video') return;

      getThumbnailUrlFromVimeoUrl(item.data).then((thumbnailUrl) => {
        setVimeoThumbnails((value) => ({...value, [item.data]: thumbnailUrl}));
      });
    });
  }, [data]);

  return <CarouselIndicators renderIndicator={renderThumbnail} />;
});
