import React from 'react';

interface CameraIconProps {
  color?: any;
  width?: number;
  height?: number;
}

export const CameraIcon = React.memo(
  ({color = '#000', width = 20, height = 21}: CameraIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.875 5.34375H14.2188L13.5859 3.57031C13.5422 3.44896 13.4621 3.34407 13.3565 3.26996C13.251 3.19585 13.1251 3.15614 12.9961 3.15625H7.00391C6.74023 3.15625 6.50391 3.32227 6.41602 3.57031L5.78125 5.34375H3.125C2.26172 5.34375 1.5625 6.04297 1.5625 6.90625V15.8125C1.5625 16.6758 2.26172 17.375 3.125 17.375H16.875C17.7383 17.375 18.4375 16.6758 18.4375 15.8125V6.90625C18.4375 6.04297 17.7383 5.34375 16.875 5.34375ZM17.0312 15.8125C17.0312 15.8984 16.9609 15.9688 16.875 15.9688H3.125C3.03906 15.9688 2.96875 15.8984 2.96875 15.8125V6.90625C2.96875 6.82031 3.03906 6.75 3.125 6.75H6.77148L7.10547 5.81641L7.55273 4.5625H12.4453L12.8926 5.81641L13.2266 6.75H16.875C16.9609 6.75 17.0312 6.82031 17.0312 6.90625V15.8125ZM10 8C8.27344 8 6.875 9.39844 6.875 11.125C6.875 12.8516 8.27344 14.25 10 14.25C11.7266 14.25 13.125 12.8516 13.125 11.125C13.125 9.39844 11.7266 8 10 8ZM10 13C8.96484 13 8.125 12.1602 8.125 11.125C8.125 10.0898 8.96484 9.25 10 9.25C11.0352 9.25 11.875 10.0898 11.875 11.125C11.875 12.1602 11.0352 13 10 13Z" />
    </svg>
  ),
);
