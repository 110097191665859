import React, {useEffect} from 'react';
import {BreadcrumbProps} from '../breadcrumb/Breadcrumb';
import {RunwayEastButton} from '../button/RunwayEastButton';
import {ArticleCard, ArticleCardProps} from '../card/ArticleCard';
import {FlexGrid, ItemsPerRowPerBreakpoint} from '../flex-grid/FlexGrid';
import {LoadingIcon} from '../icon/LoadingIcon';
import {useNavbarContext} from '../navbar/NavbarContextProvider';
import {PillProps} from '../pill/Pill';
import {PillList} from '../pill/PillList';
import {BreadcrumbsSection} from '../section/BreadcrumbsSection';
import {colors} from '../theme';

const itemsPerRowPerBreakpoint: ItemsPerRowPerBreakpoint = [1, 2, 3, 4, 4];

export interface ArticleCategoryPageProps {
  breadcrumbs: BreadcrumbProps[];
  categories: PillProps[];
  title: string;
  articles: ArticleCardProps[];
  hasMoreArticles: boolean;
  isLoadingMoreArticles: boolean;
  onLoadMoreArticlesClick: () => any;
}

export const ArticleCategoryPage = React.memo(
  ({
    articles,
    breadcrumbs,
    title,
    categories,
    hasMoreArticles,
    isLoadingMoreArticles,
    onLoadMoreArticlesClick,
  }: ArticleCategoryPageProps) => {
    const {setBackgroundColor} = useNavbarContext();

    useEffect(() => {
      setBackgroundColor(colors.primary.white);
    }, [setBackgroundColor]);

    return (
      <div className="rwe-article-category-page">
        {breadcrumbs.length ? <BreadcrumbsSection data={breadcrumbs} /> : null}
        <div className="rwe-article-category-page__header">
          <div className="rwe-content-container">
            <h1 className="rwe-h1">{title}</h1>
          </div>
        </div>
        {categories.length ? (
          <div className="rwe-article-category-page__categories">
            <div className="rwe-content-container">
              <PillList data={categories} />
            </div>
          </div>
        ) : null}
        <div className="rwe-article-category-page__content">
          <div className="rwe-content-container">
            {articles.length ? (
              <>
                <FlexGrid itemsPerRowPerBreakpoint={itemsPerRowPerBreakpoint}>
                  {articles.map((article) => (
                    <ArticleCard {...article} />
                  ))}
                </FlexGrid>
                {hasMoreArticles || isLoadingMoreArticles ? (
                  <div className="rwe-article-category-page__loading-button-container">
                    <RunwayEastButton
                      variant="primary"
                      onClick={onLoadMoreArticlesClick}
                      {...(isLoadingMoreArticles
                        ? {
                            IconComponent: (
                              <LoadingIcon color={colors.primary.white} />
                            ),
                          }
                        : {children: 'Load More'})}
                    />
                  </div>
                ) : null}
              </>
            ) : (
              <div className="rwe-article-category-page__empty-content-text">
                <span className="rwe-h3-alt">No Articles Available</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
);
