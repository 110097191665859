import React, {useMemo} from 'react';
import {HeaderCentreFragment} from 'graphql/generated/graphql';
import {HeaderCenter, HeaderCenterProps} from './HeaderCenter';
import {RunwayEastButtonProps} from '../button/RunwayEastButton';
import {resolveHrefFromLinkFields} from '../link/DatoLink';

export const transformDatoHeaderCentreFragment = ({
  backgroundColour,
  copy,
  buttonLink,
  buttonType,
  headingString,
  textBackgroundImage,
}: HeaderCentreFragment): HeaderCenterProps => {
  return {
    backgroundColor: backgroundColour?.hex || '#FFF',
    buttonText: buttonLink?.name || undefined,
    buttonHref: buttonLink ? resolveHrefFromLinkFields(buttonLink) : undefined,
    buttonVariant: buttonType
      ? (buttonType.toLowerCase() as RunwayEastButtonProps['variant'])
      : undefined,
    headerText: headingString || undefined,
    headerTextBackgroundImageSrc:
      textBackgroundImage?.responsiveImage?.src || undefined,
    text: copy || undefined,
  };
};

export interface DatoHeaderCenterProps extends HeaderCentreFragment {
  index: number;
}

export const DatoHeaderCenter = React.memo((props: DatoHeaderCenterProps) => {
  const headerCenterProps = useMemo((): HeaderCenterProps => {
    return transformDatoHeaderCentreFragment(props);
  }, [props]);

  return <HeaderCenter {...headerCenterProps} />;
});
