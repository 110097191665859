import {NewsletterSignupBlockFragment} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {
  DatoRunwayEastStructuredText,
  DatoStructuredTextConfig,
} from '../text/DatoRunwayEastStructuredText';
import {colors} from '../theme';
import {
  NewsletterSignupCtaBox,
  NewsletterSignupCtaBoxProps,
} from './NewsletterSignupCtaBox';

const structuredTextStyle: DatoStructuredTextConfig = {
  h1: {
    classNames: ['rwe-h1'],
    style: {
      color: colors.primary.white,
    },
  },
  h2: {
    classNames: ['rwe-h2'],
    style: {
      color: colors.primary.white,
    },
  },
  h3: {
    classNames: ['rwe-h3'],
    style: {
      color: colors.primary.white,
    },
  },
  h4: {
    classNames: ['rwe-h4-alt'],
    style: {
      color: colors.primary.white,
    },
  },
  h5: {
    classNames: ['rwe-h4-alt'],
    style: {
      color: colors.primary.white,
    },
  },
  h6: {
    classNames: ['rwe-h4-alt'],
    style: {
      color: colors.primary.white,
    },
  },
  p: {
    classNames: ['rwe-p1'],
    style: {
      color: colors.primary.white,
    },
  },
  a: {
    style: {
      color: colors.primary.white,
      textDecoration: 'underline',
    },
  },
};

export type DatoNewsletterSignupCtaBoxProps = NewsletterSignupBlockFragment;

const transformDatoNewsletterSignupBlockFragmentToProps = ({
  buttonCopy,
  mainCopy,
  placeholderCopy,
  smallprintCopy,
}: DatoNewsletterSignupCtaBoxProps): NewsletterSignupCtaBoxProps => ({
  buttonText: buttonCopy || undefined,
  textInputPlaceholderText: placeholderCopy || undefined,
  bodyContent: (
    <DatoRunwayEastStructuredText
      data={mainCopy}
      runwayEastStyle={structuredTextStyle}
    />
  ),
  contentUnderTextInput: (
    <DatoRunwayEastStructuredText
      data={smallprintCopy}
      runwayEastStyle={structuredTextStyle}
    />
  ),
});

export const DatoNewsletterSignupCtaBox = React.memo(
  (props: DatoNewsletterSignupCtaBoxProps) => {
    const newsletterSignupCtaBoxProps = useMemo(
      () => transformDatoNewsletterSignupBlockFragmentToProps(props),
      [props],
    );

    return <NewsletterSignupCtaBox {...newsletterSignupCtaBoxProps} />;
  },
);
