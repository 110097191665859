import React from 'react';
import {ImageRendererConfig, OptimizedImage} from '../image/ImageRenderer';
import {RunwayEastLink} from '../link/RunwayEastLink';

interface TransportLink {
  imageSrc?: string;
  text: string;
}

export interface WorkspaceCardProps {
  href?: string;
  image?: ImageRendererConfig;
  name: string;
  siteName: string;
  capacity: string;
  price: string;
  labelText?: string;
  labelColor?: string;
  transportLinks?: TransportLink[];
  withinCardGrid?: boolean;
}

export const WorkspaceCard = React.memo(
  ({
    href,
    image,
    name,
    siteName,
    capacity,
    price,
    labelText,
    labelColor,
    transportLinks,
    withinCardGrid,
  }: WorkspaceCardProps) => (
    <RunwayEastLink href={href}>
      <div
        className={[
          'rwe-workspace-card',
          withinCardGrid ? 'rwe-workspace-card--within-card-grid' : '',
        ]
          .join(' ')
          .trim()}
      >
        <div className="rwe-workspace-card__image">
          {image ? <OptimizedImage data={image} /> : null}
          {labelText ? (
            <div
              className="rwe-workspace-card__label"
              style={labelColor ? {backgroundColor: labelColor} : undefined}
            >
              <span className="rwe-p1">{labelText}</span>
            </div>
          ) : null}
        </div>
        <div className="rwe-workspace-card__details">
          <div>
            <div className="rwe-workspace-card__name">
              <h4 className="rwe-h4-alt">{name}</h4>
            </div>

            <div className="rwe-workspace-card__site-name">
              <p className="rwe-p1">{siteName}</p>
            </div>
          </div>

          {transportLinks ? (
            <div className="rwe-workspace-card__transport-links">
              {transportLinks.map((transportLink) => (
                <div className="rwe-workspace-card__transport-link">
                  {transportLink.imageSrc ? (
                    <div className="rwe-workspace-card__transport-link-icon">
                      <OptimizedImage data={transportLink.imageSrc} />
                    </div>
                  ) : null}
                  <p className="rwe-p3">{transportLink.text}</p>
                </div>
              ))}
            </div>
          ) : null}

          <div className="rwe-workspace-card__capacity-and-price">
            <div className="rwe-workspace-card__capacity">
              <p className="rwe-p3">{capacity}</p>
            </div>

            <div className="rwe-workspace-card__price">
              <p className="rwe-p3 rwe-p3--medium">{price}</p>
            </div>
          </div>
        </div>
      </div>
    </RunwayEastLink>
  ),
);
