import {
  ArticleCardFragment,
  DatoArticleCategoryPageQuery,
  DatoArticleCategoryPageQueryVariables,
  DatoBlogHomePageQuery,
  useDatoArticleCategoriesQuery,
  useDatoArticleCountInCategoryQuery,
  useDatoArticlesInCategoryQuery,
  useDatoBlogHomePageQuery,
} from 'graphql/generated/graphql';
import React, {useCallback, useMemo} from 'react';
import {transformDatoResponsiveImageFragment} from '../image/ImageRenderer';
import {JsonLd} from '../json-ld/JsonLd';
import {transformDatoBreadcrumbsFragment} from '../section/DatoBreadcrumbsSection';
import {
  ArticleCategoryPage,
  ArticleCategoryPageProps,
} from './ArticleCategoryPage';

export interface ArticleCategoryPageQuery {
  type: 'articleCategory';
  data: DatoArticleCategoryPageQuery['articleCategory'];
  queryVariables: DatoArticleCategoryPageQueryVariables;
  query: string;
}

type ArticleCategoryData = NonNullable<
  DatoArticleCategoryPageQuery['articleCategory']
>;

type SomeArticleCategoryPageProps = Omit<
  ArticleCategoryPageProps,
  'hasMoreArticles' | 'isLoadingMoreArticles' | 'onLoadMoreArticlesClick'
>;

const transformDatoArticleCategoryPageFragment = (
  articleCategory: ArticleCategoryData,
  articles: ArticleCardFragment[],
  categories: {
    pagePath?: string | null;
    categoryName?: string | null;
    categoryColour?: {hex?: string | null} | null;
  }[],
  blogHomepage: DatoBlogHomePageQuery['blogHomepage'],
): SomeArticleCategoryPageProps => ({
  title: articleCategory.categoryName || '',
  articles: articles.map((article, index) => ({
    href: article.pagePath || '',
    image: article.cardImage
      ? transformDatoResponsiveImageFragment(article.cardImage, index > 5)
      : undefined,
    copy: article.cardExcerpt || undefined,
    heading: article.cardTitle || undefined,
    byline: article.cardByline || undefined,
    category: article.categories.length
      ? article.categories[0].categoryName || undefined
      : undefined,
    categoryColor: article.categories.length
      ? article.categories[0].categoryColour?.hex || undefined
      : undefined,
  })),
  categories: [
    {
      children: 'All Articles',
      isActive: false,
      backgroundColor: blogHomepage?.categoryColour?.hex || '',
      href: blogHomepage?.pagePath || '',
    },
    ...categories.map((category) => ({
      children: category.categoryName || '',
      backgroundColor: category.categoryColour?.hex || '',
      isActive: articleCategory.categoryName === category.categoryName,
      href: category.pagePath || '',
    })),
  ],
  breadcrumbs: articleCategory.showBreadcrumb
    ? transformDatoBreadcrumbsFragment(articleCategory)
    : [],
});

export interface DatoArticleCategoryPageProps {
  data: ArticleCategoryData;
}

export const DatoArticleCategoryPage = React.memo(
  ({data}: DatoArticleCategoryPageProps) => {
    const blogHomePage = useDatoBlogHomePageQuery();

    const articlesInCategoryQuery = useDatoArticlesInCategoryQuery({
      variables: {
        categoryId: data.id,
        excludingIds: [],
      },
    });

    const articleCategoriesQuery = useDatoArticleCategoriesQuery();

    const articleCountQuery = useDatoArticleCountInCategoryQuery({
      variables: {
        categoryId: data.id,
      },
    });

    const articleCategoryProps = useMemo(
      () =>
        transformDatoArticleCategoryPageFragment(
          data,
          articlesInCategoryQuery.data
            ? articlesInCategoryQuery.data.allArticles
            : [],
          articleCategoriesQuery.data
            ? articleCategoriesQuery.data.allArticleCategories
            : [],
          blogHomePage.data ? blogHomePage.data.blogHomepage : null,
        ),
      [
        articleCategoriesQuery.data,
        articlesInCategoryQuery.data,
        blogHomePage.data,
        data,
      ],
    );

    const handleLoadMoreArticlesClick = useCallback(() => {
      if (articleCategoriesQuery.loading) return;
      if (!articlesInCategoryQuery.data) return;

      articlesInCategoryQuery.fetchMore({
        variables: {
          categoryId: data.id,
          excludingIds: articlesInCategoryQuery.data.allArticles.map(
            (article) => article.id,
          ),
        },
        updateQuery: (previous, {fetchMoreResult}) => ({
          // eslint-disable-next-line no-underscore-dangle
          __typename: previous.__typename,
          allArticles: fetchMoreResult
            ? [...previous.allArticles, ...fetchMoreResult.allArticles]
            : previous.allArticles,
        }),
      });
    }, [articleCategoriesQuery.loading, articlesInCategoryQuery, data.id]);

    return (
      <>
        {data.seoStructuredData ? (
          <JsonLd
            schema={{
              ...data.seoStructuredData,
            }}
          />
        ) : null}
        <ArticleCategoryPage
          {...articleCategoryProps}
          hasMoreArticles={
            articlesInCategoryQuery.data && articleCountQuery.data
              ? articlesInCategoryQuery.data.allArticles.length <
                // eslint-disable-next-line no-underscore-dangle
                articleCountQuery.data._allArticlesMeta.count
              : false
          }
          onLoadMoreArticlesClick={handleLoadMoreArticlesClick}
          isLoadingMoreArticles={articlesInCategoryQuery.loading}
        />
      </>
    );
  },
);
