import {SimpleCtaBlockFragment} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {transformDatoResponsiveImageFragment} from '../image/ImageRenderer';
import {DatoButtonLink} from '../link/DatoLink';
import {DatoRunwayEastStructuredText} from '../text/DatoRunwayEastStructuredText';
import {colors} from '../theme';
import {
  ContentWithImageOnSide,
  ContentWithImageOnSideProps,
} from './ContentWithImageOnSide';

type DatoContentFragmentProps = Pick<
  SimpleCtaBlockFragment,
  'structuredText' | 'ctaOne' | 'ctaTwo'
>;

const DatoContentFragment = React.memo(
  ({structuredText, ctaOne, ctaTwo}: DatoContentFragmentProps) => (
    <>
      <div>
        {structuredText ? (
          <DatoRunwayEastStructuredText
            data={structuredText}
            runwayEastStyle={{
              h4: {
                classNames: ['rwe-h4-alt'],
                style: {color: colors.slate.darkSlate},
              },
              p: {
                classNames: ['rwe-p1'],
                style: {color: colors.slate.darkSlate},
              },
            }}
          />
        ) : null}
      </div>
      {(ctaOne && ctaOne.name) || (ctaTwo && ctaTwo.name) ? (
        <div style={{marginTop: 32}}>
          <div className="rwe-button-group rwe-button-group--responsive">
            {ctaOne ? <DatoButtonLink data={ctaOne} /> : null}
            {ctaTwo ? <DatoButtonLink data={ctaTwo} /> : null}
          </div>
        </div>
      ) : null}
    </>
  ),
);

export interface DatoSimpleCtaSectionProps extends SimpleCtaBlockFragment {
  index: number;
}

const transformDatoSimpleCtaBlockFragment = ({
  ctaOne,
  ctaTwo,
  image,
  imageSide,
  structuredText,
  backgroundColour,
  hideSeparatorLine,
  blockCardIdentify,
  blockMenuName,
  onLaptop,
  onMobile,
  searchForm,
  index,
}: DatoSimpleCtaSectionProps): ContentWithImageOnSideProps => ({
  withLine: !hideSeparatorLine,
  backgroundColor: backgroundColour?.hex || undefined,
  blockId: blockCardIdentify as string,
  blockName: blockMenuName as string,
  LaptopVisible: onLaptop as string,
  MobileVisible: onMobile as string,
  FormSearch: searchForm as string,
  imagePosition: imageSide && imageSide === 'left' ? 'on-left' : 'on-right',
  image: image ? transformDatoResponsiveImageFragment(image, index > 0) : '',
  children: (
    <DatoContentFragment
      structuredText={structuredText}
      ctaOne={ctaOne}
      ctaTwo={ctaTwo}
    />
  ),
});
export const DatoSimpleCtaSection = React.memo(
  (props: DatoSimpleCtaSectionProps) => {
    const contentWithImageOnSideProps = useMemo(
      (): ContentWithImageOnSideProps =>
        transformDatoSimpleCtaBlockFragment(props),
      [props],
    );

    return (
      <div className="rwe-simple-cta-section">
        <ContentWithImageOnSide {...contentWithImageOnSideProps} />
      </div>
    );
  },
);
