import {TestimonialSingleFragment} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {
  OptimizedImage,
  transformDatoResponsiveImageFragment,
} from '../image/ImageRenderer';
import {colors} from '../theme';
import {
  TestimonialSingleSection,
  TestimonialSingleSectionProps,
} from './TestimonialSingleSection';

export const transformDatoTestimonialSingleFragment = ({
  backgroundColour,
  blockCardIdentify,
  blockMenuName,
  onLaptop,
  onMobile,
  image,
  logo,
  quote,
  quotee,
  textBoxColour,
  index,
}: DatoTestimonialSingleSectionProps): TestimonialSingleSectionProps => {
  return {
    image: image
      ? transformDatoResponsiveImageFragment(image, index > 0)
      : undefined,
    testimonial: {
      LogoComponent: logo?.responsiveImage ? (
        <OptimizedImage data={logo.responsiveImage} lazyLoad />
      ) : null,
      eBoxBackgroundColor: textBoxColour
        ? textBoxColour.hex || colors.secondary.pink
        : colors.secondary.pink,
      eBoxTextColor: colors.primary.white,
      image: image
        ? transformDatoResponsiveImageFragment(image, index > 0)
        : '',
      TestimonialBodyComponent: <div className="rwe-h4-alt">{quote}</div>,
      testimonialCreditText: quotee || undefined,
    },
    backgroundColor: backgroundColour?.hex || undefined,
    blockId: blockCardIdentify || undefined,
    blockName: blockMenuName || undefined,
    LaptopVisible: onLaptop || undefined,
    MobileVisible: onMobile || undefined,
  };
};

export interface DatoTestimonialSingleSectionProps
  extends TestimonialSingleFragment {
  index: number;
}

export const DatoTestimonialSingleSection: React.FC<DatoTestimonialSingleSectionProps> = React.memo(
  (props) => {
    const testimonialSingleSectionProps = useMemo((): TestimonialSingleSectionProps => {
      return transformDatoTestimonialSingleFragment(props);
    }, [props]);

    return <TestimonialSingleSection {...testimonialSingleSectionProps} />;
  },
);
