import React from 'react';
import {Breadcrumb, BreadcrumbProps} from './Breadcrumb';
import {BreadcrumbSeparator} from './BreadcrumbSeparator';

export interface BreadcrumbListItemProps extends BreadcrumbProps {
  isLastItem: boolean;
}

export const BreadcrumbListItem = React.memo(
  ({isLastItem, children, href}: BreadcrumbListItemProps) => (
    <div className="rwe-breadcrumb-list__item">
      <Breadcrumb href={isLastItem ? undefined : href}>{children}</Breadcrumb>
      {isLastItem ? null : <BreadcrumbSeparator />}
    </div>
  ),
);
