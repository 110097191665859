import React from 'react';

export interface PlayIconProps {
  color?: any;
  width?: number;
  height?: number;
}

export const PlayIcon = React.memo(
  ({color = 'white', width = 23, height = 28}: PlayIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 28"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0V28L22.4583 14L0 0Z" />
    </svg>
  ),
);
