import {DatoLocationSitePageQuery} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {
  GalleryCarousel,
  GalleryCarouselProps,
} from '../carousel/gallery/GalleryCarousel';
import {transformDatoResponsiveImageFragment} from '../image/ImageRenderer';
import {LocationSitePageProps} from '../page/LocationSitePage';

export const transformAssetsFieldValueToAssetsProp = (
  assets: NonNullable<DatoLocationSitePageQuery['locationSite']>['assets'],
): LocationSitePageProps['assets'] =>
  assets
    .filter(
      (asset) =>
        // eslint-disable-next-line no-underscore-dangle
        asset.__typename === 'ImageRecord' ||
        // eslint-disable-next-line no-underscore-dangle
        asset.__typename === 'VimeoVideoRecord',
    )
    .map((asset) => {
      // eslint-disable-next-line no-underscore-dangle
      switch (asset.__typename) {
        case 'ImageRecord':
          return {
            data: asset.image
              ? transformDatoResponsiveImageFragment(asset.image)
              : '',
            type: 'image',
          };
        case 'VimeoVideoRecord':
          return {
            data: asset.vimeoUrl ? asset.vimeoUrl : '',
            type: 'video',
          };
        default:
          throw new Error(
            // eslint-disable-next-line no-underscore-dangle
            `Unrecognised GalleryCarousel asset type: ${asset.__typename}`,
          );
      }
    });

export interface GalleryCarouselSectionProps extends GalleryCarouselProps {
  backgroundColor?: string;
  children?: React.ReactNode;
}

export const GalleryCarouselSection = React.memo(
  ({backgroundColor, data, children}: GalleryCarouselSectionProps) => {
    const style = useMemo(
      () => ({
        backgroundColor,
      }),
      [backgroundColor],
    );

    if (!data || data.length == 0){
      return null;
    }

    return (
      <div className="rwe-gallery-carousel-section" style={style} id='gallery' attr-name='Gallery'>
        <div className="rwe-section rwe-section--no-padding">
          <div className="rwe-content-container">
            <GalleryCarousel data={data} />
            {children}
          </div>
        </div>
      </div>
    );
  },
);
