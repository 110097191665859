import React, {useMemo, useEffect, useCallback} from 'react';
import {
  HorizontalScrollableElement,
  HorizontalScrollableElementProps,
} from '../horizontal-scrollable-element/HorizontalScrollableElement';
import {useCarousel} from './CarouselContext';

export interface CarouselWindowProps<Item extends any>
  extends Omit<HorizontalScrollableElementProps<Item>, 'data'> {
  children?: React.ReactNode;
  windowContainerChildren?: React.ReactNode;
}

const CarouselWindow = <Item extends any>({
  children,
  windowContainerChildren,
  renderItem,
}: CarouselWindowProps<Item>) => {
  const {
    data,
    isScrolling,
    setIsScrolling,
    setVisibleIndexes,
    horizontalScrollableElementRef,
    scrollTo,
  } = useCarousel<Item>();

  const containerClassNames = useMemo((): string => {
    const classNames = ['rwe-carousel-window'];

    if (isScrolling) {
      classNames.push('rwe-carousel-window--scrolling');
    }

    return classNames.join(' ');
  }, [isScrolling]);

  const handleScrollStart = useCallback(() => setIsScrolling(true), [
    setIsScrolling,
  ]);

  const handleScrollEnd = useCallback(() => setIsScrolling(false), [
    setIsScrolling,
  ]);

  useEffect(() => {
    scrollTo(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={containerClassNames}>
      <HorizontalScrollableElement
        ref={horizontalScrollableElementRef}
        onChildrenVisibleIndexesChange={setVisibleIndexes}
        onScrollStart={handleScrollStart}
        onScrollEnd={handleScrollEnd}
        containerChildren={children}
        renderItem={renderItem}
        data={data}
      />
      {windowContainerChildren}
    </div>
  );
};

const MemoedCarouselWindow = React.memo(
  CarouselWindow,
) as typeof CarouselWindow;

export {MemoedCarouselWindow as CarouselWindow};
