import React from 'react';
import {CameraIcon} from '../../icon/CameralIcon';
import {useCarousel} from '../CarouselContext';
import {GalleryCarouselItem} from './GalleryCarousel';

export const GalleryActiveNumberIndicator = React.memo(() => {
  const {visibleIndexes, data} = useCarousel<GalleryCarouselItem>();
  const activeCarouselItem =
    visibleIndexes.indexOf(0) !== undefined
      ? data[visibleIndexes.indexOf(0)]
      : null;
  return activeCarouselItem && activeCarouselItem.type === 'image' ? (
    <div className="rwe-gallery-carousel__active-number-indicator">
      <CameraIcon />
      <div className="rwe-gallery-carousel__active-number-indicator-text">
        <div className="rwe-p1">
          {(visibleIndexes.indexOf(0) || 0) + 1} / {data.length}
        </div>
      </div>
    </div>
  ) : null;
});
