import {CustomHtmlBlockFragment} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {CustomHtmlSection, CustomHtmlSectionProps} from './CustomHtmlSection';

export const transformDatoCustomHtmlFragment = ({
  customHtml,
  backgroundColour,
  containerWidth,
  hideSeparatorLine,
  blockCardIdentify,
  blockMenuName,
  onLaptop,
  onMobile,
}: CustomHtmlBlockFragment): CustomHtmlSectionProps | null =>
  customHtml
    ? {
        withLine: !hideSeparatorLine,
        blockId: blockCardIdentify as string,
        blockName: blockMenuName as string,
        LaptopVisible: onLaptop as string,
        MobileVisible: onMobile as string,
        html: customHtml,
        backgroundColor: backgroundColour?.hex || undefined,
        withArticleLikeContainerWidth: containerWidth === 'narrow',
      }
    : null;

export interface DatoCustomHtmlSectionProps extends CustomHtmlBlockFragment {
  index: number;
}

export const DatoCustomHtmlSection = React.memo(
  (props: DatoCustomHtmlSectionProps) => {
    const customHtmlSectionProps = useMemo(
      () => transformDatoCustomHtmlFragment(props),
      [props],
    );

    if (!customHtmlSectionProps) return null;

    return <CustomHtmlSection {...customHtmlSectionProps} />;
  },
);
